import { useAuth0 } from '@auth0/auth0-react';
import { BanIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

export interface NotFoundProps {
  showNavbar?: boolean;
}

function NotFound({
  showNavbar = true,
}: NotFoundProps) {
  const { t } = useTranslation();

  const { isAuthenticated } = useAuth0();

  return (
    <>
      {isAuthenticated && showNavbar &&
        <div className="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md flex items-center space-x-4">
          <BanIcon className="text-red-700 w-12 h-12" />
          <p className="prose"> {t('notfound.message')} </p>
        </div>
      }
    </>
  );
}

export default NotFound;