import { format, formatRelative, Locale } from 'date-fns';
import { fi, de, fr, sv, ru } from 'date-fns/locale';
import i18n from 'i18next';
import { ComponentType, PlugRegion } from './graphql.generated';

const locales = new Map<string, Locale>([
  ['en', fi],
  ['fi', fi],
  ['de', de],
  ['fr', fr],
  ['se', sv],
  ['ru', ru],
]);

export default function getLocale() {
  return i18n.language == 'en' ? 'fi' : i18n.language == 'se' ? 'sv' : i18n.language;
}

export function formatDate(date: Date, formatStr: string = 'PP'): string {
  return format(date, formatStr, {
    locale: locales.get(i18n.language),
  });
}

export function formatDateRelative(dateFrom: Date, dateTo: Date): string {
  return formatRelative(dateFrom, dateTo, {
    locale: locales.get(i18n.language),
  });
}

export const formatCurrency = (value: number): string =>
  new Intl.NumberFormat(getLocale(), {
    style: 'currency',
    currency: 'EUR',
  }).format(value);

export const formatWidth = (value: number): string =>
  new Intl.NumberFormat(getLocale(), {
    style: 'unit',
    unit: 'millimeter',
  }).format(value);
export function translateComponentType(value: ComponentType): string {
  const typeOptions = new Map<ComponentType, string>([
    [ComponentType.EndPlug, i18n.t('component.type.endPlug')],
    [ComponentType.Filler, i18n.t('component.type.filler')],
    [ComponentType.Logo, i18n.t('component.type.logo')],
    [ComponentType.OtherSocket, i18n.t('component.type.other')],
    [ComponentType.PowerSocket, i18n.t('component.type.power')],
    [ComponentType.Switch, i18n.t('component.type.switch')],
  ]);
  return typeOptions.get(value)!;
};

export function translatePlugRegion(value: PlugRegion): string {
  const plugOptions = new Map<PlugRegion, string>([
    [PlugRegion.Schuko, i18n.t('plugRegion.schuko')],
    [PlugRegion.Italian, i18n.t('plugRegion.italian')],
    [PlugRegion.French, i18n.t('plugRegion.french')],
    [PlugRegion.Danish, i18n.t('plugRegion.danish')],
    [PlugRegion.British, i18n.t('plugRegion.british')],
    [PlugRegion.Swiss, i18n.t('plugRegion.swiss')],

  ]);
  return plugOptions.get(value)!;
};

export function getNumberFormatting() {
  return {
    thousandsSeparator: getLocale() == 'de' ? '.' : ' ',
    decimalSeparator: ',',
  };
}