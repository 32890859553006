import { ChevronRightIcon, HomeIcon } from '@heroicons/react/solid';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useGetComponentByIdLazyQuery, useGetConfigurationByIdLazyQuery } from '../../lib/graphql.generated';

interface PageProps {
  name: string;
  href: string;
  current: boolean;
}

function BreadCrumbs() {
  const location = useLocation();
  const { t } = useTranslation();
  const [getComponent, {
    loading: componentLoading,
    data: componentData,
  }] = useGetComponentByIdLazyQuery({
    fetchPolicy: 'cache-first',
  });
  const [getConfiguration, {
    loading: configurationLoading,
    data: configurationData,
  }] = useGetConfigurationByIdLazyQuery({
    fetchPolicy: 'cache-first',
  });

  const labels = new Map([
    ['panels', t('navbar.link.panel')],
    ['components', t('navbar.link.component')],
    ['members', t('navbar.link.member')],
    ['new', t('navbar.button.panel')],
    ['base_prices', t('navbar.link.basePrice')],
  ]);

  let parts = location.pathname.split('/');
  parts.shift();
  let pagePosition = parts.indexOf('page');
  if (pagePosition != -1) {
    parts = parts.slice(0, pagePosition);
  }

  const pages: PageProps[] = parts.reduce<PageProps[]>((acc, part, i) => {
    const previousPage = acc.length == 0 ?
      '/' + part :
      acc[acc.length - 1].href + '/' + part;
    acc.push({
      name: part,
      href: previousPage,
      current: i == parts.length - 1,
    });
    return acc;
  }, []);

  useEffect(() => {
    const last = parts[parts.length - 1];
    const id: string | null = (labels.has(last) || last.length == 0) ? null : last;

    if (id) {
      if (!componentLoading && parts.find(p => p == 'components')) {
        getComponent({ variables: { id } });
      } else if (!configurationLoading && parts.find(p => p == 'panels')) {
        getConfiguration({ variables: { id } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  function lookUpLabel(pageName: string): string {
    if (labels.has(pageName)) {
      return labels.get(pageName)!;
    } else if (parts.find(p => p == 'panels') && configurationData?.configuration?.name) {
      return configurationData?.configuration?.name;
    } else if (parts.find(p => p == 'components') && componentData?.component?.name) {
      return componentData?.component?.name;
    } else {
      return '';
    }
  }

  return (
    <div className="mx-auto px-2 pt-2 pb-2 sm:px-4 lg:px-12  inline-flex">
      <nav className="flex " aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          <li>
            <div>
              <Link to="/" className="text-gray-400 hover:text-gray-500">
                <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                <span className="sr-only">Home</span>
              </Link>
            </div>
          </li>
          {(!componentLoading && !configurationLoading) && pages.map((page) => (
            <li key={page.name}>
              <div className="flex items-center">
                <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                <Link to={page.href}
                  className="ml-4 text-base font-medium text-gray-500 hover:text-gray-700"
                  aria-current={page.current ? 'page' : undefined}>
                  <p className="text-base"> {lookUpLabel(page.name)}</p>
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
}

export default BreadCrumbs;