import { useTranslation } from 'react-i18next';
import { PanelWidth, useGetBasePriceListQuery } from '../../lib/graphql.generated';
import { widthName } from '../../pages/BasePricePage.component';
import PanelWidthItem from './PanelWidthItem.component';
function WidthPanel() {
  const { t } = useTranslation();
  const { data, loading, error } = useGetBasePriceListQuery();
  const items = data?.basePrice.filter((i) => i.width !== PanelWidth.Custom)
    .map((i) => <PanelWidthItem key={i.width} name={widthName.get(i.width)!} width={i.width} />);


  return (
    <>
      {(() => {
        if (loading) {
          return (<></>);
        } else if (error) {
          return <p>Error: {error}</p>;
        } else {
          return (<ul className="divide-y divide-gray-200">
            {items}
            <PanelWidthItem key={10} name={t('panel.customWidth')} width={PanelWidth.Custom} />
          </ul>);
        }
      })()
      }
    </>
  );
}
export default WidthPanel;