import { formatRFC3339 } from 'date-fns';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { PlugRegion, PanelWidth, Configuration, Component, useGetDefaultComponentsQuery, GetDefaultComponentsQuery, ComponentSubtype, ComponentType, useGetBasePriceListQuery, GetBasePriceListQuery } from '../lib/graphql.generated';
import { regionName, OtherComponentName, generateId } from '../lib/panelNameFormat';

export const panelWidth = new Map<PanelWidth, number>([
  [PanelWidth.Xsmall, 331],
  [PanelWidth.Small, 468],
  [PanelWidth.Medium, 683],
  [PanelWidth.Large, 836],
  [PanelWidth.Xlarge, 1400],
  [PanelWidth.Xxlarge, 1700],
]);

const makeConfiguration = (
  plugRegion: PlugRegion,
  width: PanelWidth,
  customWidth: number | undefined,
  defaultComponents?: GetDefaultComponentsQuery,
  initPrice?: GetBasePriceListQuery,
): Configuration => {
  if (customWidth) {
    if (customWidth < panelWidth.get(PanelWidth.Xsmall)!) throw new Error('Panel Width is too small');
    if (customWidth > panelWidth.get(PanelWidth.Xxlarge)!) throw new Error('Panel Width is too large');
  }
  const components: Component[] = makeDefaultComponents(width, defaultComponents);

  return {
    id: 'current_configuration',
    name: '',
    description: '',
    createdAt: formatRFC3339(Date.now()),
    isActive: true,
    plugRegion,
    components,
    factoryPrice: setBasePrice(width, initPrice!)?.factoryPrice,
    exportPrice: setBasePrice(width, initPrice!)?.exportPrice,
    width,
    customWidth,
    arePricesOverridden: false,
  };
};

function makeDefaultComponents(width: PanelWidth, defaultComponents?: GetDefaultComponentsQuery) {
  const components: Component[] = [];
  if (defaultComponents) {
    components.push(defaultComponents?.endLeft[0]);
    if (width == PanelWidth.Small) {
      components.push(defaultComponents?.logoHorizontal[0]);
    } else {
      components.push(defaultComponents?.logoVertical[0]);
    }
    components.push(defaultComponents?.endRight[0]);
  }
  return components;
}

function removeByIndex<T>(a: readonly T[], i: number, isUsb: boolean) {
  return [
    ...a.slice(0, isUsb ? i - 1 : i),
    ...a.slice(i + 1),
  ];
}

function setBasePrice(width: PanelWidth, basePriceList: GetBasePriceListQuery) {
  return basePriceList.basePrice.find((i) => i.width == width);
}

type Prices = { exportPrice: number; factoryPrice: number };

const calculatePrices = (components: Component[], width: PanelWidth, basePriceList: GetBasePriceListQuery): Prices => {
  return (components.reduce<Prices>((acc, c) => ({
    exportPrice: acc.exportPrice + c.exportPrice,
    factoryPrice: acc.factoryPrice + c.factoryPrice,
  }), { exportPrice: setBasePrice(width, basePriceList)?.exportPrice!, factoryPrice: setBasePrice(width, basePriceList)?.factoryPrice! }));
};

export interface ComponentDetail {
  name: string;
  quantity: number;
  exportPrice: number;
  factoryPrice: number;
  width: number;
}

export type ConfigurationUpdateFields = Partial<Pick<Configuration, 'name' | 'description'>>;

export interface CurrentConfigurationContext {
  configuration: Configuration | null;
  reset: (plugRegion: PlugRegion, width: PanelWidth, customWidth: number | undefined) => void;
  updateConfiguration: (fieldsToUpdate: ConfigurationUpdateFields) => void;
  canAddComponent: (component: Component) => boolean;
  addComponent: (component: Component) => void;
  canRemoveComponent: (position: number) => boolean;
  removeComponent: (position: number, isUsb: boolean) => void;
  componentDetail: (component: Component) => ComponentDetail;
  allComponentDetails: () => ComponentDetail[];
  isSaveable: () => boolean;
  undo: () => void;
  redo: () => void;
  getBlankNarrowComponent: (component: Component) => void;
  generatePanelName: () => string | undefined;
  canUndo: boolean;
  canRedo: boolean;
}

const CONFIGURATION_DEFAULT_VALUE: CurrentConfigurationContext = {
  configuration: null,
  reset: (_plugRegion: PlugRegion, _width: PanelWidth, _customWidth: number | undefined) => { },
  updateConfiguration: (_fieldsToUpdate: ConfigurationUpdateFields) => { },
  canAddComponent: (_component: Component) => true,
  addComponent: (_component: Component) => { },
  canRemoveComponent: (_position: number) => true,
  removeComponent: (_position: number, __isUsb: boolean) => { },
  undo: () => { },
  redo: () => { },
  canUndo: false,
  canRedo: false,
  componentDetail: (_component: Component) => ({ name: '', quantity: 0, exportPrice: 0, factoryPrice: 0, width: 0 }),
  allComponentDetails: () => new Array<ComponentDetail>(),
  isSaveable: () => false,
  getBlankNarrowComponent: (_component: Component) => { },
  generatePanelName: () => '',
};

export const CurrentConfiguration = createContext<CurrentConfigurationContext>(CONFIGURATION_DEFAULT_VALUE);

export const useCurrentConfiguration = (): CurrentConfigurationContext => useContext(CurrentConfiguration);

export const useCurrentConfigurationContext = (): CurrentConfigurationContext => {
  const [configuration, setConfiguration] = useState<Configuration | null>(null);
  const [componentStateHistory, setComponentStateHistory] = useState<Array<Component[]>>([]);
  const [componentStatePointer, setComponentStatePointer] = useState<number>(-1);
  const [blankNarrow, setBlankNarrow] = useState<Component | null>(null);
  const { data: defaultComponents, error: defaultsError } = useGetDefaultComponentsQuery({
    fetchPolicy: 'network-only',
  });
  const { data: basePriceList, error: basePriceError } = useGetBasePriceListQuery(
    {
      fetchPolicy: 'cache-and-network',
    },
  );
  if (defaultsError) {
    console.error('Loading Default Components Error:', defaultsError);
  }
  if (basePriceError) {
    console.error('Loading Default BasePrice Error:', basePriceError);
  }

  const canUndo = (configuration ?? false) && componentStatePointer != -1 && (configuration?.components.length ?? 0) > 3;
  const canRedo = (configuration ?? false) && componentStatePointer != (componentStateHistory.length - 1);
  const currentWidth = configuration?.components.reduce<number>((acc, c) => acc + c.width, 0) ?? 0;
  // Added components we care about for determining if we can add/remove new components
  const addedComponents = configuration?.components.filter(
    c => !(c.type == ComponentType.Logo || c.type == ComponentType.Filler || c.type == ComponentType.EndPlug),
  ) ?? [];
  const reset = useCallback((plugRegion: PlugRegion, width: PanelWidth, customWidth: number | undefined) => {
    setConfiguration(makeConfiguration(plugRegion, width, customWidth, defaultComponents, basePriceList!));
    setComponentStateHistory([makeDefaultComponents(width, defaultComponents)]);
    setComponentStatePointer(0);
  }, [basePriceList, defaultComponents]);

  useEffect(() => {
    if (defaultComponents) {
      reset(PlugRegion.Schuko, PanelWidth.Small, undefined);
    }
  }, [defaultComponents, reset]);

  const updateConfiguration = (fieldsToUpdate: ConfigurationUpdateFields) => {
    if (configuration) {
      setConfiguration({
        ...configuration,
        ...fieldsToUpdate,
      });
    }
  };

  const canAddComponent = (component: Component): boolean => {
    if (!configuration) {
      return false;
    }
    // Can't be too long
    if (configuration.customWidth) {
      if ((currentWidth + component.width) > configuration.customWidth!) {
        return false;
      }
    } else if ((currentWidth + component.width) > panelWidth.get(configuration.width)!) {
      return false;
    }

    // Individual rules
    const last: Component | null = addedComponents.length == 0
      ? null
      : addedComponents[addedComponents.length - 1];

    const has2xUsb = addedComponents.filter(c => c.subtype == ComponentSubtype.Usb).length == 1
      ? true
      : false;

    // EStop MUST be first
    if (
      component.subtype == ComponentSubtype.EStop &&
      last
    ) {
      return false;

      // RCD MUST follow EStop or be first
    } else if (
      component.subtype == ComponentSubtype.Rcd &&
      last &&
      last?.subtype != ComponentSubtype.EStop
    ) {
      return false;

      // OnOff MUST follow EStop, RCD or be first
    } else if (
      component.subtype == ComponentSubtype.Onoff &&
      last &&
      last?.subtype != ComponentSubtype.EStop &&
      last?.subtype != ComponentSubtype.Rcd
    ) {
      return false;

      // Outlet MUST NOT follow Usb or OtherSocket
    } else if (
      component.subtype == ComponentSubtype.Outlet &&
      (
        last?.subtype == ComponentSubtype.Usb ||
        last?.type == ComponentType.OtherSocket
      )
    ) {
      return false;

      // Usb must follow Power_Socket or other Usb
    } else if (
      component.subtype == ComponentSubtype.Usb &&
      (
        last?.subtype != ComponentSubtype.Outlet &&
        last?.subtype != ComponentSubtype.Usb ||
        has2xUsb
      )
    ) {
      return false;

      // OtherSocket must follow PowerSocket or other OtherSocket
    } else if (
      component.type == ComponentType.OtherSocket &&
      last?.type != ComponentType.PowerSocket &&
      last?.type != ComponentType.OtherSocket
    ) {
      return false;
    }

    return true;
  };

  const getBlankNarrowComponent = (component: Component) => {
    return setBlankNarrow(component);
  };

  const addComponent = (component: Component) => {
    if (!configuration) {
      return;
    }

    const last = configuration.components.length - 1;
    const components = [
      ...configuration.components.slice(0, last),
      component,
      configuration.components[last],
    ];

    if (component.subtype == ComponentSubtype.Usb && blankNarrow != null) {
      components.splice(last, 0, blankNarrow);
    }

    setComponentStatePointer((componentStatePointer ?? -1) + 1);
    setComponentStateHistory([...componentStateHistory.slice(0, componentStatePointer + 1), components]);


    const { exportPrice, factoryPrice } = calculatePrices(components, configuration.width, basePriceList!);
    return setConfiguration({ ...configuration, exportPrice, factoryPrice, components });
  };

  const canRemoveComponent = (position: number): boolean => {
    if (!configuration) {
      return false;
    }

    const component = configuration.components[position];

    // Cannot remove Outlet if it's the last one and there are non-outlets to it's right
    if (component.subtype == ComponentSubtype.Outlet) {
      // If last before end plug, it can remove
      if (position == configuration.components.length - 2) {
        return true;
      }

      // If there are more than one, it can be removed
      const details = componentDetail(component);
      return details.quantity > 1;

    // Cannot remove Blank narrow if it's was followed by Usb
    } else if (
      component.name == 'Blank narrow' &&
      configuration.components[position + 1].subtype == ComponentSubtype.Usb) {
      return false;
    }

    return true;
  };

  const removeComponent = (position: number, isUsb: boolean) => {
    if (!configuration) {
      return;
    }

    // Note: Exclude 2 default components at start and 1 at the end
    if (position < 2 || position > (configuration.components.length - 2)) {
      throw new Error('Position out of bounds');
    }
    const components = removeByIndex(configuration.components, position, isUsb);

    setComponentStatePointer((componentStatePointer ?? -1) + 1);
    setComponentStateHistory([...componentStateHistory.slice(0, componentStatePointer + 1), components]);

    const { exportPrice, factoryPrice } = calculatePrices(components, configuration.width, basePriceList!);
    return setConfiguration({ ...configuration, exportPrice, factoryPrice, components });
  };

  const componentDetail = (component: Component): ComponentDetail => {
    if (!configuration) {
      return {
        name: '',
        quantity: 0,
        exportPrice: 0,
        factoryPrice: 0,
        width: 0,
      };
    }

    const quantity = configuration.components.filter(c => c.id == component.id).length;
    return {
      name: component.name,
      quantity: quantity,
      exportPrice: quantity * component.exportPrice,
      factoryPrice: quantity * component.factoryPrice,
      width: component.width,
    };
  };

  const allComponentDetails = () => {
    if (!configuration) {
      return [];
    }

    const map = configuration.components.reduce((acc, c, index) => {
      // Note: Exclude default components (2 at front and 1 at end)
      if (index <= 1 || index == (configuration.components.length - 1)) {
        return acc;
      }

      if (!(acc.has(c.id))) {
        acc.set(c.id, componentDetail(c));
      }
      return acc;
    }, new Map<string, ComponentDetail>());
    return Array.from(map.values());
  };

  const isSaveable = () => addedComponents.some(c => c.subtype == ComponentSubtype.Outlet);

  const undo = () => {
    if (!canUndo) {
      return;
    }
    const newPointer = componentStatePointer! - 1;
    const components = componentStateHistory[newPointer];
    setComponentStatePointer(newPointer);
    const { exportPrice, factoryPrice } = calculatePrices(components, configuration?.width!, basePriceList!);
    return setConfiguration({ ...configuration!, exportPrice, factoryPrice, components });
  };

  const redo = () => {
    if (!canRedo) {
      return;
    }
    const newPointer = componentStatePointer! + 1;
    const components = componentStateHistory[newPointer];
    setComponentStatePointer(newPointer);
    const { exportPrice, factoryPrice } = calculatePrices(components, configuration?.width!, basePriceList!);
    return setConfiguration({ ...configuration!, exportPrice, factoryPrice, components });
  };

  const generatePanelName = () => {
    if (!configuration) {
      return;
    }

    let name = '';

    // Panel width
    name = generateId(configuration.width, configuration.customWidth);

    // Number of sockets
    const sockets = configuration.components.filter(s => s.type == ComponentType.PowerSocket && s.subtype == ComponentSubtype.Outlet);
    name = name.concat(`, ${sockets.length.toString()}`);

    // Socket Type
    name = name.concat(`${regionName.get(configuration.plugRegion) != ''
      ? ' ' + regionName.get(configuration.plugRegion)
      : ''
    } socket${sockets.length > 1 ? 's' : ''}`);

    // List of other components
    const otherComponents = addedComponents.filter(
      c => !(c.subtype == ComponentSubtype.Outlet) && c.name,
    ).map(c=>c.name);

    const otherComponentCounts: { [key: string]: number } = {};

    otherComponents.forEach(element => {
      otherComponentCounts[element] = (otherComponentCounts[element] || 0) + 1;
    });

    let formulaNameComponents = {
      'switch': 0,
      'FCP': 0,
      'stop': 0,
      '2xUSB': 0,
      'CAT6A': 0,
      'HDMI': 0,
      'CM': 0,
    };

    Object.entries(otherComponentCounts).forEach((c) => {
      switch (c[0]) {
        case OtherComponentName.switch:
          formulaNameComponents.switch += c[1];
          break;
        case OtherComponentName.fcp:
          formulaNameComponents.FCP += c[1];
          break;
        case OtherComponentName.stop:
          formulaNameComponents.stop += c[1];
          break;
        case OtherComponentName.twoUsb:
          formulaNameComponents['2xUSB'] += c[1];
          break;
        case OtherComponentName.cat6a:
          formulaNameComponents.CAT6A += c[1];
          break;
        case OtherComponentName.hdmi:
          formulaNameComponents.HDMI += c[1];
          break;
        case OtherComponentName.twoCat6a:
          formulaNameComponents.CAT6A += (c[1] * 2);
          break;
        case OtherComponentName.twoHdmi:
          formulaNameComponents.HDMI += (c[1] * 2);
          break;
        case OtherComponentName.cat6aHdmi:
          formulaNameComponents.CAT6A += c[1];
          formulaNameComponents.HDMI += c[1];
          break;
        case OtherComponentName.cm:
          formulaNameComponents.CM += c[1];
          break;
      }
    });

    Object.entries(formulaNameComponents).forEach((c) => {
      name = name.concat(`${c[1] != 0
        ? `${c[1] > 1
          ? ` + ${c[1]}x${c[0]}`
          : ` + ${c[0]}`}`
        : ''}`);
    });

    return name;
  };

  return {
    ...CONFIGURATION_DEFAULT_VALUE,
    configuration,
    updateConfiguration,
    canAddComponent,
    addComponent,
    canRemoveComponent,
    removeComponent,
    componentDetail,
    allComponentDetails,
    isSaveable,
    reset,
    undo,
    redo,
    getBlankNarrowComponent,
    generatePanelName,
    canRedo,
    canUndo,
  };
};