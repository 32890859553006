import { useAuth0 } from '@auth0/auth0-react';
import { Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { useMenuState, MenuButton } from 'reakit';
import { useMe } from '../../hooks/Me.hook';
import Avatar from '../Navbar/avatar.svg';

function Profile() {
  const { t } = useTranslation();
  const menu = useMenuState();
  const { logout, user } = useAuth0();
  const me = useMe();

  return (
    <div className="ml-3 relative">
      <div>
        <MenuButton {...menu}
          className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300" id="user-menu" aria-expanded="false" aria-haspopup="true" >
          <span className="sr-only">{t('navbar.openProfile')}</span>
          <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
            <img className="h-full w-full text-gray-300" src={user?.picture ?? Avatar} alt="avatar" />
          </span>
        </MenuButton>
      </div>
      <Transition
        show={menu.visible}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div
          className="z-20 w-max origin-top-right absolute right-0 mt-2 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          <div className="ml-3 flex flex-col mt-2 mb-4 pr-3">
            <div className="text-base font-medium text-gray-800">{me?.name ?? ''}</div>
            <div className="text-sm font-medium text-gray-500">{me?.email ?? ''}</div>
          </div>
          <button onClick={() => logout({ returnTo: window.location.origin })} className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:shadow-none hover:ring-0 focus:outline-none">
            {t('navbar.logout')}
          </button>

        </div>
      </Transition>
    </div >
  );
}
export default Profile;