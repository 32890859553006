import { createContext, ReactNode, useContext, useState } from 'react';

export type ActionModalRouteFunc = () => void;

export interface ActionModalRoute {
  isOpen: boolean;
  open: ActionModalRouteFunc;
  close: ActionModalRouteFunc;
}

export interface ActionModalRouter {
  savePanel: ActionModalRoute;
  createUser: ActionModalRoute;
  createComponent: ActionModalRoute;
  viewList: ActionModalRoute;
  previewPanel: ActionModalRoute;
}

function useActionModalContext(): ActionModalRouter {
  const [savePanelOpen, setSavePanelOpen] = useState(false);
  const [createUserOpen, setCreateUserOpen] = useState(false);
  const [createComponentOpen, setCreateComponentOpen] = useState(false);
  const [previewPanelOpen, setPreviewPanelOpen] = useState(false);
  const [viewListOpen, setViewListOpen] = useState(true);

  return {
    savePanel: {
      isOpen: savePanelOpen,
      open: () => setSavePanelOpen(true),
      close: () => setSavePanelOpen(false),
    },
    createUser: {
      isOpen: createUserOpen,
      open: () => setCreateUserOpen(true),
      close: () => setCreateUserOpen(false),
    },
    createComponent: {
      isOpen: createComponentOpen,
      open: () => setCreateComponentOpen(true),
      close: () => setCreateComponentOpen(false),
    },
    viewList: {
      isOpen: viewListOpen,
      open: () => setViewListOpen(true),
      close: () => setViewListOpen(false),
    },
    previewPanel: {
      isOpen: previewPanelOpen,
      open: () => setPreviewPanelOpen(true),
      close: () => setPreviewPanelOpen(false),
    },
  };
}


const ActionModalContext = createContext<ActionModalRouter>({
  savePanel: {
    isOpen: false,
    open: () => { },
    close: () => { },
  },
  createUser: {
    isOpen: false,
    open: () => { },
    close: () => { },
  },
  createComponent: {
    isOpen: false,
    open: () => { },
    close: () => { },
  },
  viewList: {
    isOpen: false,
    open: () => { },
    close: () => { },
  },
  previewPanel: {
    isOpen: false,
    open: () => { },
    close: () => { },
  },
});

export interface ActionModalProps {
  children: ReactNode;
}

function ActionModal({ children }: ActionModalProps) {
  return (
    <ActionModalContext.Provider value={useActionModalContext()}>
      { children}
    </ActionModalContext.Provider>
  );
}

export function useActionModal(): ActionModalRouter {
  return useContext(ActionModalContext);
}

export default ActionModal;