import { useEffect } from 'react';
import { useActionModal } from '../../hooks/ActionModal.hook';
import { useCurrentConfiguration } from '../../hooks/CurrentConfiguration.hook';
import { useCheckDuplicatePanelLazyQuery, CheckDuplicatePanelQueryVariables, PlugRegion, PanelWidth } from '../../lib/graphql.generated';
import SaveAlertModal from './SaveAlertModal.component';
import SavePanelModal from './SavePanelModal.component';

export default function SavePanel() {
  const { configuration } = useCurrentConfiguration();
  const { savePanel: { isOpen } } = useActionModal();

  const [checkForDuplicates, {
    loading,
    data,
  }] = useCheckDuplicatePanelLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (isOpen) {
      const checkPanel: CheckDuplicatePanelQueryVariables = {
        width: configuration?.width ?? PanelWidth.Small,
        plugRegion: configuration?.plugRegion ?? PlugRegion.Schuko,
        components: configuration?.components.map((i) => i.id) ?? [],
      };
      checkForDuplicates({ variables: checkPanel });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (loading) {
    return null;
  } else if (data) {
    if (data?.duplicateConfiguration?.id) {
      return <SaveAlertModal id={data?.duplicateConfiguration?.id} />;
    } else {
      return <SavePanelModal />;
    }
  } else {
    return null;
  }
}