import { useTranslation } from 'react-i18next';
import { useCurrentConfiguration } from '../../hooks/CurrentConfiguration.hook';
import { formatWidth } from '../../lib/format';
import { Component } from '../../lib/graphql.generated';

export interface ComponentProps {
  component: Pick<Component, 'id' | 'name' | 'exportPrice' | 'factoryPrice' | 'width' | 'svg'>;
}
function ComponentItem({ component }: ComponentProps) {
  const { t } = useTranslation();
  const { name, width } = component;
  const { addComponent, canAddComponent } = useCurrentConfiguration();
  return (
    <li className="py-4 px-4 flex flex-row justify-between">
      <div className="ml-3">
        <p className="text-sm font-medium text-gray-900">{name}</p>
        <p className="text-sm text-gray-500">w: {formatWidth(width)}</p>
      </div>
      <button
        onClick={() => addComponent(component as Component)}
        disabled={!canAddComponent(component as Component)}
        className="inline-flex items-center px-3 py-0 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-green-400 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-300 disabled:bg-primary-grey-200 disabled:cursor-not-allowed"
      >
        {t('panel.button.add')}
      </button>
    </li >
  );
}
export default ComponentItem;