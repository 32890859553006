import { ChevronRightIcon } from '@heroicons/react/solid';
import { Tab, TabStateReturn } from 'reakit';

interface TabItemProps {
  id: string;
  tab: TabStateReturn;
  label: string;
}

function TabItem({ id, tab, label }: TabItemProps) {
  return (<Tab {...tab}
    id={id}
    className={
      `${tab.selectedId == id && 'bg-primary-grey-200'} 
      text-gray-600
      active:text-gray-900
      active:bg-primary-grey-200
      focus:border-transparent
      focus:outline-none
      focus:ring-transparent
      ${tab.selectedId != id && 'hover:bg-gray-50 hover:text-gray-900'}
      group
      flex
      items-center
      w-full
      px-2
      py-2
      text-sm
      font-medium
      rounded-md`}
    role="menuitem">
    <span className="flex-row flex justify-between w-full items-center">
      <span>{label}</span>
      {tab.selectedId != id && <ChevronRightIcon className="text-gray-500 h-6 w-6" />}
    </span>
  </Tab>);
}

export default TabItem;