/* eslint-disable import/no-extraneous-dependencies */
import { ApolloClient, InMemoryCache, ApolloLink, ApolloProvider } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode } from 'react';

interface AuthorizedApolloProviderProps {
  children: ReactNode;
}

const AuthorizedApolloProvider = ({ children }: AuthorizedApolloProviderProps) => {
  const { getIdTokenClaims } = useAuth0();
  const authMiddleware = setContext(async (_, { headers, ...context }) => {
    const token = await getIdTokenClaims();

    if (token && process.env.REACT_APP_NODE_ENV == 'development') {
      console.log(
        'PLAYGROUND_HEADER:',
        JSON.stringify({ Authorization: `Bearer ${token.__raw} }` }),
      );
    }

    return {
      ...context,
      headers: {
        ...headers,
        ...(token ? { Authorization: `Bearer ${token.__raw}` } : {}),
      },
    };
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          );
        }
        if (networkError) {
          console.error('[Network error]:', networkError);
        }
      }),
      authMiddleware,
      new RetryLink(),
      new BatchHttpLink({ uri: '/graphql' }),

    ]),
  });

  return <ApolloProvider client={client}> {children} </ApolloProvider>;
};

export default AuthorizedApolloProvider;