import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid';
import { Dispatch, Fragment, SetStateAction, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUpdateConfigurationMutation, UpdateConfigurationMutationVariables } from '../../lib/graphql.generated';

type FormValues = {
  name: string;
};
interface EditPanelInfoModalProps {
  id: string;
  show: boolean;
  name: string;
  setClose: Dispatch<SetStateAction<boolean>>;
  next: () => void;
}
export default function EditPanelInfoModal({
  id,
  show,
  name,
  setClose,
  next,
}: EditPanelInfoModalProps) {
  const { t } = useTranslation();
  const {
    register,
    reset,
    handleSubmit,
    getValues,
    formState: { isValid, errors: formErrors },
  } = useForm<FormValues>({
    mode: 'all',
  });


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const close = () => {
    setClose(false);
    reset();
  };

  const [updateConfigurationMutation, {
    data: saveData,
    error: saveError,
  }] = useUpdateConfigurationMutation();

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    const newConfig: UpdateConfigurationMutationVariables = {
      id: id,
      name: formData.name,
    };
    void updateConfigurationMutation({ variables: newConfig });
  };
  useEffect(() => {
    if (saveError) {
      console.error('SAVE ERROR:', saveError);
    } else if (saveData) {
      reset();
      close();
      if (saveData.updateConfiguration.id == id) {
        void next();
      }
    }
  }, [saveData, saveError, reset, close, next, id]);

  return (
    <Transition show={show} as={Fragment}>
      <Dialog
        id="edit-info"
        open={show}
        onClose={close}
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block align-bottom bg-white mt-auto mb-auto rounded-lg text-left sm:rounded-md overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full">
              <Dialog.Title className="flex flex-row justify-between top-0 right-0 py-4 px-4 border-b border-gray-200 w-full">
                <span className="text-lg leading-6 font-medium text-gray-900">{t('panel.modal.editInfoHeader')}</span>
                <button onClick={close} type="button" className="bg-white rounded-md text-gray-400 hover:text-red-500 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-gray-400">
                  <span className="sr-only">{t('modal.closeButton')}</span>
                  <XCircleIcon className="h-6 w-6" />
                </button>
              </Dialog.Title>
              <div className="px-4 pt-5 pb-4 sm:p-6">
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">{t('label.name')}</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            type="text"
                            id="name"
                            {...register('name', {
                              required: true,
                            })}
                            defaultValue={name}
                            onFocus={() => getValues('name') == name ? reset({ ...getValues(), name: '' }) : null}
                            placeholder={name}
                            className={`${formErrors.name ? 'focus:ring-primary-red-default focus:border-primary-red-default border-primary-red-default' : 'focus:ring-gray-400 focus:border-gray-400 border-gray-300'} mt-1 block w-full shadow-sm sm:text-sm rounded-md`}
                          />
                          {formErrors.name ? (
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none border-primary-red-default">
                              <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                            </div>
                          ) : null}
                        </div>
                        {formErrors.name && <p className="mt-2 text-sm text-red-600" id="name-error">{t('panel.modal.savePanelErrorInputName')}</p>}
                      </div>
                    </div>
                    <div className="mt-4 py-2 text-right space-x-1">
                      <button onClick={close} type="button" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-primary-gray-default bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400">
                        {t('button.cancel')}
                      </button>
                      <button
                        type="submit"
                        disabled={!isValid}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-secondary-orange-default hover:bg-secondary-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 disabled:cursor-not-allowed disabled:bg-gray-300"
                      >
                        {t('navbar.button.save')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}