import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon, UploadIcon, CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useRef, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { generatePath, useHistory } from 'react-router';
import * as z from 'zod';
import { useActionModal } from '../../hooks/ActionModal.hook';
import { formatCurrency, formatWidth, getNumberFormatting } from '../../lib/format';
import { PlugRegion, ComponentType, ComponentSubtype, useCreateComponentMutation, CreateComponentMutationVariables } from '../../lib/graphql.generated';
import TypeListBox, { TypeListOption } from '../ListBox/TypeListBox.component';

const componentSchema = z.object({
  name: z.string(),
  width: z.number().positive(),
  exportPrice: z.number().nonnegative(),
  factoryPrice: z.number().nonnegative(),
  svg: z.string().nonempty(),
  description: z.string().optional(),
  type: z.nativeEnum(ComponentType),
  subtype: z.nativeEnum(ComponentSubtype).optional(),
  region: z.nativeEnum(PlugRegion).optional(),
})
  .refine(data => {
    if (
      data.type == ComponentType.PowerSocket &&
      data.subtype == ComponentSubtype.Outlet
    ) {
      return data.region;
    } else {
      return true;
    }
  }, { message: 'Specify a region', path: ['region'] })
  .refine(data => {
    if (data.type == ComponentType.PowerSocket) {
      return data.subtype && [
        ComponentSubtype.Outlet,
        ComponentSubtype.Usb,
      ].includes(data.subtype);
    } else if (data.type == ComponentType.Switch) {
      return data.subtype && [
        ComponentSubtype.Rcd,
        ComponentSubtype.Onoff,
        ComponentSubtype.EStop,
      ].includes(data.subtype);
    } else {
      return true;
    }
  }, { message: 'A subtype is required', path: ['subtype'] });

type NewComponentData = z.infer<typeof componentSchema>;


function NewComponentModal() {
  const { t } = useTranslation();
  const history = useHistory();

  const [createComponentMutation, {
    data,
    error: createError,
  }] = useCreateComponentMutation();

  const { createComponent } = useActionModal();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isValid, dirtyFields, isSubmitting, errors: formErrors },
    trigger,
    getValues,
    setValue,
    watch,
  } = useForm<NewComponentData>({
    mode: 'all',
    resolver: zodResolver(componentSchema),
  });
  const watchFields = watch();
  useEffect(() => {
    if (!createComponent.isOpen) {
      reset();
    }
  }, [createComponent.isOpen, reset]);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const onSubmit = (formData: NewComponentData) => {
    let variables: CreateComponentMutationVariables;
    if (formData.region) {
      variables = {
        ...formData,
        plugRegions: [formData.region],
      };
    } else {
      const regions = Object.values(PlugRegion);
      variables = {
        ...formData,
        plugRegions: regions,
      };
    }
    void createComponentMutation({ variables });
  };

  useEffect(() => {
    if (createError) {
      console.error('Failed to create component', createError);
    } else if (data && data.createComponent.id) {
      createComponent.close();
      reset();
      history.push(generatePath('/components/:id', { id: data.createComponent.id }));
    }
  }, [data, createError, createComponent, history, reset]);

  const plugsOptions: TypeListOption<PlugRegion>[] = [
    { value: PlugRegion.Schuko, label: t('plugRegion.schuko') },
    { value: PlugRegion.Italian, label: t('plugRegion.italian') },
    { value: PlugRegion.French, label: t('plugRegion.french') },
    { value: PlugRegion.Danish, label: t('plugRegion.danish') },
    { value: PlugRegion.British, label: t('plugRegion.british') },
    { value: PlugRegion.Swiss, label: t('plugRegion.swiss') },
  ];

  const typeOptions: TypeListOption<ComponentType>[] = [
    { value: ComponentType.PowerSocket, label: t('component.type.power') },
    { value: ComponentType.Switch, label: t('component.type.switch') },
    { value: ComponentType.Filler, label: t('component.type.filler') },
    { value: ComponentType.OtherSocket, label: t('component.type.other') },
  ];

  const subtypePossibleOptions: TypeListOption<ComponentSubtype>[] = [
    { value: ComponentSubtype.EStop, label: t('component.subType.emergency') },
    { value: ComponentSubtype.Onoff, label: t('component.subType.onOff') },
    { value: ComponentSubtype.Outlet, label: t('component.subType.outlet') },
    { value: ComponentSubtype.Rcd, label: t('component.subType.circuitBreaker') },
    { value: ComponentSubtype.Usb, label: t('component.subType.usb') },
  ];

  let subtypeOptions: TypeListOption<ComponentSubtype>[] = [];
  if (getValues('type') == ComponentType.PowerSocket) {
    subtypeOptions = subtypePossibleOptions.filter((i) => [
      ComponentSubtype.Outlet,
      ComponentSubtype.Usb,
    ].includes(i.value));
  } else if (getValues('type') == ComponentType.Switch) {
    subtypeOptions = subtypePossibleOptions.filter((i) => [
      ComponentSubtype.Rcd,
      ComponentSubtype.Onoff,
      ComponentSubtype.EStop,
    ].includes(i.value));
  }

  const showSubtype: boolean = watchFields.type !== ComponentType.PowerSocket && watchFields.type !== ComponentType.Switch;
  const hasSvg: boolean = watchFields.svg ? true : false;
  const hasName: boolean = watchFields.name ? true : false;

  return (
    <Transition show={createComponent.isOpen}>
      <Dialog
        open={createComponent.isOpen}
        onClose={createComponent.close}
        className="fixed z-10 inset-0 overflow-y-auto"
      >
        <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block align-bottom bg-white mt-auto mb-auto rounded-lg text-left sm:rounded-md overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full">

              <Dialog.Title className="flex flex-row justify-between top-0 right-0 py-4 px-4 border-b border-gray-200 w-full">
                <span className="text-lg leading-6 font-medium text-gray-900">{t('navbar.button.component')}</span>
                <button onClick={createComponent.close} type="button" className="bg-white rounded-md text-gray-400 hover:text-red-500 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-gray-400">
                  <span className="sr-only">{t('modal.closeButton')}</span>
                  <XCircleIcon className="h-6 w-6" />
                </button>
              </Dialog.Title>
              <div className="px-4 pt-5 pb-4 sm:p-6">
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">{t('label.name')}</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            type='text'
                            {...register('name')}
                            placeholder={t('label.name')}
                            defaultValue={''}
                            className={`${!hasName && dirtyFields.name ? 'focus:ring-primary-red-default focus:border-primary-red-default border-primary-red-default' : 'focus:ring-gray-400 focus:border-gray-400 border-gray-300'} mt-1 block w-full shadow-sm sm:text-sms rounded-md`}
                          />
                          {!hasName && dirtyFields.name ? (
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="type" className="block text-sm font-medium text-gray-700">{t('label.type')}</label>
                        <Controller
                          name='type'
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <TypeListBox
                              label={t('component.modal.typeBox')}
                              options={typeOptions}
                              isDisabled={false}
                              value={value}
                              onChange={(newValue: ComponentType) => {
                                onChange(newValue);
                                setValue('subtype', undefined);
                                setValue('region', undefined);
                              }}
                            />
                          )}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="width" className="block text-sm font-medium text-gray-700">{t('label.width')}</label>
                        <input
                          {...register('width', { valueAsNumber: true })}
                          className="sr-only"
                        />
                        <Controller
                          name="width"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange } }) => (
                            <div className="mt-1 relative rounded-md shadow-sm">
                              <NumberFormat
                                id="width"
                                placeholder={formatWidth(0)!}
                                onValueChange={(values) => {
                                  const { value } = values;
                                  onChange(parseFloat(value));
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={getNumberFormatting().thousandsSeparator}
                                decimalSeparator={getNumberFormatting().decimalSeparator}
                                allowNegative={false}
                                className={`${formErrors.width ? 'focus:ring-primary-red-default focus:border-primary-red-default border-primary-red-default' : 'focus:ring-gray-400 focus:border-gray-400 border-gray-300'} mt-1 block w-full shadow-sm sm:text-sms rounded-md`}
                              />
                              {formErrors.width ? (
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                                </div>
                              ) : null}
                            </div>
                          )}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="subType" className="block text-sm font-medium capitalize text-gray-700">{t('component.modal.subtypeLabel')}</label>
                        <Controller
                          name='subtype'
                          control={control}
                          rules={{ required: !showSubtype }}
                          render={({ field: { onChange, value } }) => (
                            <TypeListBox
                              label={t('component.modal.subtypeBox')}
                              options={subtypeOptions}
                              value={value}
                              onChange={(newValue: ComponentSubtype) => {
                                onChange(newValue);
                                setValue('region', undefined);
                              }}
                              isDisabled={showSubtype}
                            />
                          )}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="exportPrice" className="block text-sm font-medium text-gray-700">{t('label.exportPrice')}</label>
                        <input
                          {...register('exportPrice', { valueAsNumber: true })}
                          className="sr-only"
                        />
                        <Controller
                          name='exportPrice'
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange } }) => (
                            <div className="mt-1 relative rounded-md shadow-sm">
                              <NumberFormat
                                id="exportPrice"
                                placeholder={formatCurrency(0)!}
                                onValueChange={(values) => {
                                  const { value } = values;
                                  onChange(parseFloat(value));
                                }}
                                suffix={'€'}
                                decimalScale={2}
                                thousandSeparator={getNumberFormatting().thousandsSeparator}
                                decimalSeparator={getNumberFormatting().decimalSeparator}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                className={`${formErrors.exportPrice ? 'focus:ring-primary-red-default focus:border-primary-red-default border-primary-red-default' : 'focus:ring-gray-400 focus:border-gray-400 border-gray-300'} mt-1 block w-full shadow-sm sm:text-sms rounded-md`}
                              />
                              {formErrors.exportPrice ? (
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                                </div>
                              ) : null}
                            </div>
                          )}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="plugRegion" className="block text-sm font-medium capitalize text-gray-700">{t('label.region')}</label>
                        <Controller
                          name='region'
                          control={control}
                          rules={{ required: (watchFields.subtype == ComponentSubtype.Outlet && showSubtype) }}
                          render={({ field: { onChange, value } }) => (
                            <TypeListBox
                              label={t('component.modal.regionBox')}
                              value={value}
                              onChange={(newValue: PlugRegion) => {
                                onChange(newValue);
                              }}
                              options={plugsOptions}
                              isDisabled={watchFields.subtype !== ComponentSubtype.Outlet}
                            />
                          )}
                        />

                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="factoryPrice" className="block text-sm font-medium text-gray-700">{t('label.factPrice')}</label>
                        <input
                          {...register('factoryPrice', { valueAsNumber: true })}
                          className="sr-only"
                        />
                        <Controller
                          name='factoryPrice'
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange } }) => (
                            <div className="mt-1 relative rounded-md shadow-sm">
                              <NumberFormat
                                id="factoryPrice"
                                placeholder={formatCurrency(0)!}
                                onValueChange={(values) => {
                                  const { value } = values;
                                  onChange(parseFloat(value));
                                }}
                                suffix={'€'}
                                thousandSeparator={getNumberFormatting().thousandsSeparator}
                                decimalSeparator={getNumberFormatting().decimalSeparator}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                allowNegative={false}
                                className={`${formErrors.factoryPrice ? 'focus:ring-primary-red-default focus:border-primary-red-default border-primary-red-default' : 'focus:ring-gray-400 focus:border-gray-400 border-gray-300'} mt-1 block w-full shadow-sm sm:text-sms rounded-md`}
                              />
                              {formErrors.factoryPrice ? (
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                                </div>
                              ) : null}
                            </div>
                          )}
                        />

                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="svgFile" className="block text-sm font-medium capitalize text-gray-700">{t('component.modal.imageLabel')}</label>
                        <input
                          type="file"
                          id="svg"
                          {...register('svg', { required: true })}

                          ref={hiddenFileInput}
                          onChange={(e) => {
                            const files = e.target.files;
                            if (files && files.length > 0) {
                              const reader = new FileReader();
                              reader.onload = function (evt) {
                                setValue(
                                  'svg',
                                  btoa(evt?.target?.result?.toString() ?? ''),
                                );
                                void trigger();
                              };
                              reader.readAsText(files[0]);
                            }
                          }}
                          className="hidden"
                        />
                        <button
                          type='button'
                          className="group inline-flex items-center px-3 py-2 mt-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-primary-gray-default bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-0"
                          onClick={() => hiddenFileInput?.current?.click()}
                        >{
                            hasSvg ? (
                              <>
                                <CheckCircleIcon className="-ml-0.5 mr-2 h-4 w-4 group-hover:text-green-400" />
                                <span className="capitalize">{t('component.modal.buttonSvgSuccess')}</span>
                              </>

                            ) : (
                              <>
                                <UploadIcon className="-ml-0.5 mr-2 h-4 w-4 group-hover:text-primary-red-default" />
                                <span>{t('component.modal.buttonSVG')}</span>
                              </>
                            )
                          }
                        </button>

                      </div>
                    </div>

                    <div className="col-span-6 mt-4">
                      <label htmlFor="description" className="block text-sm font-medium text-gray-700 capitalize">{t('label.description')}</label>
                      <textarea
                        id="description"
                        {...register('description')}
                        className="mt-1 focus:ring-gray-400 focus:border-gray-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="py-3 text-right space-x-1">
                      <button onClick={createComponent.close} type="button" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-primary-gray-default bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400">
                        {t('button.cancel')}
                      </button>
                      <button
                        type="submit"
                        disabled={isSubmitting || !isValid || !hasName}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-secondary-orange-default hover:bg-secondary-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 disabled:cursor-not-allowed disabled:bg-gray-300"
                      >
                        {t('button.create')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default NewComponentModal;