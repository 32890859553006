import { Switch, Route } from 'react-router-dom';
import BasePricePage from '../pages/BasePricePage.component';
import ComponentListPage from '../pages/ComponentListPage.component';
import ComponentViewPage from '../pages/ComponentViewPage.component';
import HomePage from '../pages/HomePage.component';
import NotFound from '../pages/NotFound.component';
import PanelCreatePage from '../pages/PanelCreatePage.component';
import PanelListPage from '../pages/PanelListPage.component';
import PanelViewPage from '../pages/PanelViewPage.component';
import UserViewPage from '../pages/UserViewPage.component';
import ProtectedRoute from './ProtectedRoute.component';

function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <HomePage />
      </Route>

      <ProtectedRoute path="/panels/new" component={PanelCreatePage} />

      <ProtectedRoute path="/panels/page/:page" component={PanelListPage} />

      <ProtectedRoute path="/panels/:id" component={PanelViewPage} />

      <ProtectedRoute path="/panels" component={PanelListPage} />

      <ProtectedRoute path="/members/:page" component={UserViewPage} />

      <ProtectedRoute path="/members" component={UserViewPage} />

      <ProtectedRoute path="/components/page/:page" component={ComponentListPage} />

      <ProtectedRoute path="/components/:id" component={ComponentViewPage} />

      <ProtectedRoute path="/components" component={ComponentListPage} />

      <ProtectedRoute path="/base_prices" component={BasePricePage} />

      <ProtectedRoute path="/base_prices" component={BasePricePage} />

      <ProtectedRoute path="/base_prices" component={BasePricePage} />

      <ProtectedRoute path="/base_prices" component={BasePricePage} />

      <Route path="*">
        <NotFound />
      </Route>
    </Switch>

  );
}

export default Routes;