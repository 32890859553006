import { createContext, ReactNode, useContext } from 'react';
import Spinner from '../components/Spinner.component';
import { useMeQuery, UserDetailsFragment } from '../lib/graphql.generated';

type User = UserDetailsFragment | null;

const MeContext = createContext<User>(null);

export interface MeProps {
  children: ReactNode;
}

function Me({ children }: MeProps) {
  let value: User = null;

  const { data, loading, error } = useMeQuery();
  if (loading) {
    return <Spinner />;
  } else if (error) {
    return <p>Cannot fetch user profile</p>;
  } else if (data) {
    value = data.me ?? null;
  }

  return (
    <MeContext.Provider value={value}>
      { children }
    </MeContext.Provider>
  );
}

export function useMe(): User {
  return useContext(MeContext);
}

export default Me;