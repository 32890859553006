import { ApolloQueryResult } from '@apollo/client';
import { Transition, Listbox } from '@headlessui/react';
import { AdjustmentsIcon, CheckIcon } from '@heroicons/react/solid';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import {
  Exact,
  GetComponentByIdQuery,
  Thickness,
  useGetThicknessQuery,
  useUpdateComponentSvgMutation,
} from '../../lib/graphql.generated';

export interface AdjustButtonProps {
  id: string;
  refetch: (variables?: Partial<Exact<{
    id: string;
  }>> | undefined) => Promise<ApolloQueryResult<GetComponentByIdQuery>>;
  thickness: Thickness;
}

interface ThicknessOption {
  value: Thickness;
  svg: string;
}

function AdjustButton({
  id,
  refetch,
  thickness,
}: AdjustButtonProps) {
  const { t } = useTranslation();
  const { data: thicknessData, loading } = useGetThicknessQuery({
    variables: { id },
    fetchPolicy: 'cache-only',
  });
  const [updateComponentSvg, {
    data: updateData,
    error: updateError,
  }] = useUpdateComponentSvgMutation();

  const [options, setOptions] = useState<ThicknessOption[]>([]);

  const handleOnchange = (selection: Thickness) => {
    void updateComponentSvg({ variables: { id, thickness: selection } });
  };

  useEffect(() => {
    if (thicknessData) {
      const newOptions: ThicknessOption[] = [
        {
          value: Thickness.None,
          svg: thicknessData.componentThicknessOptions.none,
        },
        {
          value: Thickness.Thick,
          svg: thicknessData.componentThicknessOptions.thick,
        },
        {
          value: Thickness.Thicker,
          svg: thicknessData.componentThicknessOptions.thicker,
        },
        {
          value: Thickness.Thickest,
          svg: thicknessData.componentThicknessOptions.thickest,
        },
      ];
      setOptions(newOptions);
    }
  }, [thicknessData, t]);

  useEffect(() => {
    if (updateError) {
      console.error('Failed to update thickness:', updateError);
    } else if (updateData && updateData.updateComponentSvg.id) {
      void refetch();
    }
  });

  if (loading) {
    return (
      <div className="h-full w-full inline-flex flex-1 items-center justify-center">
        <Loader
          type="Oval"
          color="#e2001a"
          height={20}
          width={20}
          timeout={5000} //5 secs
        />
      </div>);
  }

  return (
    <Listbox value={thickness} onChange={handleOnchange}>
      {() => (
        <div className="relative">
          <Listbox.Button className="group xl:text-md sm:text-base md:text-sm inline-flex flex-1 items-center px-4 py-2 border border-gray-200 shadow-sm text-sm font-medium rounded-md text-primary-gray-default bg-gray-100 hover:bg-gray-300 focus:outline-none focus:ring-0 focus:border-primary-grey-default">
            <AdjustmentsIcon className="-ml-1 mr-2 h-5 w-5 group-hover:text-primary-red-default " />
            <span className="text-base xl:text-md lg:text-md 2xl:text-md">{t('button.adjustComponent')}</span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Listbox.Options className="absolute z-10 w-max max-w-screen px-4 mt-3 sm:px-0 lg:max-w- bg-white rounded-md shadow-md">
              {options.map(({ value, svg }, index) => (
                <Listbox.Option
                  key={index}
                  value={value}
                  className={({ active }) =>
                    `${active ? 'text-gray-900 bg-gray-100' : 'text-gray-900'}
                          cursor-default select-none relative py-2 pl-10 pr-4`
                  }
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`${selected ? 'font-semibold' : 'font-normal'} text-base truncate flex-row inline-flex`}
                      >
                        <div
                          className="relative text-gray-500"
                          style={{
                            backgroundImage: `url("${svg}")`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            height: '2rem',
                            width: '2rem',
                          }}
                        />
                      </span>
                      {selected ? (
                        <span
                          className={`${active ? 'text-gray-600' : 'text-gray-600'}
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                        >
                          <CheckIcon className="w-4 h-4" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>

          </Transition>
        </div>
      )}
    </Listbox>
  );
}

export default AdjustButton;