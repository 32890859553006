import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { Fragment } from 'react';

export interface TypeListOption<TOption extends string> {
  value: TOption;
  label: string;
}

export interface ListBoxProps<TOption extends string> {
  options: TypeListOption<TOption>[];
  isDisabled: boolean;
  label: string;
  value?: TOption;
  onChange: (newValue: TOption) => void;
}

export default function TypeListBox<TOption extends string>({
  options,
  isDisabled,
  label,
  value,
  onChange,
}: ListBoxProps<TOption>) {

  const buttonText = options.find(o => o.value == value)?.label ?? label;

  return (
    <Listbox
      as="div"
      disabled={isDisabled}
      value={value}
      onChange={onChange}
    >
      {({ open }) => (
        <div className="relative mt-1">
          <Listbox.Button
            className="border-gray-300 disabled:border-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg border focus:outline-none sm:text-sm"
          >
            <span className="block truncate capitalize">
              {buttonText}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronDownIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              static
              className="absolute w-full py-1 mt-1 overflow-auto text-base z-10 bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" tabIndex={-1} role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3"
            >
              {options.map((i, index)=>(
                <Listbox.Option
                  key={index}
                  value={i.value}
                  className={({ active }) => {
                    return (`${active ? 'text-gray-900 bg-gray-100' : 'text-gray-900'
                    } cursor-default select-none relative py-2 pl-10 pr-4`);
                  }}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`${
                          selected ? 'font-medium' : 'font-normal'
                        } block truncate capitalize`}
                      >
                        {i.label}
                      </span>
                      {selected ? (
                        <span
                          className={`${
                            active ? 'text-gray-600' : 'text-gray-600'
                          } absolute inset-y-0 left-0 flex items-center pl-3`}
                        >
                          <CheckIcon
                            className="w-5 h-5"
                            aria-hidden="true"
                          />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}