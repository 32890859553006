/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { Transition } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import { useCurrentConfiguration } from '../../hooks/CurrentConfiguration.hook';
import { Component, ComponentSubtype } from '../../lib/graphql.generated';
import { mm2px } from '../../lib/measurement';

export interface SvgComponentProps {
  index: number;
  component: Component;
  scale: number;
}

function SvgComponent({ index, component, scale }: SvgComponentProps) {
  const { configuration, canRemoveComponent, removeComponent } = useCurrentConfiguration();
  const [showDelete, setShowDelete] = useState(false);

  if (!configuration) {
    return null;
  }

  const toggleDelete = () => setShowDelete(!showDelete);

  const dataUri = `url("${component.svg}")`;
  return (
    <>
      {index == (configuration.components.length - 1) && <div key="space" className="flex-grow" />}
      <div key={`${index}`} className="flex-none z-10">
        <div
          onMouseOver={toggleDelete}
          onMouseOut={toggleDelete}
          key={`${component.id}__${index}`}
          className="relative"
          style={{
            backgroundImage: dataUri,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: `${mm2px(50, scale)}px`,
            width: `${mm2px(component.width, scale)}px`,
          }}
        >
          {index >= 2 && index <= (configuration.components.length - 2) &&
            <Transition show={showDelete} >
              <div
                className="absolute z-30"
                style={{
                  top: `${mm2px(3, scale)}px`,
                  right: `${mm2px(2, scale)}px`,
                }}
              >
                <button
                  onClick={() => removeComponent(index, component.subtype==ComponentSubtype.Usb ? true : false )}
                  disabled={!canRemoveComponent(index)}
                  className="focus:outline-none focus:ring-0 cursor-pointer text-primary-red-default hover:text-primary-red-700 disabled:text-primary-grey-200 disabled:cursor-not-allowed"
                >
                  <span className="sr-only">Delete</span>
                  <XCircleIcon className="h-5 w-5 opacity-100" />
                </button>
              </div>
            </Transition>
          }
        </div>
      </div>
    </>
  );
}

export default SvgComponent;