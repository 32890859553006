import { useEffect } from 'react';
import { useCurrentConfiguration } from '../../hooks/CurrentConfiguration.hook';
import { Component, ComponentType, useGetPanelComponentQuery } from '../../lib/graphql.generated';
import Spinner from '../Spinner.component';
import ComponentItem from './ComponentItem.component';
import Region from './Region.component';


interface ComponentItemListProps {
  selection: ComponentType;
}

export default function ComponentItemList({ selection }: ComponentItemListProps) {
  const { configuration, getBlankNarrowComponent } = useCurrentConfiguration();
  const { data, loading } = useGetPanelComponentQuery({
    fetchPolicy: 'cache-first',
    variables: {
      type: selection,
      plugRegion: configuration?.plugRegion,
    },
  });

  useEffect(() => {
    const blankNarrow = data?.filler.find(f => f.name == 'Blank narrow');
    if (blankNarrow) {
      getBlankNarrowComponent(blankNarrow as Component);
    }
  }, [data?.filler, getBlankNarrowComponent]);

  if (loading) {
    return <Spinner local={true} />;

  } else if (data) {
    let items = data.components.map((comp) => <ComponentItem key={comp.id} component={comp} />);
    items = items.concat(data.filler.map((comp) => <ComponentItem key={comp.id} component={comp} />));
    return (
      <>
        <Region />
        <ul className="divide-y divide-gray-200">
          {items}
        </ul>
      </>
    );

  } else {
    return null;
  }
}