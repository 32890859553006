import { ApolloQueryResult } from '@apollo/client';
import { TrashIcon } from '@heroicons/react/solid';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteAlertModal from '../../components/Common/DeleteAlertModal.component';
import {
  useDeleteUserMutation,
  ListUsersQueryVariables,
  ListUsersQuery,
  UserDetailsFragment,
} from '../../lib/graphql.generated';


interface DeleteProps {
  id: string;
  refetch: (variables?: Partial<ListUsersQueryVariables>) => Promise<ApolloQueryResult<ListUsersQuery>>;
  changer: Dispatch<SetStateAction<UserDetailsFragment | undefined>>;
}

function DeleteUser({ id, refetch, changer }: DeleteProps) {
  const { t } = useTranslation();
  const [DeleteUserMutation, {
    data: deleteResult, loading, error: deleteError,
  }] = useDeleteUserMutation();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  let message: string | undefined;
  let error: string | undefined;
  if (deleteError) {
    error = t('user.error.cannotDelete');
  }
  if (loading) {
    message = t('user.awaitMsg.delete');
  }
  if (deleteResult) {
    changer(undefined);
  }

  return (
    <div>
      <div className="flex space-x-3  " >
        <div className="relative rounded-lg px-6 py-5 flex items-center space-x-2 mt-1 " >
          <div className="flex-shrink-0 " >
            {message && <p className="text-sm " >{message}</p>}
            {error && <p className="text-red-600 text-sm">{error}</p>}
          </div>
          <TrashIcon className={`h-4 w-4 text-gray-700 hover:text-red-600 ml-10 ${loading && 'animate-spin'}`}
            onClick={() => { setShowDeleteConfirm(true); }
            }
          />
        </div>

      </div>
      <DeleteAlertModal
        id={id}
        show={showDeleteConfirm}
        setClose={setShowDeleteConfirm}
        action={DeleteUserMutation}
        result={deleteResult}
        status={true}
        next={refetch}
      />
    </div>
  );
}
export default DeleteUser;