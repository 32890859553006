import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid';
import { parse } from 'query-string';
import { useTranslation } from 'react-i18next';
import { generatePath, Redirect, useHistory, useLocation, useParams } from 'react-router';
import FilterBar, {
  FilterConfig,
  FilterConfigs,
  ActiveState,
  PageParams,
  InputQueryOptions,
  makePageObj,
} from '../components/Common/FilterBar.component';
import NewComponentModal from '../components/Component/NewComponentModal.component';
import Spinner from '../components/Spinner.component';
import { useMe } from '../hooks/Me.hook';
import { formatWidth, translateComponentType } from '../lib/format';
import { ComponentType, ListComponentsQueryVariables, Role, useListComponentsQuery } from '../lib/graphql.generated';
import NotFound from './NotFound.component';

const LIMIT = 20;
const BASE_URL = '/components/page';

type ComponentTypeState = ComponentType | null;

interface FilterState {
  isActive: ActiveState;
  type: ComponentTypeState;
  search?: string;
}

function ComponentListPage() {
  const { t } = useTranslation();
  const me = useMe();
  const history = useHistory();

  const { search } = useLocation();
  const query: InputQueryOptions<FilterState> = parse(search);

  const { page: pageString } = useParams<PageParams>();
  const page = parseInt(pageString ?? '1');
  const offset = (page - 1) * LIMIT;

  const optionsType: FilterConfig<ComponentTypeState> = {
    label: t('component.filters.typeLabel'),
    options: [
      { value: ComponentType.PowerSocket, label: t('component.type.power') },
      { value: ComponentType.Switch, label: t('component.type.switch') },
      { value: ComponentType.Filler, label: t('component.type.filler') },
      { value: ComponentType.Logo, label: t('component.type.logo') },
      { value: ComponentType.EndPlug, label: t('component.type.endPlug') },
      { value: ComponentType.OtherSocket, label: t('component.type.other') },
      { value: null, label: t('component.filters.type.all') },
    ],
    default: null,
    enabled: true,
  };
  const optionsIsActive: FilterConfig<ActiveState> = {
    label: t('filters.label.status'),
    options: [
      { value: ActiveState.Active, label: t('component.filters.deleted.active') },
      { value: ActiveState.Deleted, label: t('component.filters.deleted.deleted') },
      { value: ActiveState.All, label: t('component.filters.deleted.all') },
    ],
    default: ActiveState.Active,
    enabled: true,
  };

  const filterOptions: FilterConfigs<FilterState> = {
    isActive: optionsIsActive,
    type: optionsType,
  };

  let selectedType: ComponentTypeState = null;
  if (query.type) {
    const selection = Object.keys(ComponentType)
      .map(i => ComponentType[i as keyof typeof ComponentType])
      .find(type => type == query.type);
    if (selection) {
      selectedType = selection;
    }
  }

  let selectedIsActive: ActiveState = ActiveState.Active;
  if (query.isActive) {
    const selection = Object.keys(ActiveState)
      .map(i => ActiveState[i as keyof typeof ActiveState])
      .find(state => state == query.isActive);
    if (selection) {
      selectedIsActive = selection;
    }
  }

  let filterState: FilterState = {
    isActive: selectedIsActive,
    type: selectedType,
  };

  let listVars: ListComponentsQueryVariables = {
    offset,
    limit: LIMIT,
  };

  if (query.search && query.search.length > 0) {
    listVars.search = query.search;
    filterState.search = query.search;
  }
  if (filterState.type && filterState.type != optionsType.default) {
    listVars.type = filterState.type;
  }
  if (filterState.isActive != ActiveState.All) {
    listVars.isActive = filterState.isActive == ActiveState.Active ?
      true :
      false;
  }

  const { data, loading, error } = useListComponentsQuery({
    variables: listVars,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  if (me?.role != Role.Admin) {
    return <NotFound />;
  }

  function handleOnClick(id: string) {
    history.push(generatePath('/components/:id', { id }));
  }

  if (!loading && page > 1 && data?.components.length == 0) {
    return <Redirect to={makePageObj(BASE_URL, 1, filterState, filterOptions)} />;
  }

  return (
    <div className="pb-5 flex w-full flex-col items-center">
      <div className="flex flex-col flex-1 w-full xl:max-w-screen-lg justify-center">
        <FilterBar
          values={filterState}
          next={(newValues: Partial<FilterState>) =>
            history.push(makePageObj(BASE_URL, 1, newValues, filterOptions))}
          options={filterOptions}
        />
        <div className="py-2 align-middle inline-block min-w-full items-center sm:px-6 lg:px-8">
          {(() => {
            if (loading) {
              return <Spinner local={true} />;
            } else if (error) {
              return <p>Error: {error}</p>;
            } else {
              return (
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg ">

                  <table className="min-w-full divide-y divide-gray-200 stripped ">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {t('label.name')}
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {t('label.type')}
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {t('label.width')}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {(data?.components?.length ?? 0) > 0 && (data?.components.map((item, index) => {
                        const dataUri = `url("${item.svg}")`;
                        return (
                          <tr key={index} onClick={() => handleOnClick(item.id)} className="hover:bg-gray-200 cursor-pointer">
                            <td className="px-6 py-4 whitespace-nowrap inline-flex items-center text-sm font-medium text-gray-900">
                              <div
                                className="relative text-gray-800 mr-3"
                                style={{
                                  backgroundImage: dataUri,
                                  backgroundRepeat: 'no-repeat',
                                  backgroundPosition: 'center',
                                  height: '4rem',
                                  width: '4rem',
                                }}
                              />
                              {item.name}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {translateComponentType(item.type)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {formatWidth(item.width)}
                            </td>
                          </tr>
                        );
                      }))}
                    </tbody>
                  </table>
                  {(data?.components?.length ?? 0) == 0 &&
                    <h3 className="text-primary-grey-default text-md text-center">
                      {t('table.NoData')}
                    </h3>
                  }
                </div>
              );
            }
          })()}
        </div>

        <nav className="flex-shrink-0 border-t border-gray-200 px-4 flex items-center justify-between sm:px-0 min-w-full">
          <div className="-mt-px w-0 flex-1 flex">

            <button disabled={page == 1} className="disabled:text-gray-300 disabled:cursor-not-allowed border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:ring-0 focus:outline-none"
              onClick={() => {
                history.push(makePageObj(BASE_URL, page - 1, filterState, filterOptions));
              }}
            >
              <ArrowNarrowLeftIcon className="mr-3 h-5 w-5" />
              {t('table.previous')}
            </button>
          </div>
          <div className="-mt-px w-0 flex-1 flex justify-end">
            <button disabled={data?.components.length! < LIMIT} className="disabled:text-gray-300 disabled:cursor-not-allowed border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:ring-0 focus:outline-none"
              onClick={() => {
                history.push(makePageObj(BASE_URL, page + 1, filterState, filterOptions));
              }}
            >
              {t('table.next')}
              <ArrowNarrowRightIcon className="mr-3 h-5 w-5" />
            </button>
          </div>
        </nav>
      </div>
      <NewComponentModal />
    </div>
  );
}

export default ComponentListPage;