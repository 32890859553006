import { Maybe, PanelWidth, PlugRegion } from './graphql.generated';

export const generateId = (width: PanelWidth, customWidth?: Maybe<number>) => {
  const prefix: string = 'TPR';
  let indicator: string;

  switch (width) {
    case PanelWidth.Xsmall:
      indicator = '3';
      break;
    case PanelWidth.Small:
      indicator = '4';
      break;
    case PanelWidth.Medium:
      indicator = '7';
      break;
    case PanelWidth.Large:
      indicator = '9';
      break;
    case PanelWidth.Xlarge:
      indicator = '14';
      break;
    case PanelWidth.Xxlarge:
      indicator = '17';
      break;
    case PanelWidth.Custom:
      indicator = Math.floor(customWidth! / 100).toString();
      break;
    default:
      const message = 'INVALID Panel Width';
      console.error(message);
      throw message;
  }

  return `${prefix}${indicator}`;
};

export const regionName = new Map<PlugRegion, string>([
  [PlugRegion.Schuko, ''],
  [PlugRegion.Italian, 'IT'],
  [PlugRegion.French, 'FR'],
  [PlugRegion.Danish, 'DK'],
  [PlugRegion.British, 'UK'],
  [PlugRegion.Swiss, 'CH'],
]);

export enum OtherComponentName {
  switch = 'On/off switch',
  fcp = 'Fault current protection',
  stop = 'Emergency stop',
  twoUsb = '2xUSB-charger',
  cat6a = '1xCAT6A',
  hdmi = '1xHDMI',
  twoCat6a = '2xCAT6A',
  twoHdmi = '2xHDMI',
  cat6aHdmi = 'CAT6A + HDMI',
  cm = 'Custom module empty',
}

