import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/solid';
import { Dispatch, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UpdateUserMutationVariables, UserDetailsFragment, useUpdateUserMutation } from '../../lib/graphql.generated';

export interface ModalMessage {
  isOpen: boolean;
  closeModalMessage: any;
  idMessage: string;
  nameMessage: string;
  emailMessage: string;
  changer: Dispatch<React.SetStateAction<UserDetailsFragment | undefined>>;
}
function EditUser({
  isOpen: openModalMessage,
  closeModalMessage,
  idMessage,
  nameMessage,
  emailMessage,
  changer,
}: ModalMessage) {


  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: {
      errors: formErrors,
      isValid,
      isSubmitting,
      isDirty,
    },
    reset,
  } = useForm<UpdateUserMutationVariables>({
    mode: 'all',
  });
  const [
    UpdateUserMutation,
    { data, loading, error: saveError },
  ] = useUpdateUserMutation();
  const onSubmit = (vars: UpdateUserMutationVariables) => {
    vars = { ...vars, id: idMessage };
    void UpdateUserMutation({ variables: vars });
    changer(undefined);
    reset();
  };
  let message: string | undefined;
  let error: string | undefined;
  if (formErrors.email
    || formErrors.name
  ) {
    console.error(formErrors);
    error = t('user.modal.error.fromError');
  }
  if (formErrors.role) {
    console.error(formErrors);
    error = t('user.modal.error.roleError');
  }
  if (saveError) {
    console.error(saveError);
    error = t('user.modal.error.cannotSaver');
  }
  if (loading) {
    message = t('user.awaitMsg.save');
  }
  if (data && !data?.updateUser?.id) {
    error = t('user.modal.error.alreadyExisted');
  } else if (data) {
    message = t('user.modal.saveSuccessful');
  }

  return (
    <Transition show={openModalMessage}>
      <Dialog
        open={openModalMessage}
        onClose={closeModalMessage}
        className="fixed z-10 inset-0 overflow-y-auto"
      >
        <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block align-bottom bg-white mt-auto mb-auto rounded-lg text-left sm:rounded-md overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full">
              <Dialog.Title className="flex flex-row justify-between top-0 right-0 py-4 px-4 border-b border-gray-200 w-full">
                <span className="text-lg leading-6 font-medium text-gray-900">{t('user.modal.EditMemberHeader')}</span>
                <button onClick={closeModalMessage} type="button" className="bg-white rounded-md text-gray-400 hover:text-red-500 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-gray-400">
                  <span className="sr-only">{t('modal.closeButton')}</span>
                  <XCircleIcon className="h-6 w-6" />
                </button>
              </Dialog.Title>
              <div className="px-4 pt-5 pb-4 sm:p-6">
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">{t('label.name')}</label>
                        <input
                          type="text"
                          id="name"
                          {...register('name', { required: true })}
                          defaultValue={nameMessage}
                          className="mt-1 focus:ring-gray-400 focus:border-gray-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-3 sm:col-span-3">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">{t('user.modal.emailLabel')}</label>
                        <input
                          type="email"
                          placeholder={t('user.modal.emailLabel')}
                          defaultValue={emailMessage}
                          className="mt-1 focus:ring-gray-400 focus:border-gray-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          {...register('email', { required: true })}
                        />
                      </div>
                    </div>
                    <div className="py-3 text-right space-x-1">
                      <button onClick={closeModalMessage} type="button" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-primary-gray-default bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400">
                        {t('button.cancel')}
                      </button>
                      <button
                        type="submit"
                        disabled={isSubmitting || !isValid || !isDirty}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-secondary-orange-default hover:bg-secondary-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 disabled:cursor-not-allowed disabled:bg-gray-300"
                      >
                        {t('button.update')}
                      </button>
                    </div>
                  </form>
                  {message && <p className="text-sm">{message}</p>}
                  {error && <p className="text-red-600 text-sm">{error}</p>}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>

  );
}
export default EditUser;