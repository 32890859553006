import { makeVar, useReactiveVar } from '@apollo/client';
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid';
import { parse } from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useLocation, useParams } from 'react-router';
import FilterBar, {
  FilterConfig,
  FilterConfigs,
  ActiveState,
  PageParams,
  InputQueryOptions,
  makePageObj,
} from '../components/Common/FilterBar.component';
import { TypeListOption } from '../components/ListBox/TypeListBox.component';
import Spinner from '../components/Spinner.component';
import Card from '../components/User/Card.component';
import CreateUser from '../components/User/CreateUser.component';
import ListTableItem from '../components/User/ListTableItem.component';
import { useMe } from '../hooks/Me.hook';
import { ListUsersQueryVariables, Role, useListUsersQuery, UserDetailsFragment } from '../lib/graphql.generated';
import NotFound from './NotFound.component';

const LIMIT = 20;
const BASE_URL = '/members';

type RoleState = Role | null;

interface FilterState {
  isActive: ActiveState;
  role: RoleState;
  search?: string;
}

export const deleteUser = makeVar<string | null>(null);
function UserViewPage() {
  const { t } = useTranslation();
  const me = useMe();
  const history = useHistory();

  const { search } = useLocation();
  const query: InputQueryOptions<FilterState> = parse(search);

  const { page: pageString } = useParams<PageParams>();
  const page = parseInt(pageString ?? '1');
  const offset = (page - 1) * LIMIT;

  const optionsRole: FilterConfig<RoleState> = {
    label: t('user.filters.roleLabel'),
    options: [
      { value: Role.Admin, label: t('user.role.admin') },
      { value: Role.Salesperson, label: t('user.role.salesperson') },
      { value: Role.Distributor, label: t('user.role.distributor') },
      { value: Role.DistributorWithoutPrice, label: t('user.role.distributor_with_out_price') },
      { value: null, label: t('user.filters.role.all') },
    ],
    default: null,
    enabled: true,
  };
  const roleSelected: TypeListOption<Role>[] = [
    { value: Role.Admin, label: t('user.role.admin') },
    { value: Role.Salesperson, label: t('user.role.salesperson') },
    { value: Role.Distributor, label: t('user.role.distributor') },
    { value: Role.DistributorWithoutPrice, label: t('user.role.distributor_with_out_price') },
  ];
  const optionsIsActive: FilterConfig<ActiveState> = {
    label: t('filters.label.status'),
    options: [
      { value: ActiveState.Active, label: t('user.filters.deleted.active') },
      { value: ActiveState.Deleted, label: t('user.filters.deleted.deleted') },
      { value: ActiveState.All, label: t('user.filters.deleted.all') },
    ],
    default: ActiveState.Active,
    enabled: true,
  };


  const filterOptions: FilterConfigs<FilterState> = {
    isActive: optionsIsActive,
    role: optionsRole,
  };

  let selectedRole: RoleState = null;
  if (query.role) {
    const selection = Object.keys(Role)
      .map(i => Role[i as keyof typeof Role])
      .find(type => type == query.role);
    if (selection) {
      selectedRole = selection;
    }
  }

  let selectedIsActive: ActiveState = ActiveState.Active;
  if (query.isActive) {
    const selection = Object.keys(ActiveState)
      .map(i => ActiveState[i as keyof typeof ActiveState])
      .find(state => state == query.isActive);
    if (selection) {
      selectedIsActive = selection;
    }
  }

  let filterState: FilterState = {
    isActive: selectedIsActive,
    role: selectedRole,
  };

  let listVars: ListUsersQueryVariables = {
    offset,
    limit: LIMIT,
  };

  if (query.search && query.search.length > 0) {
    listVars.search = query.search;
    filterState.search = query.search;
  }
  if (filterState.role && filterState.role != optionsRole.default) {
    listVars.role = filterState.role;
  }
  if (filterState.isActive != ActiveState.All) {
    listVars.isActive = filterState.isActive == ActiveState.Active ?
      true :
      false;
  }

  const { data, loading, error, refetch } = useListUsersQuery({
    variables: listVars,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const newUserList = useReactiveVar(deleteUser);
  useEffect(() => {
    void refetch();
  }, [newUserList, refetch]);

  const [selection, setSelection] = useState<UserDetailsFragment>();

  if (me?.role != Role.Admin) {
    return <NotFound />;
  }

  if (!loading && page > 1 && data?.users.length == 0) {
    return <Redirect to={makePageObj(BASE_URL, 1, filterState, filterOptions)} />;
  }

  return (
    <div className="min-h-content">
      <div className="relative top-0 left-0 w-1/2 h-full bg-white" aria-hidden="true"></div>
      <div className="relative top-0 right-0 w-1/2 h-full bg-white" aria-hidden="true"></div>
      <div className="relative min-h-screen flex flex-col">
        {/* <!-- 3 column wrapper --> */}
        <div className="flex-grow w-full max-w-7xl mx-auto xl:px-8 lg:flex">
          {/* <!-- Left sidebar & main wrapper --> */}
          <div className="flex-1 min-w-0 bg-white xl:flex">
            {/* <!-- center bar --> */}
            <div className="bg-white lg:min-w-0 lg:flex-1">
              <div className="pl-4 pr-6 pt-4  sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-6 xl:border-t-0">
                <div className="flex items-center">
                  <FilterBar
                    values={filterState}
                    next={(newValues: Partial<FilterState>) =>
                      history.push(makePageObj(BASE_URL, 1, newValues, filterOptions))}
                    options={filterOptions}
                  />
                </div>

              </div>
              <div className="z-0 divide-y divide-gray-200 ">
                {/* <Table /> */}
                <div className="mx-auto px-4 pt-2 overflow-visible">
                  <div className="flex flex-col">
                    <div className="-my-2 sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full w-10 sm:px-6 lg:px-8 ">
                        <>
                          {(() => {
                            if (loading) {
                              return <Spinner local={true} />;
                            } else if (error) {
                              return <p>Error: {error}</p>;
                            } else {
                              return (
                                <div className="shadow  border-b border-t border-gray-200 sm:rounded-lg">
                                  <table className="min-w-full  divide-y divide-gray-200 ">
                                    <tbody>
                                      {data?.users?.map((user: UserDetailsFragment) => {
                                        return (
                                          <ListTableItem key={user!.id} user={user!}
                                            changer={(word) => setSelection(word)}
                                            refetch={refetch}
                                            active={user?.isActive ?? false}
                                            roleSelected={roleSelected}
                                          />
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                  {(data?.users?.length ?? 0) == 0 &&
                                    <h3 className="text-primary-grey-default text-md text-center">
                                      {t('table.NoData')}
                                    </h3>
                                  }
                                </div>
                              );
                            }
                          })()}
                          <nav className="flex-shrink-0 border-t border-gray-200 px-4 flex items-center justify-between sm:px-0 min-w-full">
                            <div className="-mt-px w-0 flex-1 flex">

                              <button disabled={page == 1} className="disabled:text-gray-300 disabled:cursor-not-allowed border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:ring-0 focus:outline-none"
                                onClick={() => {
                                  history.push(makePageObj(BASE_URL, page - 1, filterState, filterOptions));
                                }}>
                                <ArrowNarrowLeftIcon className="mr-3 h-5 w-5" />
                                {t('table.previous')}
                              </button>
                            </div>
                            <div className="-mt-px w-0 flex-1 flex justify-end">
                              <button disabled={data?.users.length! < LIMIT} className="disabled:text-gray-300 disabled:cursor-not-allowed border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:ring-0 focus:outline-none"
                                onClick={() => {
                                  history.push(makePageObj(BASE_URL, page + 1, filterState, filterOptions));
                                }}
                              >
                                {t('table.next')}
                                <ArrowNarrowRightIcon className="mr-3 h-5 w-5" />
                              </button>
                            </div>
                          </nav>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Card --> */}
          <div className="pr-4 sm:pr-6 lg:pr-8 lg:flex-shrink-0 lg:border-l lg:border-gray-200 xl:pr-0 " >
            <div className="pl-6 lg:w-80">
              <div>
                <ul className="divide-y divide-gray-200">
                  <li className="py-4">
                    <div className="flex space-x-3">
                      <div className="flex-1 space-y-1">
                        <ul >
                          <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide</li>-y divide-gray-200">
                            {selection ? <Card roleSelected={roleSelected} selection={selection} /> : null}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateUser refetch={refetch} roleSelected={roleSelected} />
    </div>
  );

}


export default UserViewPage;
