import { PencilAltIcon } from '@heroicons/react/solid';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface SetComponentPricesButtonProps {
  action: Dispatch<SetStateAction<boolean>>;
}
export default function SetComponentPricesButton({ action }: SetComponentPricesButtonProps) {
  const { t } = useTranslation();
  return (
    <div className="relative">
      <button type="button" onClick={() => action(true)} className="group xl:text-md sm:text-base md:text-sm inline-flex flex-1 items-center px-4 py-2 border border-gray-200 shadow-sm text-sm font-medium rounded-md text-primary-gray-default bg-gray-100 hover:bg-gray-300 focus:outline-none focus:ring-0 focus:border-primary-grey-default">
        <PencilAltIcon className="-ml-1 mr-2 h-5 w-5 group-hover:text-primary-red-default" />
        <span className="text-base xl:text-md lg:text-md 2xl:text-md">{t('panel.button.setPrice')}</span>
      </button>
    </div>
  );
}