import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { MenuStateReturn } from 'reakit';
import { useMe } from '../../hooks/Me.hook';
import { Role } from '../../lib/graphql.generated';
import MobileMenuPageLink from './MobileMenuPageLink.component';
import MobileMenuProfile from './MobileMenuProfile.component';


function MobileMenu(props: MenuStateReturn) {
  const me = useMe();
  const { t, i18n } = useTranslation();
  const onClick = () => props.setVisible(false);
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  return (
    <div className={`${props.visible ? 'block' : 'hidden'} lg:hidden`} role="menu">
      <>{isAuthenticated ?
        <>
          <div className="pt-2 pb-3 space-y-1">
            <MobileMenuPageLink to="/" onClick={onClick}>
              {t('navbar.link.home')}
            </MobileMenuPageLink>
            <MobileMenuPageLink to="/panels" onClick={onClick}>
              {t('navbar.link.panel')}
            </MobileMenuPageLink>
            { me?.role == Role.Admin &&
              <>
                <MobileMenuPageLink to="/components" onClick={onClick}>
                  {t('navbar.link.component')}
                </MobileMenuPageLink>
                <MobileMenuPageLink to="/member" onClick={onClick}>
                  {t('navbar.link.member')}
                </MobileMenuPageLink>
              </>
            }
          </div>
          <div className="pt-4 pb-3 border-t border-gray-200">
            <MobileMenuProfile />
            <div className="mt-3 space-y-1">
              <button
                onClick={() => logout({ returnTo: window.location.origin })}
                className="block w-full px-5 py-3 text-center font-medium text-primary-red-default bg-gray-50 hover:bg-gray-100">
                {t('navbar.logout')}
              </button>
            </div>
          </div>
        </>
        : <div className="px-2 pt-2 pb-3 space-y-1">
          <div className="rounded-lg lg:shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden ">
            <MobileMenuPageLink to="/" onClick={onClick}>
              {t('navbar.link.home')}
            </MobileMenuPageLink>
            <MobileMenuPageLink to="/panel" onClick={onClick}>
              {t('navbar.link.panel')}
            </MobileMenuPageLink>
            <button
              onClick={() => loginWithRedirect({ ui_locales: i18n.language })}
              className="block w-full px-5 py-3 text-center font-medium text-primary-red-default bg-gray-50 hover:bg-gray-100">
              {t('navbar.login')}
            </button>
          </div>
        </div>
      }
      </>

    </div>
  );
}
export default MobileMenu;