import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { Fragment } from 'react';
import { RowProps } from './SelectEditRole.component';

function OptionCreateUser<TOption extends string>({
  value,
  onChange,
  optionCreate,
  defaultValue,
}: RowProps<TOption>) {
  const buttonText = optionCreate?.find(o => o.value == value)?.label ?? defaultValue;
  return (
    <>
      <Listbox value={value} onChange={onChange}>
        {({ open }) => (
          <>
            <div className="mt-1 relative">
              <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm">
                <span className="block truncate capitalize">
                  {buttonText}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronDownIcon
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className="absolute w-full py-1 mt-1 overflow-auto text-base z-10 bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" tabIndex={-1} role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3"
                >
                  {optionCreate?.map((i, index) => (
                    <Listbox.Option
                      key={index}
                      className={({ active }) => {
                        return (`${active ? 'text-gray-900 bg-gray-100' : 'text-gray-900'} cursor-default select-none relative py-2 pl-10 pr-4`);
                      }}
                      value={i.value}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`${selected ? 'font-medium' : 'font-normal'} block truncate`}
                          >
                            {i.label}
                          </span>
                          {selected ? (
                            <span
                              className="text-gray-900 absolute inset-y-0 left-0 flex items-center pl-3"
                            >
                              <CheckIcon className="w-5 h-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </>
  );
}

export default OptionCreateUser;


