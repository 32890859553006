import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid';
import { Dispatch, Fragment, SetStateAction, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { formatCurrency, getNumberFormatting } from '../../lib/format';
import {
  SetCustomPriceMutationVariables,
  useSetCustomPriceMutation,
} from '../../lib/graphql.generated';

interface SetCustomPricesModalProps {
  id: string;
  show: boolean;
  exportPrice: number;
  factoryPrice: number;
  setClose: Dispatch<SetStateAction<boolean>>;
  next: () => void;
}
type FormValues = {
  exportPrice: string;
  factoryPrice: string;
};

export default function SetCustomPricesModal({
  show,
  setClose,
  id,
  exportPrice,
  factoryPrice,
  next,
}: SetCustomPricesModalProps) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid, errors: formErrors },
  } = useForm<FormValues>({
    mode: 'all',
  });
  const close = () => {
    setClose(false);
    reset();
  };

  const [setCustomPrice, { data: priceData, error: priceError }] = useSetCustomPriceMutation();
  const onSubmit = (data: FormValues) => {
    const newPrice: SetCustomPriceMutationVariables = {
      id: id,
      exportPrice: parseFloat(data.exportPrice),
      factoryPrice: parseFloat(data.factoryPrice),
    };
    void setCustomPrice({ variables: newPrice });
  };

  useEffect(() => {
    if (priceError) {
      console.error('SET PRICE ERROR: ', priceError);
    } else if (priceData) {
      reset();
      close();
      if (priceData.setConfigurationCustomPrices.id == id) {
        void next();
      }
    }
  });

  return (
    <Transition show={show} as={Fragment}>
      <Dialog
        open={show}
        onClose={close}
        className="fixed z-10 inset-0 overflow-y-auto"
        id="set-price-modal"
      >
        <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <Dialog.Title className="flex flex-row justify-between pb-2 top-0 right-0 border-b border-gray-200 w-full">
                <span className="text-lg leading-6 font-medium text-gray-900">
                  {t('panel.modal.setPriceTitle')}
                </span>
                <button
                  type="button"
                  onClick={close}
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                >
                  <span className="sr-only">{t('modal.closeButton')}</span>
                  <XCircleIcon className="w-6 h-6" />
                </button>
              </Dialog.Title>
              <div className="px-4 pt-5 sm:p-6">
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="exportPrice"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t('label.exportPrice')}
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <Controller
                          defaultValue={(exportPrice!).toString()}
                          render={({ field: { onChange } }) => {
                            return (
                              <NumberFormat
                                id="exportPrice"
                                placeholder={formatCurrency(exportPrice)!}
                                decimalScale={2}
                                allowNegative={false}
                                onValueChange={(values) => {
                                  onChange(values.value);
                                }}
                                suffix={'€'}
                                thousandSeparator={getNumberFormatting().thousandsSeparator}
                                decimalSeparator={getNumberFormatting().decimalSeparator}
                                fixedDecimalScale={true}
                                className={`${formErrors.exportPrice ? 'border-red-300 focus:border-red-400 focus:ring-red-400' : 'border-gray-300 focus:border-gray-400 focus:ring-gray-400'} mt-1 block w-full shadow-sm sm:text-sm rounded-md`}
                              />);
                          }}
                          name={'exportPrice'}
                          control={control}
                          rules={{ required: `${t('panel.modal.inputErrorexportPrice')}` }}
                        />
                        {formErrors.exportPrice ? (
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                          </div>
                        ) : null}
                      </div>
                      {formErrors.exportPrice && <p className="mt-2 text-sm text-red-600" id="exportPrice-error">{formErrors.exportPrice.message}</p>}
                    </div>
                    <div className="col-span-6 sm:col-span-3 mt-4">
                      <label
                        htmlFor="factoryPrice"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t('label.factPrice')}
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <Controller
                          defaultValue={(factoryPrice!).toString()}
                          render={({ field: { onChange } }) => {
                            return (
                              <NumberFormat
                                id="factoryPrice"
                                placeholder={formatCurrency(factoryPrice)!}
                                decimalScale={2}
                                allowNegative={false}
                                onValueChange={(values) => {
                                  onChange(values.value);
                                }}
                                suffix={'€'}
                                thousandSeparator={getNumberFormatting().thousandsSeparator}
                                decimalSeparator={getNumberFormatting().decimalSeparator}
                                fixedDecimalScale={true}
                                className={`${formErrors.factoryPrice ? 'border-red-300 focus:border-red-400 focus:ring-red-400' : 'border-gray-300 focus:border-gray-400 focus:ring-gray-400'} mt-1 block w-full shadow-sm sm:text-sm rounded-md`}
                              />);
                          }}
                          name={'factoryPrice'}
                          control={control}
                          rules={{ required: `${t('panel.modal.inputErrorFactPrice')}` }}
                        />
                        {formErrors.factoryPrice ? (
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                          </div>
                        ) : null}
                      </div>
                      {formErrors.factoryPrice && <p className="mt-2 text-sm text-red-600" id="factPrice-error">{formErrors.factoryPrice.message}</p>}
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="submit"
                        disabled={!isValid}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-secondary-orange-default hover:bg-secondary-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 disabled:cursor-not-allowed disabled:bg-gray-300 sm:ml-3 sm:w-auto"
                      >
                        {t('panel.button.set')}
                      </button>
                      <button
                        onClick={close}
                        type="button"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-primary-gray-default bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 "
                      >
                        {t('button.cancel')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
