import { NavLink } from 'react-router-dom';
import { FullNavLinkProps } from '../../lib/types';
function NavbarLink<TState>(props: FullNavLinkProps<TState>) {
  return (
    <NavLink exact {...props}
      activeClassName="border-primary-red-default text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-base font-semibold"
      className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-base font-medium"
      role="menuitem"
    >
      {props.children}
    </NavLink>
  );
}

export default NavbarLink;