import { useActionModal } from '../../hooks/ActionModal.hook';
import { Configuration, ListConfigurationsQuery } from '../../lib/graphql.generated';
import PreviewPanelModal from './PreviewPanelModal.component';

interface PreviewPanelModalProps {
  data?: ListConfigurationsQuery;
  loading: boolean;
  selectedPanelIndex: number;
}

export default function PreviewPanel({ data, loading, selectedPanelIndex }: PreviewPanelModalProps) {
  const { previewPanel } = useActionModal();

  if (loading) {
    return null;
  } else if (previewPanel.isOpen && data) {
    return <PreviewPanelModal configurations={data.configurations as Configuration[]} selectedPanelIndex={selectedPanelIndex} />;
  } else {
    return null;
  }
}