/* eslint-disable react-hooks/exhaustive-deps */
import { ApolloQueryResult } from '@apollo/client';
import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/solid';
import { useEffect, useState, Fragment } from 'react';
import { Controller, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useActionModal } from '../../hooks/ActionModal.hook';
import { CreateUserMutationVariables, Role, useCreateUserMutation, ListUsersQueryVariables, ListUsersQuery, useListUsersQuery } from '../../lib/graphql.generated';
import { TypeListOption } from '../ListBox/TypeListBox.component';
import SelectCreateRole from './SelectCreateRole.component';


export interface RoleState {
  value: Role | boolean | string;
  label: String;
  isAdmin?: boolean;
}

export interface CreateUserProps {
  refetch: (variables?: Partial<ListUsersQueryVariables>) => Promise<ApolloQueryResult<ListUsersQuery>>;
  roleSelected: TypeListOption<Role>[];
}

function CreateUser({ refetch, roleSelected }: CreateUserProps) {
  const { createUser } = useActionModal();
  const { t } = useTranslation();
  const {
    register,
    control,
    handleSubmit,
    formState: {
      errors: formErrors,
      isValid,
      isSubmitting,
      isDirty,
    },
    reset,
    setValue,
  } = useForm<CreateUserMutationVariables>({
    mode: 'all',
  });
  const [
    createUserMutation,
    { data, loading, error: saveError },
  ] = useCreateUserMutation();
  const { data: listData } = useListUsersQuery({
    variables: {
      isActive: null,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  const onSubmit = (vars: CreateUserMutationVariables) => {
    if (listData?.users.map(element => element.email).find(x => x == vars.email) == undefined) {
      setRoleError(null);
      void createUserMutation({ variables: vars }).then(createUser.close);
      reset();
    } else {
      setRoleError(t('user.modal.error.alreadyExisted'));
    }
  };
  const [roleError, setRoleError] = useState(null);
  const { field: typeRole } = useController({
    name: 'role',
    control,
  });
  let message: string | undefined;
  let error: string | undefined;

  if (formErrors.email
    || formErrors.name
  ) {
    error = t('user.modal.error.fromError');
  }
  if (formErrors.role) {
    error = t('user.modal.error.roleError');
  }
  if (saveError) {
    error = t('user.modal.error.cannotSaver');
  }
  if (loading) {
    message = t('user.awaitMsg.save');
  }
  if (data && !data?.createUser?.email) {
    error = t('user.modal.error.alreadyExisted');
  } else if (data) {
    error = undefined;
    message = undefined;
  }
  useEffect(() => {
    if (data && data?.createUser?.email) {
      void refetch();
    }
  }, [data, refetch]);
  useEffect(() => {
    if (!createUser.isOpen) {
      reset();
    }
  }, [createUser.isOpen, reset, message, error]);


  return (
    <Transition show={createUser.isOpen}>
      <Dialog
        open={createUser.isOpen}
        onClose={createUser.close}
        className="fixed z-10 inset-0 overflow-y-auto"
      >
        <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block align-bottom bg-white mt-auto mb-auto rounded-lg text-left sm:rounded-md overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full">
              <Dialog.Title className="flex flex-row justify-between top-0 right-0 py-4 px-4 border-b border-gray-200 w-full">
                <span className="text-lg leading-6 font-medium text-gray-900">{t('user.modal.createMemberHeader')}</span>
                <button onClick={createUser.close} type="button" className="bg-white rounded-md text-gray-400 hover:text-red-500 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-gray-400">
                  <span className="sr-only">{t('modal.closeButton')}</span>
                  <XCircleIcon className="h-6 w-6" />
                </button>
              </Dialog.Title>
              <div className="px-4 pt-5 pb-4 sm:p-6">
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">{t('label.name')}</label>
                        <input
                          type="text"
                          placeholder={t('label.name')}
                          {...register('name', { required: true })}
                          className="mt-1 focus:ring-gray-400 focus:border-gray-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="role" className="block text-sm font-medium text-gray-700">{t('user.modal.roleLabel')}</label>
                        <Controller
                          control={(control)}
                          name="role"
                          rules={{
                            required: true,
                          }}
                          render={({ field: { onChange } }) => (
                            <SelectCreateRole
                              value={typeRole.value}
                              onChange={((newValue: Role) => {
                                setValue(typeRole.name, newValue);
                                onChange(newValue);
                              })}
                              optionCreate={roleSelected}
                              defaultValue={t('user.modal.ChooseRole')}
                            />
                          )}
                        />
                      </div>
                      <div className="col-span-3 sm:col-span-3">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">{t('user.modal.emailLabel')}</label>
                        <input
                          type="email"
                          placeholder={t('user.modal.emailLabel')}
                          className="mt-1 focus:ring-gray-400 focus:border-gray-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          {...register('email', { required: true })}
                        />
                      </div>
                    </div>
                    <div className="py-3 text-right space-x-1">
                      <button onClick={createUser.close} type="button" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-primary-gray-default bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400">
                        {t('button.cancel')}
                      </button>
                      <button
                        type="submit"
                        disabled={isSubmitting || !isValid || !isDirty}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-secondary-orange-default hover:bg-secondary-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 disabled:cursor-not-allowed disabled:bg-gray-300"
                      >
                        {t('button.create')}
                      </button>
                    </div>
                  </form>
                  {message && <p className="text-sm">{message}</p>}
                  {error && <p className="text-red-600 text-sm">{error}</p>}
                  <p className="text-red-600 text-sm">{roleError}</p>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default CreateUser;