import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon, ArrowCircleLeftIcon, ArrowCircleRightIcon } from '@heroicons/react/solid';
import { Fragment, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';
import { useDrag } from 'react-use-gesture';
import { useActionModal } from '../../hooks/ActionModal.hook';
import { panelWidth } from '../../hooks/CurrentConfiguration.hook';
import { Configuration, PanelWidth } from '../../lib/graphql.generated';
import { mm2px } from '../../lib/measurement';
import SvgPreview from './SvgPreview.component';

interface PreviewPanelModalProps {
  configurations: Configuration[];
  selectedPanelIndex: number;
}

function PreviewPanelModal({ configurations, selectedPanelIndex }: PreviewPanelModalProps) {
  const { t } = useTranslation();
  const panel = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { previewPanel } = useActionModal();
  const [selectedIndex, setSelectedIndex] = useState(selectedPanelIndex);
  const appropriateScale = 28.125;

  function isNotOver799mm() {
    const width = configurations[selectedIndex].width;
    const customWidth = configurations[selectedIndex].customWidth;
    if (
      width == PanelWidth.Large ||
      width == PanelWidth.Xlarge ||
      width == PanelWidth.Xxlarge ||
      width == PanelWidth.Custom && customWidth && (customWidth > 799)
    ) {
      return false;
    }
    return true;
  }

  function handleOnClick(id: string) {
    history.push(generatePath('/panels/:id', { id }));
  }

  const bind = useDrag(({ dragging, delta, tap }) => {
    if (!tap && dragging && panel.current) {
      panel.current.scrollLeft += delta[0];
    }
  }, { delay: 400, filterTaps: true });

  return (
    <Transition show={previewPanel.isOpen} as={Fragment}>
      <Dialog
        id="save panel"
        open={previewPanel.isOpen}
        onClose={previewPanel.close}
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block align-bottom bg-white mt-auto mb-auto rounded-lg text-left sm:rounded-md overflow-hidden shadow-xl transform transition-all sm:align-middle w-full lg:max-w-screen-lg">
              <Dialog.Title className="top-0 right-0 py-4 px-4 border-b border-gray-200 w-full">
                <div className='flex flex-row justify-between'>
                  <span className="text-primary-grey-default text-xl font-bold leading-6">{configurations[selectedIndex].id}</span>
                  <button onClick={previewPanel.close} type="button" className="bg-white rounded-md text-gray-400 hover:text-red-500 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-gray-400">
                    <span className="sr-only">{t('modal.closeButton')}</span>
                    <XCircleIcon className="h-6 w-6" />
                  </button>
                </div>
                <div className="flex flex-row space-x-2 ">
                  <p className="text-xs xl:text-sm lg:text-sm 2xl:text-sm text-primary-grey-default">
                    {t('label.name')}: {configurations[selectedIndex].name}
                  </p>
                  <p className="text-xs xl:text-sm lg:text-sm 2xl:text-sm text-primary-grey-default">
                    {t('label.width')}: {configurations[selectedIndex].width}
                  </p>
                </div>
              </Dialog.Title>
              <div className="px-4 pt-5 pb-4 sm:p-6">
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div className="grid grid-flow-col gap-4 justify-between items-center">
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedIndex(selectedIndex > 0 ? selectedIndex-1 : 0 );
                      }}
                      disabled={selectedIndex==0}
                      className="col-start-1 col-span-1 h-10 w-10 text-gray-400 hover:text-secondary-orange-600 disabled:text-gray-300 disabled:cursor-not-allowed">
                      <ArrowCircleLeftIcon />
                    </button>
                    <div ref={panel} {...bind()} className={`overflow-y-auto h-full pb-8 col-start-2 col-span-8 cursor-grab active:cursor-grabbing lg:${isNotOver799mm() && 'justify-self-center'}`}>
                      <div className=''>
                        <button
                          className="flex flex-row border border-black border-r-0 border-l-0"
                          style={{
                            width: `${mm2px(panelWidth.get(configurations[selectedIndex].width!) ?? configurations[selectedIndex].customWidth!, appropriateScale)}px`,
                          }}
                          onClick={() => {
                            previewPanel.close();
                            handleOnClick(configurations[selectedIndex].id);
                          }}>
                          <SvgPreview configuration={configurations[selectedIndex]} appropriateScale={appropriateScale} />
                        </button>
                      </div>
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedIndex(selectedIndex < configurations.length-1 ? selectedIndex+1 : selectedIndex );
                      }}
                      disabled={selectedIndex==configurations.length-1}
                      className="col-start-10 col-span-1 h-10 w-10 text-gray-400 hover:text-secondary-orange-600 disabled:text-gray-300 disabled:cursor-not-allowed"
                    >
                      <ArrowCircleRightIcon />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default PreviewPanelModal;