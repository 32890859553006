import { Listbox, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { TypeListOption } from '../ListBox/TypeListBox.component';

const options: TypeListOption<string>[] = [
  { value: 'en', label: 'EN' },
  { value: 'fi', label: 'FI' },
  { value: 'de', label: 'DE' },
  { value: 'fr', label: 'FR' },
  { value: 'se', label: 'SE' },
  { value: 'ru', label: 'RU' },
];

function Translate() {
  const { i18n } = useTranslation();
  const current = options.find((i) => i.value == i18n.language);
  return (
    <div >
      <div className="flex justify-center space-x-6 md:order-2 m-auto">
        <Listbox value={current} onChange={(lang) => i18n.changeLanguage(lang?.value)}>
          {({ open }) => (
            <>
              <div className="mt-1 relative">
                <Listbox.Button className="relative w-10 bg-white shadow-sm py-2 text-center cursor-default focus:outline-none sm:text-xs">
                  <span className="block truncate capitalize text-sm text-primary-grey-default">
                    {current?.label}
                  </span>
                </Listbox.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    static
                    className="absolute w-10 py-1 mt-1 overflow-auto text-base z-10 bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" tabIndex={-1} role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3"
                  >
                    {options?.map((i, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) => {
                          return (`${active ? 'text-gray-900 bg-gray-100' : 'text-gray-900'} cursor-default select-none relative py-2 text-center`);
                        }}
                        value={i}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`${selected ? 'font-semibold' : 'font-normal'} block truncate`}
                            >
                              {i.label}
                            </span>
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
    </div>
  );
}
export default Translate;

