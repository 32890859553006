import { Popover } from '@headlessui/react';
import { DocumentDownloadIcon, DownloadIcon } from '@heroicons/react/solid';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { useMe } from '../../hooks/Me.hook';
import {
  PdfPricing,
  Role,
  useGeneratePdfMutation,
} from '../../lib/graphql.generated';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

interface IncludePrice {
  includePrice: boolean;
}

export interface DownloadPdfProps {
  id: string;
}

function DownloadPdf({ id }: DownloadPdfProps) {
  const { t, i18n } = useTranslation();
  const me = useMe();
  const { register, handleSubmit, reset } = useForm<IncludePrice>();

  const [generatePdfMutation, { data, loading }] = useGeneratePdfMutation();

  const doGenerate: SubmitHandler<IncludePrice> = async (formData) => {
    let pricing = PdfPricing.None;
    if (formData.includePrice) {
      switch (me?.role) {
        case Role.DistributorWithoutPrice:
          pricing = PdfPricing.None;
          break;
        case Role.Distributor:
          pricing = PdfPricing.ExportPrice;
          break;
        default:
          pricing = PdfPricing.FactoryPrice;
          break;
      }
    }

    void generatePdfMutation({
      variables: { id, pricing, language: i18n.language },
    });
    reset();
  };

  return (
    <Popover className="relative inline-flex">
      <Popover.Button className="group inline-flex flex-1 items-center px-4 py-2 border border-gray-200 shadow-sm text-sm font-medium rounded-md text-primary-gray-default bg-gray-100 hover:bg-gray-300 focus:outline-none focus:ring-0 focus:border-primary-grey-default">
        <DocumentDownloadIcon className="-ml-1 mr-2 h-5 w-5 group-hover:text-primary-red-default" />
        <span className="text-base xl:text-md lg:text-md 2xl:text-md">
          {t('button.downloadPDF')}
        </span>
      </Popover.Button>
      <Popover.Panel className="absolute z-20 mt-10 left-0 origin-right p-2 shadow-md rounded-lg overflow-y-auto text-base sm:text-sm space-y-0 bg-white">
        {(() => {
          if (loading) {
            return (
              <div className="h-full w-full flex items-center justify-center p-10">
                <Loader
                  type="Oval"
                  color="#e2001a"
                  height={20}
                  width={20}
                  timeout={5000} //5 secs
                />
              </div>
            );
          } else if (data) {
            const url = `data:application/pdf;base64,${data.configurationPdf}`;
            return (
              <a
                href={url}
                download={`${id}.pdf`}
                className="text-gray-700 hover:text-red-500 text-sm font-medium"
              >
                <span className="flex flex-row">
                  <DownloadIcon className="inline-flex h-4 w-4" />
                  <span className="inline-flex font-sm font-semibold">
                    {t('panel.button.download')}
                  </span>
                </span>
              </a>
            );
          } else {
            return (
              <form onSubmit={handleSubmit(doGenerate)}>
                <div className="flex flex-col w-max space-y-2">
                  {me?.role != Role.DistributorWithoutPrice ?
                    <div className="items-center inline-flex ">
                      <input
                        id="include_price"
                        {...register('includePrice')}
                        type="checkbox"
                        className="h-3 w-3 text-gray-600 focus:ring-0 border-gray-300 rounded"
                      />
                      <label htmlFor="include_price" className="ml-2 block text-base text-gray-600">
                        {t('panel.checkbox.includePrice')}
                      </label>
                    </div>
                    : null
                  }
                  <button
                    type="submit"
                    className="inline-flex bg-red-400 px-2 py-1 hover:bg-red-500 rounded-md"
                  >
                    <span className="text-sm text-white">
                      {t('panel.button.generate')}
                    </span>
                  </button>
                </div>
              </form>
            );
          }
        })()}
      </Popover.Panel>
    </Popover>
  );
}

export default DownloadPdf;
