import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SetBasePriceButton from '../components/BasePrice/SetBasePriceButton.component';
import SetBasePricesModal from '../components/BasePrice/SetBasePricesModal.component';
import Spinner from '../components/Spinner.component';
import { panelWidth } from '../hooks/CurrentConfiguration.hook';
import { useMe } from '../hooks/Me.hook';
import {
  PanelWidth,
  Role,
  useGetBasePriceListQuery,
} from '../lib/graphql.generated';
import NotFound from './NotFound.component';
export const widthName = new Map<PanelWidth, string>([
  [PanelWidth.Xsmall, 'TPR3'],
  [PanelWidth.Small, 'TPR4'],
  [PanelWidth.Medium, 'TPR7'],
  [PanelWidth.Large, 'TPR9'],
  [PanelWidth.Xlarge, 'TPR14'],
  [PanelWidth.Xxlarge, 'TPR17'],
  [PanelWidth.Custom, 'TPR99'],
]);

export default function BasePricePage() {
  const { t } = useTranslation();
  const me = useMe();
  const [showCustomPriceModal, setShowCustomPriceModal] =
    useState<boolean>(false);
  const [id, setId] = useState<PanelWidth>();
  const { data, loading, error, refetch } = useGetBasePriceListQuery({
    fetchPolicy: 'cache-and-network',
  });
  const exportPrice: number = data?.basePrice.find((i) => i.width == id)?.exportPrice!;
  const factoryPrice: number = data?.basePrice.find((i) => i.width == id)?.factoryPrice!;
  if (me?.role !== Role.Admin) {
    return <NotFound />;
  }
  if (loading) {
    return <Spinner />;
  } else if (error) {
    console.error('ERROR LOADING CONFIG', error);
  } else if (data && data.basePrice == null) {
    return (
      <h3 className="text-primary-grey-default text-md text-center">
        {t('table.NoData')}
      </h3>
    );
  }

  return (
    <div className="pb-5 flex w-full flex-col items-center">
      <div className="flex flex-col flex-1 w-full xl:max-w-screen-lg justify-center">
        <div className="py-2 align-middle inline-block min-w-full items-center sm:px-6 lg:px-8">
          <div className="py-2 align-middle inline-block min-w-full items-center sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg ">
              <table className="min-w-full divide-y divide-gray-200 stripped table-fixed">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider w-1/5"
                    >
                      {t('label.name')}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider w-1/5"
                    >
                      {t('label.width')}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider w-1/5"
                    >
                      {t('label.exportPrice')}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider w-1/5"
                    >
                      {t('label.factPrice')}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider w-1/5"
                    ></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data?.basePrice.map((item, idx) => {
                    return (
                      <tr key={idx} className="hover:bg-gray-200" tabIndex={idx + 1}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                          {widthName.get(item.width)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                          {item.width == PanelWidth.Custom
                            ? t('width.custom')
                            : panelWidth.get(item.width)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                          {item.exportPrice}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                          {item.factoryPrice}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                          <SetBasePriceButton
                            action={setShowCustomPriceModal}
                            id={item.width}
                            set={setId}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <SetBasePricesModal
        id={id!}
        show={showCustomPriceModal}
        exportPrice={exportPrice}
        factoryPrice={factoryPrice}
        setClose={setShowCustomPriceModal}
        next={refetch}
      />
    </div>
  );
}
