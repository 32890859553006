import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import DeleteAlertModal from '../components/Common/DeleteAlertModal.component';
import DeleteButton from '../components/Common/DeleteButton.component';
import DownloadPdf from '../components/Panel/DownloadPdf.component';
import EditPanelInfoButton from '../components/Panel/EditPanelInfoButton.component';
import EditPanelInfoModal from '../components/Panel/EditPanelInfoModal.component';
import PanelDetails from '../components/Panel/PanelDetails.component';
import ResetPricesAlert from '../components/Panel/ResetPricesAlert.component';
import SetCustomPricesButton from '../components/Panel/SetCustomPricesButton.component';
import SetCustomPricesModal from '../components/Panel/SetCustomPricesModal.component';
import SvgPanelView from '../components/Panel/SvgPanelView.component';
import Spinner from '../components/Spinner.component';
import { panelWidth } from '../hooks/CurrentConfiguration.hook';
import { useMe } from '../hooks/Me.hook';
import { formatWidth, translatePlugRegion } from '../lib/format';
import {
  Component,
  Configuration,
  PanelWidth,
  Role,
  useDeleteConfigurationMutation,
  useGetConfigurationQuery,
  useUndeleteConfigurationMutation,
} from '../lib/graphql.generated';
import NotFound from './NotFound.component';

type PageParams = { id: string };

export interface ComponentDetail {
  name: string;
  quantity: number;
  exportPrice: number;
  factoryPrice: number;
}

const componentDetail = (
  configuration: Configuration,
  component: Component,
): ComponentDetail => {
  const quantity = configuration.components.filter(
    (c) => c.id == component.id,
  ).length;
  return {
    name: component.name,
    quantity: quantity,
    exportPrice: quantity * component.exportPrice,
    factoryPrice: quantity * component.factoryPrice,
  };
};

const allComponentDetails = (configuration: Configuration) => {
  const map = configuration.components.reduce((acc, c, index) => {
    // Note: Exclude default components (2 at front and 1 at end)
    if (index <= 1 || index == configuration.components.length - 1) {
      return acc;
    }

    if (!acc.has(c.id)) {
      acc.set(c.id, componentDetail(configuration, c));
    }
    return acc;
  }, new Map<string, ComponentDetail>());
  return Array.from(map.values());
};

function PanelViewPage() {
  const { id } = useParams<PageParams>();
  const { data, loading, error, refetch } = useGetConfigurationQuery({
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });
  const { t } = useTranslation();
  const me = useMe();
  const history = useHistory();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showSetCustomPrices, setShowSetCustomPrices] = useState(false);
  const [showResetPricesConfirm, setShowResetPricesConfirm] = useState(false);
  const [showEditInfo, setShowEditInfo] = useState(false);
  const isOverridden: boolean = data?.configuration?.arePricesOverridden!;
  const isAdmin = me?.role == Role.Admin ?? false;

  const [deleteConfigurationMutation, { data: deleteResult }] =
    useDeleteConfigurationMutation();

  const [undeleteConfigurationMutation, { data: undeleteResult }] =
    useUndeleteConfigurationMutation();

  let width: string = '';
  let componentDetails: ComponentDetail[] = [];
  if (loading) {
    return <Spinner />;
  } else if (error) {
    console.error('ERROR LOADING CONFIG', error);
  } else if (data && data.configuration == null) {
    // Not Fount
    return <NotFound />;
  } else if (data && me?.role !== Role.Admin && !data.configuration?.isActive) {
    // Not admin trying to access a deleted item
    return <NotFound />;
  } else if (data) {
    componentDetails = allComponentDetails(data.configuration as Configuration);
    let width_raw: number = panelWidth.get(data.configuration?.width!)!;
    if (data.configuration?.width == PanelWidth.Custom) {
      width_raw = data.configuration.customWidth!;
    }
    width = formatWidth(width_raw);
  }


  return (
    <div className="min-h-content px-20 md:px-14 sm:px-8">
      <div className="w-full pt-10 flex xl:flex-row lg:flex-row justify-between flex-col">
        <div className="w-auto">
          <h1 className="text-primary-grey-default text-xl font-bold">
            {data?.configuration?.id}
          </h1>
          <div className="flex flex-row space-x-2 ">
            <p className="text-xs xl:text-sm lg:text-sm 2xl:text-sm text-primary-grey-default">
              {t('label.region')}:{translatePlugRegion(data?.configuration?.plugRegion!)}
            </p>
            <p className="text-xs xl:text-sm lg:text-sm 2xl:text-sm text-primary-grey-default">
              {t('label.width')}: {width}
            </p>
          </div>
        </div>
        <div className="pt-4 space-x-2 xl:flex-row lg:flex-row 2xl:flex-row xl:pt-0 lg:pt-0 2xl:pt-0 xl:flex-shrink-0 lg:flex-shrink-0 2xl:flex-shrink-0 xl:pr-20 lg:pr-20 2xl:pr20 md:pr-12  flex-row">
          {isAdmin && data?.configuration && (
            <>
              <EditPanelInfoButton action={setShowEditInfo} />
              {isOverridden ? (
                <SetCustomPricesButton
                  action={setShowResetPricesConfirm}
                  isOverride={isOverridden}
                />
              ) : (
                <SetCustomPricesButton
                  action={setShowSetCustomPrices}
                  isOverride={isOverridden}
                />
              )}
              <DeleteButton
                action={setShowDeleteConfirm}
                isActive={data?.configuration?.isActive}
              />
            </>
          )}
          {/* <button type="button" className="inline-flex flex-1 items-center px-4 py-2 border border-gray-200 shadow-sm text-sm font-medium rounded-md text-primary-gray-default bg-gray-100 hover:bg-primary-grey-300 focus:outline-none focus:ring-0 focus:border-primary-grey-default">
              <DuplicateIcon className="-ml-1 mr-2 h-5 w-5" />
              {t('button.duplicate')}
            </button> */}
          <DownloadPdf id={id} />
        </div>
      </div>
      <div className="mx-20 h-full flex flex-col mt-10 space-y-2">
        <PanelDetails data={data} width={width} details={componentDetails} />
        <SvgPanelView data={data} />
      </div>
      {data?.configuration && data.configuration.isActive && isAdmin && (
        <DeleteAlertModal
          id={id}
          show={showDeleteConfirm}
          setClose={setShowDeleteConfirm}
          action={deleteConfigurationMutation}
          result={deleteResult}
          status={true}
          next={() => history.push('/panels')}
        />
      )}
      {data?.configuration && !data.configuration.isActive && isAdmin && (
        <DeleteAlertModal
          id={id}
          show={showDeleteConfirm}
          setClose={setShowDeleteConfirm}
          action={undeleteConfigurationMutation}
          result={undeleteResult}
          status={false}
          next={refetch}
        />
      )}
      {data?.configuration && isAdmin && (
        <>
          <SetCustomPricesModal
            id={id}
            show={showSetCustomPrices}
            exportPrice={data.configuration.exportPrice!}
            factoryPrice={data.configuration.factoryPrice!}
            setClose={setShowSetCustomPrices}
            next={refetch}
          />
          <ResetPricesAlert
            id={id}
            show={showResetPricesConfirm}
            setClose={setShowResetPricesConfirm}
            next={refetch}
          />
          <EditPanelInfoModal
            id={id}
            show={showEditInfo}
            name={data.configuration.name}
            setClose={setShowEditInfo}
            next={refetch}
          />
        </>
      )}
    </div>
  );
}

export default PanelViewPage;
