import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  PositiveFloat: number;
};

export type BasePrice = {
  readonly __typename?: 'BasePrice';
  readonly width: PanelWidth;
  readonly exportPrice: Scalars['Float'];
  readonly factoryPrice: Scalars['Float'];
};

export type CheckDuplicateConfigInput = {
  readonly width: PanelWidth;
  readonly customWidth?: Maybe<Scalars['Float']>;
  readonly plugRegion: PlugRegion;
  readonly components: ReadonlyArray<Scalars['ID']>;
};

export type Component = {
  readonly __typename?: 'Component';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly exportPrice: Scalars['Float'];
  readonly factoryPrice: Scalars['Float'];
  readonly isActive: Scalars['Boolean'];
  readonly width: Scalars['Float'];
  readonly svg: Scalars['String'];
  readonly thickness: Thickness;
  readonly type: ComponentType;
  readonly subtype?: Maybe<ComponentSubtype>;
  readonly plugRegions: ReadonlyArray<PlugRegion>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy?: Maybe<User>;
};

/** Enum ComponentSubtype */
export enum ComponentSubtype {
  EStop = 'E_STOP',
  Horizontal = 'HORIZONTAL',
  Leftend = 'LEFTEND',
  Onoff = 'ONOFF',
  Outlet = 'OUTLET',
  Rcd = 'RCD',
  Rightend = 'RIGHTEND',
  Usb = 'USB',
  Vertical = 'VERTICAL'
}

/** Enum ComponentType */
export enum ComponentType {
  EndPlug = 'END_PLUG',
  Filler = 'FILLER',
  Logo = 'LOGO',
  OtherSocket = 'OTHER_SOCKET',
  PowerSocket = 'POWER_SOCKET',
  Switch = 'SWITCH'
}

export type Configuration = {
  readonly __typename?: 'Configuration';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy?: Maybe<User>;
  readonly createdFrom?: Maybe<Configuration>;
  readonly isActive: Scalars['Boolean'];
  readonly width: PanelWidth;
  readonly customWidth?: Maybe<Scalars['Float']>;
  readonly plugRegion: PlugRegion;
  readonly components: ReadonlyArray<Component>;
  readonly factoryPrice?: Maybe<Scalars['Float']>;
  readonly exportPrice?: Maybe<Scalars['Float']>;
  readonly arePricesOverridden: Scalars['Boolean'];
};

export type CreateComponentInput = {
  readonly name: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly exportPrice: Scalars['Float'];
  readonly factoryPrice: Scalars['Float'];
  readonly width: Scalars['Float'];
  readonly svg: Scalars['String'];
  readonly type: ComponentType;
  readonly subtype?: Maybe<ComponentSubtype>;
  readonly plugRegions: ReadonlyArray<Maybe<PlugRegion>>;
};

export type CreateConfigurationInput = {
  readonly name: Scalars['String'];
  readonly width: PanelWidth;
  readonly customWidth?: Maybe<Scalars['Float']>;
  readonly plugRegion: PlugRegion;
  readonly components: ReadonlyArray<Scalars['ID']>;
};

export type CreateUserInput = {
  readonly name: Scalars['String'];
  readonly email: Scalars['String'];
  readonly role: Role;
  readonly company?: Maybe<Scalars['String']>;
};


export type GetComponentsInput = {
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly plugRegion?: Maybe<PlugRegion>;
  readonly type?: Maybe<ComponentType>;
  readonly subtype?: Maybe<ComponentSubtype>;
  readonly search?: Maybe<Scalars['String']>;
};

export type GetConfigurationsInput = {
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly plugRegion?: Maybe<PlugRegion>;
  readonly createdBySelf?: Maybe<Scalars['Boolean']>;
  readonly search?: Maybe<Scalars['String']>;
};

export type GetPdfInput = {
  readonly id: Scalars['ID'];
  readonly pricing: PdfPricing;
  readonly language: Scalars['String'];
};

export type GetUsersInput = {
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly role?: Maybe<Role>;
  readonly search?: Maybe<Scalars['String']>;
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly createUser: User;
  readonly updateUser: User;
  readonly deleteUser: User;
  readonly undeleteUser: User;
  readonly createComponent: Component;
  readonly deleteComponent: Component;
  readonly undeleteComponent: Component;
  readonly updateComponentSvg: Component;
  readonly updateComponentPrices: Component;
  readonly createConfiguration: Configuration;
  readonly deleteConfiguration: Configuration;
  readonly undeleteConfiguration: Configuration;
  readonly setConfigurationCustomPrices: Configuration;
  readonly resetConfigurationPrices: Configuration;
  readonly updateConfiguration: Configuration;
  readonly configurationPdf: Scalars['String'];
  readonly updateBasePrices: BasePrice;
};


export type MutationCreateUserArgs = {
  user: CreateUserInput;
};


export type MutationUpdateUserArgs = {
  user?: Maybe<UpdateUserInput>;
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationUndeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationCreateComponentArgs = {
  component: CreateComponentInput;
};


export type MutationDeleteComponentArgs = {
  id: Scalars['ID'];
};


export type MutationUndeleteComponentArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateComponentSvgArgs = {
  id: Scalars['ID'];
  thickness: Thickness;
};


export type MutationUpdateComponentPricesArgs = {
  component: UpdateComponentPricesInput;
};


export type MutationCreateConfigurationArgs = {
  configuration: CreateConfigurationInput;
};


export type MutationDeleteConfigurationArgs = {
  id: Scalars['ID'];
};


export type MutationUndeleteConfigurationArgs = {
  id: Scalars['ID'];
};


export type MutationSetConfigurationCustomPricesArgs = {
  configuration: SetConfigurationCustomPricesInput;
};


export type MutationResetConfigurationPricesArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateConfigurationArgs = {
  configuration: UpdateConfigurationInput;
};


export type MutationConfigurationPdfArgs = {
  input?: Maybe<GetPdfInput>;
};


export type MutationUpdateBasePricesArgs = {
  width: UpdateBasePricesInput;
};

/** Panel width */
export enum PanelWidth {
  Xsmall = 'XSMALL',
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'LARGE',
  Xlarge = 'XLARGE',
  Xxlarge = 'XXLARGE',
  Custom = 'CUSTOM'
}

/** Enum Pricing */
export enum PdfPricing {
  FactoryPrice = 'FACTORY_PRICE',
  ExportPrice = 'EXPORT_PRICE',
  None = 'NONE'
}

/** Enum PlugRegion */
export enum PlugRegion {
  British = 'BRITISH',
  Danish = 'DANISH',
  French = 'FRENCH',
  Italian = 'ITALIAN',
  Schuko = 'SCHUKO',
  Swiss = 'SWISS'
}


export type Query = {
  readonly __typename?: 'Query';
  readonly me?: Maybe<User>;
  readonly user?: Maybe<User>;
  readonly users: ReadonlyArray<User>;
  readonly component?: Maybe<Component>;
  readonly components: ReadonlyArray<Component>;
  readonly componentThicknessOptions: ThicknessOptions;
  readonly configuration?: Maybe<Configuration>;
  readonly configurations: ReadonlyArray<Configuration>;
  readonly duplicateConfiguration?: Maybe<Configuration>;
  readonly basePrice: ReadonlyArray<BasePrice>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  input?: Maybe<GetUsersInput>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryComponentArgs = {
  id: Scalars['ID'];
};


export type QueryComponentsArgs = {
  input?: Maybe<GetComponentsInput>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryComponentThicknessOptionsArgs = {
  id: Scalars['ID'];
};


export type QueryConfigurationArgs = {
  id: Scalars['ID'];
};


export type QueryConfigurationsArgs = {
  input?: Maybe<GetConfigurationsInput>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryDuplicateConfigurationArgs = {
  input: CheckDuplicateConfigInput;
};

/** Enum Role */
export enum Role {
  Admin = 'ADMIN',
  Salesperson = 'SALESPERSON',
  Distributor = 'DISTRIBUTOR',
  DistributorWithoutPrice = 'DISTRIBUTOR_WITHOUT_PRICE'
}

export type SetConfigurationCustomPricesInput = {
  readonly id: Scalars['ID'];
  readonly exportPrice: Scalars['Float'];
  readonly factoryPrice: Scalars['Float'];
};

/** Enum Thickness */
export enum Thickness {
  None = 'NONE',
  Thick = 'THICK',
  Thicker = 'THICKER',
  Thickest = 'THICKEST'
}

export type ThicknessOptions = {
  readonly __typename?: 'ThicknessOptions';
  readonly id: Scalars['ID'];
  readonly none: Scalars['String'];
  readonly thick: Scalars['String'];
  readonly thicker: Scalars['String'];
  readonly thickest: Scalars['String'];
};

export type UpdateBasePricesInput = {
  readonly width: PanelWidth;
  readonly exportPrice: Scalars['Float'];
  readonly factoryPrice: Scalars['Float'];
};

export type UpdateComponentPricesInput = {
  readonly id: Scalars['ID'];
  readonly exportPrice: Scalars['Float'];
  readonly factoryPrice: Scalars['Float'];
};

export type UpdateConfigurationInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type UpdateUserInput = {
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly role?: Maybe<Role>;
};

export type User = {
  readonly __typename?: 'User';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly email: Scalars['String'];
  readonly role: Role;
  readonly company?: Maybe<Scalars['String']>;
  readonly picture?: Maybe<Scalars['String']>;
  readonly isAdminDeprecated: Scalars['Boolean'];
  readonly isActive: Scalars['Boolean'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly createdBy?: Maybe<User>;
  readonly configurations?: Maybe<ReadonlyArray<Configuration>>;
};

export type GetBasePriceListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBasePriceListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly basePrice: ReadonlyArray<(
    { readonly __typename?: 'BasePrice' }
    & Pick<BasePrice, 'width' | 'exportPrice' | 'factoryPrice'>
  )> }
);

export type UpdateBasePriceMutationVariables = Exact<{
  width: PanelWidth;
  exportPrice: Scalars['Float'];
  factoryPrice: Scalars['Float'];
}>;


export type UpdateBasePriceMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateBasePrices: (
    { readonly __typename?: 'BasePrice' }
    & Pick<BasePrice, 'width'>
  ) }
);

export type ComponentDetailsFragment = (
  { readonly __typename?: 'Component' }
  & Pick<Component, 'id' | 'name' | 'description' | 'exportPrice' | 'factoryPrice' | 'isActive' | 'width' | 'svg' | 'thickness' | 'type' | 'subtype' | 'plugRegions' | 'createdAt'>
);

export type CreateComponentMutationVariables = Exact<{
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  factoryPrice: Scalars['Float'];
  exportPrice: Scalars['Float'];
  width: Scalars['Float'];
  svg: Scalars['String'];
  type: ComponentType;
  subtype?: Maybe<ComponentSubtype>;
  plugRegions: ReadonlyArray<PlugRegion> | PlugRegion;
}>;


export type CreateComponentMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createComponent: (
    { readonly __typename?: 'Component' }
    & ComponentDetailsFragment
  ) }
);

export type DeleteComponentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteComponentMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly result: (
    { readonly __typename?: 'Component' }
    & Pick<Component, 'id'>
  ) }
);

export type GetComponentByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetComponentByIdQuery = (
  { readonly __typename?: 'Query' }
  & { readonly component?: Maybe<(
    { readonly __typename?: 'Component' }
    & ComponentDetailsFragment
  )> }
);

export type GetThicknessQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetThicknessQuery = (
  { readonly __typename?: 'Query' }
  & { readonly componentThicknessOptions: (
    { readonly __typename?: 'ThicknessOptions' }
    & Pick<ThicknessOptions, 'id' | 'none' | 'thick' | 'thicker' | 'thickest'>
  ) }
);

export type ListComponentsQueryVariables = Exact<{
  isActive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ComponentType>;
  subtype?: Maybe<ComponentSubtype>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
}>;


export type ListComponentsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly components: ReadonlyArray<(
    { readonly __typename?: 'Component' }
    & Pick<Component, 'id' | 'name' | 'type' | 'width' | 'svg'>
  )> }
);

export type UndeleteComponentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UndeleteComponentMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly result: (
    { readonly __typename?: 'Component' }
    & Pick<Component, 'id' | 'isActive'>
  ) }
);

export type UpdateComponentPricesMutationVariables = Exact<{
  id: Scalars['ID'];
  exportPrice: Scalars['Float'];
  factoryPrice: Scalars['Float'];
}>;


export type UpdateComponentPricesMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateComponentPrices: (
    { readonly __typename?: 'Component' }
    & Pick<Component, 'id'>
  ) }
);

export type UpdateComponentSvgMutationVariables = Exact<{
  id: Scalars['ID'];
  thickness: Thickness;
}>;


export type UpdateComponentSvgMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateComponentSvg: (
    { readonly __typename?: 'Component' }
    & Pick<Component, 'id'>
  ) }
);

export type CheckDuplicatePanelQueryVariables = Exact<{
  width: PanelWidth;
  customWidth?: Maybe<Scalars['Float']>;
  plugRegion: PlugRegion;
  components: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type CheckDuplicatePanelQuery = (
  { readonly __typename?: 'Query' }
  & { readonly duplicateConfiguration?: Maybe<(
    { readonly __typename?: 'Configuration' }
    & Pick<Configuration, 'id'>
  )> }
);

export type ConfigurationDetailsFragment = (
  { readonly __typename?: 'Configuration' }
  & Pick<Configuration, 'id' | 'name' | 'description' | 'createdAt' | 'isActive' | 'width' | 'plugRegion' | 'exportPrice' | 'factoryPrice'>
  & { readonly components: ReadonlyArray<(
    { readonly __typename?: 'Component' }
    & Pick<Component, 'id' | 'name'>
  )> }
);

export type CreateConfigurationMutationVariables = Exact<{
  name: Scalars['String'];
  width: PanelWidth;
  customWidth?: Maybe<Scalars['Float']>;
  plugRegion: PlugRegion;
  components: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type CreateConfigurationMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createConfiguration: (
    { readonly __typename?: 'Configuration' }
    & Pick<Configuration, 'id'>
  ) }
);

export type CreatePanelComponentFragment = (
  { readonly __typename?: 'Component' }
  & Pick<Component, 'id' | 'name' | 'exportPrice' | 'factoryPrice' | 'width' | 'svg' | 'type' | 'subtype'>
);

export type DeleteConfigurationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteConfigurationMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly result: (
    { readonly __typename?: 'Configuration' }
    & Pick<Configuration, 'id'>
  ) }
);

export type GeneratePdfMutationVariables = Exact<{
  id: Scalars['ID'];
  pricing: PdfPricing;
  language: Scalars['String'];
}>;


export type GeneratePdfMutation = (
  { readonly __typename?: 'Mutation' }
  & Pick<Mutation, 'configurationPdf'>
);

export type GetConfigurationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetConfigurationQuery = (
  { readonly __typename?: 'Query' }
  & { readonly configuration?: Maybe<(
    { readonly __typename?: 'Configuration' }
    & Pick<Configuration, 'id' | 'name' | 'description' | 'createdAt' | 'isActive' | 'width' | 'customWidth' | 'plugRegion' | 'exportPrice' | 'factoryPrice' | 'arePricesOverridden'>
    & { readonly components: ReadonlyArray<(
      { readonly __typename?: 'Component' }
      & Pick<Component, 'id' | 'name' | 'width' | 'svg' | 'type' | 'subtype' | 'exportPrice' | 'factoryPrice'>
    )> }
  )> }
);

export type GetConfigurationByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetConfigurationByIdQuery = (
  { readonly __typename?: 'Query' }
  & { readonly configuration?: Maybe<(
    { readonly __typename?: 'Configuration' }
    & Pick<Configuration, 'id' | 'name'>
  )> }
);

export type GetPanelComponentQueryVariables = Exact<{
  plugRegion?: Maybe<PlugRegion>;
  type?: Maybe<ComponentType>;
}>;


export type GetPanelComponentQuery = (
  { readonly __typename?: 'Query' }
  & { readonly components: ReadonlyArray<(
    { readonly __typename?: 'Component' }
    & CreatePanelComponentFragment
  )>, readonly filler: ReadonlyArray<(
    { readonly __typename?: 'Component' }
    & CreatePanelComponentFragment
  )> }
);

export type ListConfigurationsQueryVariables = Exact<{
  isActive?: Maybe<Scalars['Boolean']>;
  createdBySelf?: Maybe<Scalars['Boolean']>;
  plugRegion?: Maybe<PlugRegion>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
}>;


export type ListConfigurationsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly configurations: ReadonlyArray<(
    { readonly __typename?: 'Configuration' }
    & Pick<Configuration, 'id' | 'name' | 'width' | 'customWidth'>
    & { readonly components: ReadonlyArray<(
      { readonly __typename?: 'Component' }
      & Pick<Component, 'id' | 'width' | 'svg'>
    )> }
  )> }
);

export type ResetCustomPricesMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResetCustomPricesMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly resetConfigurationPrices: (
    { readonly __typename?: 'Configuration' }
    & Pick<Configuration, 'id'>
  ) }
);

export type SetCustomPriceMutationVariables = Exact<{
  id: Scalars['ID'];
  exportPrice: Scalars['Float'];
  factoryPrice: Scalars['Float'];
}>;


export type SetCustomPriceMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly setConfigurationCustomPrices: (
    { readonly __typename?: 'Configuration' }
    & Pick<Configuration, 'id'>
  ) }
);

export type UndeleteConfigurationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UndeleteConfigurationMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly result: (
    { readonly __typename?: 'Configuration' }
    & Pick<Configuration, 'id'>
  ) }
);

export type UpdateConfigurationMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type UpdateConfigurationMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateConfiguration: (
    { readonly __typename?: 'Configuration' }
    & Pick<Configuration, 'id'>
  ) }
);

export type CreateUserMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  role: Role;
}>;


export type CreateUserMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createUser: (
    { readonly __typename?: 'User' }
    & Pick<User, 'name' | 'email' | 'role'>
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly result: (
    { readonly __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type ListUsersQueryVariables = Exact<{
  isActive?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Role>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
}>;


export type ListUsersQuery = (
  { readonly __typename?: 'Query' }
  & { readonly users: ReadonlyArray<(
    { readonly __typename?: 'User' }
    & UserDetailsFragment
  )> }
);

export type UndeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UndeleteUserMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly result: (
    { readonly __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
}>;


export type UpdateUserMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateUser: (
    { readonly __typename?: 'User' }
    & UserDetailsFragment
  ) }
);

export type UserDetailsFragment = (
  { readonly __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'email' | 'role' | 'picture' | 'isActive'>
);

export type GetDefaultComponentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDefaultComponentsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly endRight: ReadonlyArray<(
    { readonly __typename?: 'Component' }
    & ComponentDetailsFragment
  )>, readonly endLeft: ReadonlyArray<(
    { readonly __typename?: 'Component' }
    & ComponentDetailsFragment
  )>, readonly logoVertical: ReadonlyArray<(
    { readonly __typename?: 'Component' }
    & ComponentDetailsFragment
  )>, readonly logoHorizontal: ReadonlyArray<(
    { readonly __typename?: 'Component' }
    & ComponentDetailsFragment
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { readonly __typename?: 'Query' }
  & { readonly me?: Maybe<(
    { readonly __typename?: 'User' }
    & UserDetailsFragment
  )> }
);

export const ComponentDetailsFragmentDoc = gql`
    fragment ComponentDetails on Component {
  id
  name
  description
  exportPrice
  factoryPrice
  isActive
  width
  svg
  thickness
  type
  subtype
  plugRegions
  createdAt
}
    `;
export const ConfigurationDetailsFragmentDoc = gql`
    fragment ConfigurationDetails on Configuration {
  id
  name
  description
  createdAt
  isActive
  width
  plugRegion
  components {
    id
    name
  }
  exportPrice
  factoryPrice
}
    `;
export const CreatePanelComponentFragmentDoc = gql`
    fragment CreatePanelComponent on Component {
  id
  name
  exportPrice
  factoryPrice
  width
  svg
  type
  subtype
}
    `;
export const UserDetailsFragmentDoc = gql`
    fragment UserDetails on User {
  id
  name
  email
  role
  picture
  isActive
}
    `;
export const GetBasePriceListDocument = gql`
    query GetBasePriceList {
  basePrice {
    width
    exportPrice
    factoryPrice
  }
}
    `;

/**
 * __useGetBasePriceListQuery__
 *
 * To run a query within a React component, call `useGetBasePriceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasePriceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasePriceListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBasePriceListQuery(baseOptions?: Apollo.QueryHookOptions<GetBasePriceListQuery, GetBasePriceListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBasePriceListQuery, GetBasePriceListQueryVariables>(GetBasePriceListDocument, options);
      }
export function useGetBasePriceListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBasePriceListQuery, GetBasePriceListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBasePriceListQuery, GetBasePriceListQueryVariables>(GetBasePriceListDocument, options);
        }
export type GetBasePriceListQueryHookResult = ReturnType<typeof useGetBasePriceListQuery>;
export type GetBasePriceListLazyQueryHookResult = ReturnType<typeof useGetBasePriceListLazyQuery>;
export type GetBasePriceListQueryResult = Apollo.QueryResult<GetBasePriceListQuery, GetBasePriceListQueryVariables>;
export const UpdateBasePriceDocument = gql`
    mutation UpdateBasePrice($width: PanelWidth!, $exportPrice: Float!, $factoryPrice: Float!) {
  updateBasePrices(
    width: {width: $width, exportPrice: $exportPrice, factoryPrice: $factoryPrice}
  ) {
    width
  }
}
    `;
export type UpdateBasePriceMutationFn = Apollo.MutationFunction<UpdateBasePriceMutation, UpdateBasePriceMutationVariables>;

/**
 * __useUpdateBasePriceMutation__
 *
 * To run a mutation, you first call `useUpdateBasePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBasePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBasePriceMutation, { data, loading, error }] = useUpdateBasePriceMutation({
 *   variables: {
 *      width: // value for 'width'
 *      exportPrice: // value for 'exportPrice'
 *      factoryPrice: // value for 'factoryPrice'
 *   },
 * });
 */
export function useUpdateBasePriceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBasePriceMutation, UpdateBasePriceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBasePriceMutation, UpdateBasePriceMutationVariables>(UpdateBasePriceDocument, options);
      }
export type UpdateBasePriceMutationHookResult = ReturnType<typeof useUpdateBasePriceMutation>;
export type UpdateBasePriceMutationResult = Apollo.MutationResult<UpdateBasePriceMutation>;
export type UpdateBasePriceMutationOptions = Apollo.BaseMutationOptions<UpdateBasePriceMutation, UpdateBasePriceMutationVariables>;
export const CreateComponentDocument = gql`
    mutation CreateComponent($name: String!, $description: String = "", $factoryPrice: Float!, $exportPrice: Float!, $width: Float!, $svg: String!, $type: ComponentType!, $subtype: ComponentSubtype, $plugRegions: [PlugRegion!]!) {
  createComponent(
    component: {name: $name, description: $description, factoryPrice: $factoryPrice, exportPrice: $exportPrice, width: $width, svg: $svg, type: $type, subtype: $subtype, plugRegions: $plugRegions}
  ) {
    ...ComponentDetails
  }
}
    ${ComponentDetailsFragmentDoc}`;
export type CreateComponentMutationFn = Apollo.MutationFunction<CreateComponentMutation, CreateComponentMutationVariables>;

/**
 * __useCreateComponentMutation__
 *
 * To run a mutation, you first call `useCreateComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createComponentMutation, { data, loading, error }] = useCreateComponentMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      factoryPrice: // value for 'factoryPrice'
 *      exportPrice: // value for 'exportPrice'
 *      width: // value for 'width'
 *      svg: // value for 'svg'
 *      type: // value for 'type'
 *      subtype: // value for 'subtype'
 *      plugRegions: // value for 'plugRegions'
 *   },
 * });
 */
export function useCreateComponentMutation(baseOptions?: Apollo.MutationHookOptions<CreateComponentMutation, CreateComponentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateComponentMutation, CreateComponentMutationVariables>(CreateComponentDocument, options);
      }
export type CreateComponentMutationHookResult = ReturnType<typeof useCreateComponentMutation>;
export type CreateComponentMutationResult = Apollo.MutationResult<CreateComponentMutation>;
export type CreateComponentMutationOptions = Apollo.BaseMutationOptions<CreateComponentMutation, CreateComponentMutationVariables>;
export const DeleteComponentDocument = gql`
    mutation DeleteComponent($id: ID!) {
  result: deleteComponent(id: $id) {
    id
  }
}
    `;
export type DeleteComponentMutationFn = Apollo.MutationFunction<DeleteComponentMutation, DeleteComponentMutationVariables>;

/**
 * __useDeleteComponentMutation__
 *
 * To run a mutation, you first call `useDeleteComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteComponentMutation, { data, loading, error }] = useDeleteComponentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteComponentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteComponentMutation, DeleteComponentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteComponentMutation, DeleteComponentMutationVariables>(DeleteComponentDocument, options);
      }
export type DeleteComponentMutationHookResult = ReturnType<typeof useDeleteComponentMutation>;
export type DeleteComponentMutationResult = Apollo.MutationResult<DeleteComponentMutation>;
export type DeleteComponentMutationOptions = Apollo.BaseMutationOptions<DeleteComponentMutation, DeleteComponentMutationVariables>;
export const GetComponentByIdDocument = gql`
    query GetComponentById($id: ID!) {
  component(id: $id) {
    ...ComponentDetails
  }
}
    ${ComponentDetailsFragmentDoc}`;

/**
 * __useGetComponentByIdQuery__
 *
 * To run a query within a React component, call `useGetComponentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComponentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComponentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetComponentByIdQuery(baseOptions: Apollo.QueryHookOptions<GetComponentByIdQuery, GetComponentByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetComponentByIdQuery, GetComponentByIdQueryVariables>(GetComponentByIdDocument, options);
      }
export function useGetComponentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetComponentByIdQuery, GetComponentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetComponentByIdQuery, GetComponentByIdQueryVariables>(GetComponentByIdDocument, options);
        }
export type GetComponentByIdQueryHookResult = ReturnType<typeof useGetComponentByIdQuery>;
export type GetComponentByIdLazyQueryHookResult = ReturnType<typeof useGetComponentByIdLazyQuery>;
export type GetComponentByIdQueryResult = Apollo.QueryResult<GetComponentByIdQuery, GetComponentByIdQueryVariables>;
export const GetThicknessDocument = gql`
    query GetThickness($id: ID!) {
  componentThicknessOptions(id: $id) {
    id
    none
    thick
    thicker
    thickest
  }
}
    `;

/**
 * __useGetThicknessQuery__
 *
 * To run a query within a React component, call `useGetThicknessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThicknessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThicknessQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetThicknessQuery(baseOptions: Apollo.QueryHookOptions<GetThicknessQuery, GetThicknessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetThicknessQuery, GetThicknessQueryVariables>(GetThicknessDocument, options);
      }
export function useGetThicknessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetThicknessQuery, GetThicknessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetThicknessQuery, GetThicknessQueryVariables>(GetThicknessDocument, options);
        }
export type GetThicknessQueryHookResult = ReturnType<typeof useGetThicknessQuery>;
export type GetThicknessLazyQueryHookResult = ReturnType<typeof useGetThicknessLazyQuery>;
export type GetThicknessQueryResult = Apollo.QueryResult<GetThicknessQuery, GetThicknessQueryVariables>;
export const ListComponentsDocument = gql`
    query ListComponents($isActive: Boolean = true, $type: ComponentType, $subtype: ComponentSubtype, $offset: Int, $limit: Int, $search: String) {
  components(
    input: {isActive: $isActive, type: $type, subtype: $subtype, search: $search}
    offset: $offset
    limit: $limit
  ) {
    id
    name
    type
    width
    svg
  }
}
    `;

/**
 * __useListComponentsQuery__
 *
 * To run a query within a React component, call `useListComponentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListComponentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListComponentsQuery({
 *   variables: {
 *      isActive: // value for 'isActive'
 *      type: // value for 'type'
 *      subtype: // value for 'subtype'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useListComponentsQuery(baseOptions?: Apollo.QueryHookOptions<ListComponentsQuery, ListComponentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListComponentsQuery, ListComponentsQueryVariables>(ListComponentsDocument, options);
      }
export function useListComponentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListComponentsQuery, ListComponentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListComponentsQuery, ListComponentsQueryVariables>(ListComponentsDocument, options);
        }
export type ListComponentsQueryHookResult = ReturnType<typeof useListComponentsQuery>;
export type ListComponentsLazyQueryHookResult = ReturnType<typeof useListComponentsLazyQuery>;
export type ListComponentsQueryResult = Apollo.QueryResult<ListComponentsQuery, ListComponentsQueryVariables>;
export const UndeleteComponentDocument = gql`
    mutation UndeleteComponent($id: ID!) {
  result: undeleteComponent(id: $id) {
    id
    isActive
  }
}
    `;
export type UndeleteComponentMutationFn = Apollo.MutationFunction<UndeleteComponentMutation, UndeleteComponentMutationVariables>;

/**
 * __useUndeleteComponentMutation__
 *
 * To run a mutation, you first call `useUndeleteComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndeleteComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undeleteComponentMutation, { data, loading, error }] = useUndeleteComponentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUndeleteComponentMutation(baseOptions?: Apollo.MutationHookOptions<UndeleteComponentMutation, UndeleteComponentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UndeleteComponentMutation, UndeleteComponentMutationVariables>(UndeleteComponentDocument, options);
      }
export type UndeleteComponentMutationHookResult = ReturnType<typeof useUndeleteComponentMutation>;
export type UndeleteComponentMutationResult = Apollo.MutationResult<UndeleteComponentMutation>;
export type UndeleteComponentMutationOptions = Apollo.BaseMutationOptions<UndeleteComponentMutation, UndeleteComponentMutationVariables>;
export const UpdateComponentPricesDocument = gql`
    mutation UpdateComponentPrices($id: ID!, $exportPrice: Float!, $factoryPrice: Float!) {
  updateComponentPrices(
    component: {id: $id, exportPrice: $exportPrice, factoryPrice: $factoryPrice}
  ) {
    id
  }
}
    `;
export type UpdateComponentPricesMutationFn = Apollo.MutationFunction<UpdateComponentPricesMutation, UpdateComponentPricesMutationVariables>;

/**
 * __useUpdateComponentPricesMutation__
 *
 * To run a mutation, you first call `useUpdateComponentPricesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateComponentPricesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateComponentPricesMutation, { data, loading, error }] = useUpdateComponentPricesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      exportPrice: // value for 'exportPrice'
 *      factoryPrice: // value for 'factoryPrice'
 *   },
 * });
 */
export function useUpdateComponentPricesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateComponentPricesMutation, UpdateComponentPricesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateComponentPricesMutation, UpdateComponentPricesMutationVariables>(UpdateComponentPricesDocument, options);
      }
export type UpdateComponentPricesMutationHookResult = ReturnType<typeof useUpdateComponentPricesMutation>;
export type UpdateComponentPricesMutationResult = Apollo.MutationResult<UpdateComponentPricesMutation>;
export type UpdateComponentPricesMutationOptions = Apollo.BaseMutationOptions<UpdateComponentPricesMutation, UpdateComponentPricesMutationVariables>;
export const UpdateComponentSvgDocument = gql`
    mutation UpdateComponentSvg($id: ID!, $thickness: Thickness!) {
  updateComponentSvg(id: $id, thickness: $thickness) {
    id
  }
}
    `;
export type UpdateComponentSvgMutationFn = Apollo.MutationFunction<UpdateComponentSvgMutation, UpdateComponentSvgMutationVariables>;

/**
 * __useUpdateComponentSvgMutation__
 *
 * To run a mutation, you first call `useUpdateComponentSvgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateComponentSvgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateComponentSvgMutation, { data, loading, error }] = useUpdateComponentSvgMutation({
 *   variables: {
 *      id: // value for 'id'
 *      thickness: // value for 'thickness'
 *   },
 * });
 */
export function useUpdateComponentSvgMutation(baseOptions?: Apollo.MutationHookOptions<UpdateComponentSvgMutation, UpdateComponentSvgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateComponentSvgMutation, UpdateComponentSvgMutationVariables>(UpdateComponentSvgDocument, options);
      }
export type UpdateComponentSvgMutationHookResult = ReturnType<typeof useUpdateComponentSvgMutation>;
export type UpdateComponentSvgMutationResult = Apollo.MutationResult<UpdateComponentSvgMutation>;
export type UpdateComponentSvgMutationOptions = Apollo.BaseMutationOptions<UpdateComponentSvgMutation, UpdateComponentSvgMutationVariables>;
export const CheckDuplicatePanelDocument = gql`
    query CheckDuplicatePanel($width: PanelWidth!, $customWidth: Float, $plugRegion: PlugRegion!, $components: [ID!]!) {
  duplicateConfiguration(
    input: {width: $width, customWidth: $customWidth, plugRegion: $plugRegion, components: $components}
  ) {
    id
  }
}
    `;

/**
 * __useCheckDuplicatePanelQuery__
 *
 * To run a query within a React component, call `useCheckDuplicatePanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckDuplicatePanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDuplicatePanelQuery({
 *   variables: {
 *      width: // value for 'width'
 *      customWidth: // value for 'customWidth'
 *      plugRegion: // value for 'plugRegion'
 *      components: // value for 'components'
 *   },
 * });
 */
export function useCheckDuplicatePanelQuery(baseOptions: Apollo.QueryHookOptions<CheckDuplicatePanelQuery, CheckDuplicatePanelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckDuplicatePanelQuery, CheckDuplicatePanelQueryVariables>(CheckDuplicatePanelDocument, options);
      }
export function useCheckDuplicatePanelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckDuplicatePanelQuery, CheckDuplicatePanelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckDuplicatePanelQuery, CheckDuplicatePanelQueryVariables>(CheckDuplicatePanelDocument, options);
        }
export type CheckDuplicatePanelQueryHookResult = ReturnType<typeof useCheckDuplicatePanelQuery>;
export type CheckDuplicatePanelLazyQueryHookResult = ReturnType<typeof useCheckDuplicatePanelLazyQuery>;
export type CheckDuplicatePanelQueryResult = Apollo.QueryResult<CheckDuplicatePanelQuery, CheckDuplicatePanelQueryVariables>;
export const CreateConfigurationDocument = gql`
    mutation CreateConfiguration($name: String!, $width: PanelWidth!, $customWidth: Float, $plugRegion: PlugRegion!, $components: [ID!]!) {
  createConfiguration(
    configuration: {name: $name, width: $width, customWidth: $customWidth, plugRegion: $plugRegion, components: $components}
  ) {
    id
  }
}
    `;
export type CreateConfigurationMutationFn = Apollo.MutationFunction<CreateConfigurationMutation, CreateConfigurationMutationVariables>;

/**
 * __useCreateConfigurationMutation__
 *
 * To run a mutation, you first call `useCreateConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConfigurationMutation, { data, loading, error }] = useCreateConfigurationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      width: // value for 'width'
 *      customWidth: // value for 'customWidth'
 *      plugRegion: // value for 'plugRegion'
 *      components: // value for 'components'
 *   },
 * });
 */
export function useCreateConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<CreateConfigurationMutation, CreateConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConfigurationMutation, CreateConfigurationMutationVariables>(CreateConfigurationDocument, options);
      }
export type CreateConfigurationMutationHookResult = ReturnType<typeof useCreateConfigurationMutation>;
export type CreateConfigurationMutationResult = Apollo.MutationResult<CreateConfigurationMutation>;
export type CreateConfigurationMutationOptions = Apollo.BaseMutationOptions<CreateConfigurationMutation, CreateConfigurationMutationVariables>;
export const DeleteConfigurationDocument = gql`
    mutation DeleteConfiguration($id: ID!) {
  result: deleteConfiguration(id: $id) {
    id
  }
}
    `;
export type DeleteConfigurationMutationFn = Apollo.MutationFunction<DeleteConfigurationMutation, DeleteConfigurationMutationVariables>;

/**
 * __useDeleteConfigurationMutation__
 *
 * To run a mutation, you first call `useDeleteConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConfigurationMutation, { data, loading, error }] = useDeleteConfigurationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConfigurationMutation, DeleteConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConfigurationMutation, DeleteConfigurationMutationVariables>(DeleteConfigurationDocument, options);
      }
export type DeleteConfigurationMutationHookResult = ReturnType<typeof useDeleteConfigurationMutation>;
export type DeleteConfigurationMutationResult = Apollo.MutationResult<DeleteConfigurationMutation>;
export type DeleteConfigurationMutationOptions = Apollo.BaseMutationOptions<DeleteConfigurationMutation, DeleteConfigurationMutationVariables>;
export const GeneratePdfDocument = gql`
    mutation GeneratePdf($id: ID!, $pricing: PdfPricing!, $language: String!) {
  configurationPdf(input: {id: $id, pricing: $pricing, language: $language})
}
    `;
export type GeneratePdfMutationFn = Apollo.MutationFunction<GeneratePdfMutation, GeneratePdfMutationVariables>;

/**
 * __useGeneratePdfMutation__
 *
 * To run a mutation, you first call `useGeneratePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePdfMutation, { data, loading, error }] = useGeneratePdfMutation({
 *   variables: {
 *      id: // value for 'id'
 *      pricing: // value for 'pricing'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGeneratePdfMutation(baseOptions?: Apollo.MutationHookOptions<GeneratePdfMutation, GeneratePdfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GeneratePdfMutation, GeneratePdfMutationVariables>(GeneratePdfDocument, options);
      }
export type GeneratePdfMutationHookResult = ReturnType<typeof useGeneratePdfMutation>;
export type GeneratePdfMutationResult = Apollo.MutationResult<GeneratePdfMutation>;
export type GeneratePdfMutationOptions = Apollo.BaseMutationOptions<GeneratePdfMutation, GeneratePdfMutationVariables>;
export const GetConfigurationDocument = gql`
    query GetConfiguration($id: ID!) {
  configuration(id: $id) {
    id
    name
    description
    createdAt
    isActive
    width
    customWidth
    plugRegion
    components {
      id
      name
      width
      svg
      type
      subtype
      exportPrice
      factoryPrice
    }
    exportPrice
    factoryPrice
    arePricesOverridden
  }
}
    `;

/**
 * __useGetConfigurationQuery__
 *
 * To run a query within a React component, call `useGetConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigurationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetConfigurationQuery(baseOptions: Apollo.QueryHookOptions<GetConfigurationQuery, GetConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConfigurationQuery, GetConfigurationQueryVariables>(GetConfigurationDocument, options);
      }
export function useGetConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConfigurationQuery, GetConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConfigurationQuery, GetConfigurationQueryVariables>(GetConfigurationDocument, options);
        }
export type GetConfigurationQueryHookResult = ReturnType<typeof useGetConfigurationQuery>;
export type GetConfigurationLazyQueryHookResult = ReturnType<typeof useGetConfigurationLazyQuery>;
export type GetConfigurationQueryResult = Apollo.QueryResult<GetConfigurationQuery, GetConfigurationQueryVariables>;
export const GetConfigurationByIdDocument = gql`
    query GetConfigurationById($id: ID!) {
  configuration(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetConfigurationByIdQuery__
 *
 * To run a query within a React component, call `useGetConfigurationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigurationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigurationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetConfigurationByIdQuery(baseOptions: Apollo.QueryHookOptions<GetConfigurationByIdQuery, GetConfigurationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConfigurationByIdQuery, GetConfigurationByIdQueryVariables>(GetConfigurationByIdDocument, options);
      }
export function useGetConfigurationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConfigurationByIdQuery, GetConfigurationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConfigurationByIdQuery, GetConfigurationByIdQueryVariables>(GetConfigurationByIdDocument, options);
        }
export type GetConfigurationByIdQueryHookResult = ReturnType<typeof useGetConfigurationByIdQuery>;
export type GetConfigurationByIdLazyQueryHookResult = ReturnType<typeof useGetConfigurationByIdLazyQuery>;
export type GetConfigurationByIdQueryResult = Apollo.QueryResult<GetConfigurationByIdQuery, GetConfigurationByIdQueryVariables>;
export const GetPanelComponentDocument = gql`
    query GetPanelComponent($plugRegion: PlugRegion, $type: ComponentType) {
  components(input: {isActive: true, plugRegion: $plugRegion, type: $type}) {
    ...CreatePanelComponent
  }
  filler: components(input: {isActive: true, type: FILLER}) {
    ...CreatePanelComponent
  }
}
    ${CreatePanelComponentFragmentDoc}`;

/**
 * __useGetPanelComponentQuery__
 *
 * To run a query within a React component, call `useGetPanelComponentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPanelComponentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPanelComponentQuery({
 *   variables: {
 *      plugRegion: // value for 'plugRegion'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetPanelComponentQuery(baseOptions?: Apollo.QueryHookOptions<GetPanelComponentQuery, GetPanelComponentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPanelComponentQuery, GetPanelComponentQueryVariables>(GetPanelComponentDocument, options);
      }
export function useGetPanelComponentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPanelComponentQuery, GetPanelComponentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPanelComponentQuery, GetPanelComponentQueryVariables>(GetPanelComponentDocument, options);
        }
export type GetPanelComponentQueryHookResult = ReturnType<typeof useGetPanelComponentQuery>;
export type GetPanelComponentLazyQueryHookResult = ReturnType<typeof useGetPanelComponentLazyQuery>;
export type GetPanelComponentQueryResult = Apollo.QueryResult<GetPanelComponentQuery, GetPanelComponentQueryVariables>;
export const ListConfigurationsDocument = gql`
    query ListConfigurations($isActive: Boolean = true, $createdBySelf: Boolean = false, $plugRegion: PlugRegion, $offset: Int, $limit: Int, $search: String) {
  configurations(
    input: {isActive: $isActive, createdBySelf: $createdBySelf, plugRegion: $plugRegion, search: $search}
    offset: $offset
    limit: $limit
  ) {
    id
    name
    width
    customWidth
    components {
      id
      width
      svg
    }
  }
}
    `;

/**
 * __useListConfigurationsQuery__
 *
 * To run a query within a React component, call `useListConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListConfigurationsQuery({
 *   variables: {
 *      isActive: // value for 'isActive'
 *      createdBySelf: // value for 'createdBySelf'
 *      plugRegion: // value for 'plugRegion'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useListConfigurationsQuery(baseOptions?: Apollo.QueryHookOptions<ListConfigurationsQuery, ListConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListConfigurationsQuery, ListConfigurationsQueryVariables>(ListConfigurationsDocument, options);
      }
export function useListConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListConfigurationsQuery, ListConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListConfigurationsQuery, ListConfigurationsQueryVariables>(ListConfigurationsDocument, options);
        }
export type ListConfigurationsQueryHookResult = ReturnType<typeof useListConfigurationsQuery>;
export type ListConfigurationsLazyQueryHookResult = ReturnType<typeof useListConfigurationsLazyQuery>;
export type ListConfigurationsQueryResult = Apollo.QueryResult<ListConfigurationsQuery, ListConfigurationsQueryVariables>;
export const ResetCustomPricesDocument = gql`
    mutation ResetCustomPrices($id: ID!) {
  resetConfigurationPrices(id: $id) {
    id
  }
}
    `;
export type ResetCustomPricesMutationFn = Apollo.MutationFunction<ResetCustomPricesMutation, ResetCustomPricesMutationVariables>;

/**
 * __useResetCustomPricesMutation__
 *
 * To run a mutation, you first call `useResetCustomPricesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetCustomPricesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetCustomPricesMutation, { data, loading, error }] = useResetCustomPricesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResetCustomPricesMutation(baseOptions?: Apollo.MutationHookOptions<ResetCustomPricesMutation, ResetCustomPricesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetCustomPricesMutation, ResetCustomPricesMutationVariables>(ResetCustomPricesDocument, options);
      }
export type ResetCustomPricesMutationHookResult = ReturnType<typeof useResetCustomPricesMutation>;
export type ResetCustomPricesMutationResult = Apollo.MutationResult<ResetCustomPricesMutation>;
export type ResetCustomPricesMutationOptions = Apollo.BaseMutationOptions<ResetCustomPricesMutation, ResetCustomPricesMutationVariables>;
export const SetCustomPriceDocument = gql`
    mutation SetCustomPrice($id: ID!, $exportPrice: Float!, $factoryPrice: Float!) {
  setConfigurationCustomPrices(
    configuration: {id: $id, exportPrice: $exportPrice, factoryPrice: $factoryPrice}
  ) {
    id
  }
}
    `;
export type SetCustomPriceMutationFn = Apollo.MutationFunction<SetCustomPriceMutation, SetCustomPriceMutationVariables>;

/**
 * __useSetCustomPriceMutation__
 *
 * To run a mutation, you first call `useSetCustomPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCustomPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCustomPriceMutation, { data, loading, error }] = useSetCustomPriceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      exportPrice: // value for 'exportPrice'
 *      factoryPrice: // value for 'factoryPrice'
 *   },
 * });
 */
export function useSetCustomPriceMutation(baseOptions?: Apollo.MutationHookOptions<SetCustomPriceMutation, SetCustomPriceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCustomPriceMutation, SetCustomPriceMutationVariables>(SetCustomPriceDocument, options);
      }
export type SetCustomPriceMutationHookResult = ReturnType<typeof useSetCustomPriceMutation>;
export type SetCustomPriceMutationResult = Apollo.MutationResult<SetCustomPriceMutation>;
export type SetCustomPriceMutationOptions = Apollo.BaseMutationOptions<SetCustomPriceMutation, SetCustomPriceMutationVariables>;
export const UndeleteConfigurationDocument = gql`
    mutation UndeleteConfiguration($id: ID!) {
  result: undeleteConfiguration(id: $id) {
    id
  }
}
    `;
export type UndeleteConfigurationMutationFn = Apollo.MutationFunction<UndeleteConfigurationMutation, UndeleteConfigurationMutationVariables>;

/**
 * __useUndeleteConfigurationMutation__
 *
 * To run a mutation, you first call `useUndeleteConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndeleteConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undeleteConfigurationMutation, { data, loading, error }] = useUndeleteConfigurationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUndeleteConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UndeleteConfigurationMutation, UndeleteConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UndeleteConfigurationMutation, UndeleteConfigurationMutationVariables>(UndeleteConfigurationDocument, options);
      }
export type UndeleteConfigurationMutationHookResult = ReturnType<typeof useUndeleteConfigurationMutation>;
export type UndeleteConfigurationMutationResult = Apollo.MutationResult<UndeleteConfigurationMutation>;
export type UndeleteConfigurationMutationOptions = Apollo.BaseMutationOptions<UndeleteConfigurationMutation, UndeleteConfigurationMutationVariables>;
export const UpdateConfigurationDocument = gql`
    mutation UpdateConfiguration($id: ID!, $name: String!) {
  updateConfiguration(configuration: {id: $id, name: $name}) {
    id
  }
}
    `;
export type UpdateConfigurationMutationFn = Apollo.MutationFunction<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>;

/**
 * __useUpdateConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConfigurationMutation, { data, loading, error }] = useUpdateConfigurationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>(UpdateConfigurationDocument, options);
      }
export type UpdateConfigurationMutationHookResult = ReturnType<typeof useUpdateConfigurationMutation>;
export type UpdateConfigurationMutationResult = Apollo.MutationResult<UpdateConfigurationMutation>;
export type UpdateConfigurationMutationOptions = Apollo.BaseMutationOptions<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($name: String!, $email: String!, $role: Role!) {
  createUser(user: {name: $name, email: $email, role: $role}) {
    name
    email
    role
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: ID!) {
  result: deleteUser(id: $id) {
    id
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const ListUsersDocument = gql`
    query ListUsers($isActive: Boolean = true, $role: Role, $offset: Int, $limit: Int, $search: String) {
  users(
    input: {isActive: $isActive, role: $role, search: $search}
    offset: $offset
    limit: $limit
  ) {
    ...UserDetails
  }
}
    ${UserDetailsFragmentDoc}`;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      isActive: // value for 'isActive'
 *      role: // value for 'role'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useListUsersQuery(baseOptions?: Apollo.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
      }
export function useListUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
        }
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersQueryResult = Apollo.QueryResult<ListUsersQuery, ListUsersQueryVariables>;
export const UndeleteUserDocument = gql`
    mutation UndeleteUser($id: ID!) {
  result: undeleteUser(id: $id) {
    id
  }
}
    `;
export type UndeleteUserMutationFn = Apollo.MutationFunction<UndeleteUserMutation, UndeleteUserMutationVariables>;

/**
 * __useUndeleteUserMutation__
 *
 * To run a mutation, you first call `useUndeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undeleteUserMutation, { data, loading, error }] = useUndeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUndeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<UndeleteUserMutation, UndeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UndeleteUserMutation, UndeleteUserMutationVariables>(UndeleteUserDocument, options);
      }
export type UndeleteUserMutationHookResult = ReturnType<typeof useUndeleteUserMutation>;
export type UndeleteUserMutationResult = Apollo.MutationResult<UndeleteUserMutation>;
export type UndeleteUserMutationOptions = Apollo.BaseMutationOptions<UndeleteUserMutation, UndeleteUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: ID!, $name: String, $email: String, $role: Role) {
  updateUser(user: {id: $id, name: $name, email: $email, role: $role}) {
    ...UserDetails
  }
}
    ${UserDetailsFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetDefaultComponentsDocument = gql`
    query GetDefaultComponents {
  endRight: components(input: {isActive: true, type: END_PLUG, subtype: RIGHTEND}) {
    ...ComponentDetails
  }
  endLeft: components(input: {isActive: true, type: END_PLUG, subtype: LEFTEND}) {
    ...ComponentDetails
  }
  logoVertical: components(input: {isActive: true, type: LOGO, subtype: VERTICAL}) {
    ...ComponentDetails
  }
  logoHorizontal: components(
    input: {isActive: true, type: LOGO, subtype: HORIZONTAL}
  ) {
    ...ComponentDetails
  }
}
    ${ComponentDetailsFragmentDoc}`;

/**
 * __useGetDefaultComponentsQuery__
 *
 * To run a query within a React component, call `useGetDefaultComponentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultComponentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultComponentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultComponentsQuery(baseOptions?: Apollo.QueryHookOptions<GetDefaultComponentsQuery, GetDefaultComponentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDefaultComponentsQuery, GetDefaultComponentsQueryVariables>(GetDefaultComponentsDocument, options);
      }
export function useGetDefaultComponentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultComponentsQuery, GetDefaultComponentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDefaultComponentsQuery, GetDefaultComponentsQueryVariables>(GetDefaultComponentsDocument, options);
        }
export type GetDefaultComponentsQueryHookResult = ReturnType<typeof useGetDefaultComponentsQuery>;
export type GetDefaultComponentsLazyQueryHookResult = ReturnType<typeof useGetDefaultComponentsLazyQuery>;
export type GetDefaultComponentsQueryResult = Apollo.QueryResult<GetDefaultComponentsQuery, GetDefaultComponentsQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...UserDetails
  }
}
    ${UserDetailsFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;