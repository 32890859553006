import { NavLink } from 'react-router-dom';
import { FullNavLinkProps } from '../../lib/types';

function MobilePageLink<TState>(props: FullNavLinkProps<TState>) {
  return (
    <NavLink {...props}
      exact
      activeClassName="bg-gray-200 border-gray-500 text-primary-red-500 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
      className="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
      role="menuitem"
    >
      {props.children}
    </NavLink>
  );
}
export default MobilePageLink;
