import { useTranslation } from 'react-i18next';
import { useMe } from '../../hooks/Me.hook';
import { formatCurrency, translatePlugRegion } from '../../lib/format';
import { GetConfigurationQuery, Role } from '../../lib/graphql.generated';
import { ComponentDetail } from '../../pages/PanelViewPage.component';

type Description = {
  title: string;
  value: string;
};

export interface PanelDetailProps {
  data?: GetConfigurationQuery;
  details: ComponentDetail[];
  width: string;
}

function PanelDetails({ data, details, width }: PanelDetailProps) {
  const { t } = useTranslation();
  const me = useMe();

  const description: Description[] = [
    { title: t('label.name'), value: data?.configuration?.name ?? '' },
    { title: t('label.region'), value: translatePlugRegion(data?.configuration?.plugRegion!) ?? '' },
    { title: t('label.width'), value: width },
  ];
  if (data?.configuration?.description) {
    description.push({ title: t('label.description'), value: data?.configuration?.description ?? '' });
  }
  const isDistributorWithoutPrice = me?.role == Role.DistributorWithoutPrice;
  const isDistributor = me?.role == Role.Distributor;
  const label = isDistributor ? t('label.exportPrice') : t('label.factPrice');
  const price = isDistributor ? formatCurrency(data?.configuration?.exportPrice!)
    : formatCurrency(data?.configuration?.factoryPrice!);

  return (
    <div className="flex flex-col w-full space-y-5 space-x-0 xl:flex-row 2xl:flex-row lg:flex-row xl:space-x-5 lg:space-x-5 xl:space-x-5 2xl:space-x-5 2xl:space-y-0 xl:space-y-0 lg:space-y-0 xl:w-9/12 lg:w-9/12 mx-auto content-center max-h-screen-md">
      <div className="flex text-sm max-w-md xl:text-md lg:text-md 2xl:text-md p-4 bg-white rounded-md shadow-md">
        <div className="flex-col w-full px-2">
          <div className="border-gray-200 border-b w-full pb-2">
            <p className="inline-flex capitalize justify-start text-sm xl:text-md lg:text-md 2xl:text-md font-semibold">{t('label.description')}</p>
          </div>
          <ul className="flex flex-col space-y-4 pt-2">
            {description.map(({ title, value }) =>
              <li
                key={title}
                className="px-4 flex-row inline-flex justify-between sm:px-2 space-x-6"
              >
                <p className="text-sm xl:text-md lg:text-md 2xl:text-md font-semibold">
                  {title}:
                </p>
                <p className="text-sm xl:text-md lg:text-md 2xl:text-md">
                  {value}
                </p>
              </li>,
            )}
          </ul>
        </div>
      </div>
      <div className="bg-gray-100 xl:p-8 md:p-4 sm:p-2 flex-1 flex-col divide-y divide-gray-200 ">
        <ul className="flex flex-col w-full py-4 space-y-2">
          <li className="px-4 flex-row-reverse flex w-full justify-between text-sm xl:text-md lg:text-md 2xl:text-md font-semibold">
            {/* {me?.role == Role.Distributor ? t('label.exportPrice') : t('label.factPrice')} */}
            {t('label.quantity')}
          </li>
          {details.map((prices, index) => {
            return (<li key={`priceList__${index}`} className="px-4 flex-row flex w-full justify-between">
              {/* <p className="flex-shrink-0 pr-8 text-sm xl:text-md lg:text-md 2xl:text-md">x{prices.quantity}</p> */}
              <span className="inline-flex flex-1 justify-end">
                <p className="flex-1 text-sm xl:text-md lg:text-md 2xl:text-md">{prices.name}</p>
                <p className="flex-shrink-0 text-sm xl:text-md lg:text-md 2xl:text-md">
                  {/* {me?.role == Role.Distributor ? prices.exportPrice : prices.factoryPrice}€ */}
                  {prices.quantity}
                </p>
              </span>
            </li>);
          })}
        </ul>

        <div key='total' className="py-4 px-4 flex flex-col w-full ">
          {isDistributorWithoutPrice ? null
            : <span className="inline-flex justify-between">
              <p className="text-sm xl:text-md lg:text-md 2xl:text-md">
                {label}</p>
              {/* <p className="text-sm xl:text-md lg:text-md 2xl:text-md">{t('totalPrice')}</p> */}
              <p className="text-sm xl:text-md lg:text-md 2xl:text-md">
                {price}
              </p>
            </span>
          }
        </div>
      </div>
    </div>
  );
}

export default PanelDetails;