import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

interface SpinnerProps {
  local?: boolean;
}
function Spinner({ local }: SpinnerProps) {
  return (
    <div className={`${local ? '' : 'fixed'} h-full w-full flex items-center justify-center`}>
      <Loader
        type="ThreeDots"
        color="#e2001a"
        height={100}
        width={100}
        timeout={3000} //3 secs
      />
    </div>
  );
}

export default Spinner;