import { ApolloQueryResult } from '@apollo/client';
import { PlusCircleIcon } from '@heroicons/react/solid';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useUndeleteUserMutation,
  ListUsersQueryVariables,
  ListUsersQuery,
  UserDetailsFragment,
} from '../../lib/graphql.generated';
import DeleteAlertModal from '../Common/DeleteAlertModal.component';


interface UnDeleteProps {
  id: string;
  refetch: (variables?: Partial<ListUsersQueryVariables>) => Promise<ApolloQueryResult<ListUsersQuery>>;
  changer: Dispatch<SetStateAction<UserDetailsFragment | undefined>>;
}
function UnDeleteUser({ id, refetch, changer }: UnDeleteProps) {
  const [
    UnDeleteUserMutation,
    { data: undeleteResult, loading, error: undeleteError },
  ] = useUndeleteUserMutation();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const { t } = useTranslation();
  let message: string | undefined;
  let error: string | undefined;

  if (undeleteError) {
    console.error(undeleteError);
    error = t('user.error.undeleteError');
  }
  if (loading) {
    message = t('user.awaitMsg.undelete');
  }
  if (undeleteResult) {
    changer(undefined);
  }
  return (
    <div>
      <div className="flex space-x-3  " >
        <div className="relative rounded-lg px-6 py-5 flex items-center space-x-2 mt-1 " >
          <div className="flex-shrink-0 " >
            {message && <p className="text-sm " >{message}</p>}
            {error && <p className="text-red-600 text-sm">{error}</p>}
          </div>
          <PlusCircleIcon className={`h-8 w-8 text-gray-700 hover:text-red-600 ml-10 ${loading && 'animate-spin'}`}
            onClick={() => { setShowDeleteConfirm(true); }
            }
          />
        </div>

      </div>
      <DeleteAlertModal
        id={id}
        show={showDeleteConfirm}
        setClose={setShowDeleteConfirm}
        action={UnDeleteUserMutation}
        result={undeleteResult}
        status={false}
        next={refetch}
      />
    </div>
  );
}
export default UnDeleteUser;