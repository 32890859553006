import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useMenuState } from 'reakit';
import { useActionModal } from '../../hooks/ActionModal.hook';
import { useMe } from '../../hooks/Me.hook';
import { Role } from '../../lib/graphql.generated';
import Logo from '../../Treston_logo.svg';
import CreateButton from './CreateButton.component';
import HamburgerIcon from './HamburgerIcon.component';
import MobileMenu from './MobileMenu.component';
import NavbarLink from './NavbarLink.component';
import Profile from './Profile.component';
import SaveButton from './SaveButton.component';
import Translate from './Translate.component';

function Navbar() {
  const { t } = useTranslation();
  const location = useLocation();
  const pathname: string = (location.pathname.split('/'))[1].toString();
  const menu = useMenuState();
  const history = useHistory();
  const onClickNewPanel = () => history.push(`${pathname}/new`);
  const onClickViewPanel = () => history.push('/panels/new');
  const { savePanel, createUser, createComponent } = useActionModal();
  const me = useMe();
  const isAdmin = me?.role == Role.Admin ?? false;

  return (
    <nav className="bg-white shadow mb-1">
      <div className="mx-auto px-2 sm:px-4 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex px-2 lg:px-0">

            {/*Mobile Menu Icon*/}
            <HamburgerIcon {...menu} />

            {/*Navbar link*/}
            <div className="hidden lg:ml-6 lg:flex lg:space-x-8" role="menubar">
              <Link className="m-auto" to={'/'}><img className="h-8 w-auto sm:h-12" src={Logo} alt="Logo" /></Link>
              <NavbarLink to="/">
                {t('navbar.link.home')}
              </NavbarLink>
              <NavbarLink to="/panels">
                {t('navbar.link.panel')}
              </NavbarLink>
              {isAdmin &&
                <>
                  <NavbarLink to="/components">
                    {t('navbar.link.component')}
                  </NavbarLink>
                  <NavbarLink to="/base_prices">
                    {t('navbar.link.basePrice')}
                  </NavbarLink>
                  <NavbarLink to="/members">
                    {t('navbar.link.member')}
                  </NavbarLink>
                </>
              }
            </div>
          </div>


          <div className="flex items-center">
            {/*Button  */}
            {(() => {
              if (location.pathname == '/panels/new') {
                return (<SaveButton onClick={() => { savePanel.open(); }} />);
              } else if (location.pathname.startsWith('/panels') && location.pathname == '/panels') {
                return <CreateButton message={t('navbar.button.panel')} onClick={onClickNewPanel} />;
              } else if (location.pathname.startsWith('/panels') && location.pathname !== '/panels') {
                return <CreateButton message={t('navbar.button.panel')} onClick={onClickViewPanel} />;
              } else if (location.pathname.startsWith('/components')) {
                return <CreateButton message={t('navbar.button.component')} onClick={createComponent.open} />;
              } else if (location.pathname.startsWith('/members')) {
                return <CreateButton message={t('navbar.button.member')} onClick={createUser.open} />;
              } else {
                return null;
              }
            })()}
            <div className="ml-4">
              <Translate />
            </div>
            <div className="hidden lg:block">
              <Profile />
            </div>
          </div>
        </div>
      </div>
      <MobileMenu {...menu} />
    </nav>
  );
}
export default Navbar;