import { Fragment } from 'react';
import { Configuration } from '../../lib/graphql.generated';
import { mm2px } from '../../lib/measurement';

interface SvgPreviewProps {
  configuration: Configuration;
  appropriateScale: number;
}
function SvgPreview({ configuration, appropriateScale }: SvgPreviewProps) {

  return (
    <>
      {
        configuration.components.map((c, index) => {
          const dataUri = `url("${c.svg}")`;
          return (
            <Fragment key={`frag_${c.id}__${index}`}>
              {index == ((configuration.components.length ?? 0) - 1) && <div className="flex-grow" />}
              <div className="flex-none z-10" >
                <div
                  className="relative"
                  style={{
                    backgroundImage: dataUri,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    height: `${mm2px(50, appropriateScale)}px`,
                    width: `${mm2px(c.width, appropriateScale)}px`,
                  }}
                />
              </div>
            </Fragment>
          );
        })
      }
    </>
  );
}

export default SvgPreview;