import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabStateReturn } from 'reakit';
import { useCurrentConfiguration } from '../../hooks/CurrentConfiguration.hook';
import { useMe } from '../../hooks/Me.hook';
import { formatCurrency } from '../../lib/format';
import { ComponentType, Role } from '../../lib/graphql.generated';
import TabItem from './TabItem.component';

export interface SidebarProps {
  tab: TabStateReturn;
}
export interface StackProps {
  name: string;
  exportPrice: number;
  factPrice: number;
  qty: number;
}
function Sidebar({ tab }: SidebarProps) {
  const { t } = useTranslation();
  const { configuration } = useCurrentConfiguration();
  const me = useMe();
  const showFactPrice = (me?.role == Role.Admin || me?.role == Role.Salesperson);
  const label = showFactPrice ? t('label.factPrice') : t('label.exportPrice');
  const isDistributorWithoutPrice = me?.role == Role.DistributorWithoutPrice;
  const price = showFactPrice ? (formatCurrency(configuration?.factoryPrice!)) :
    (formatCurrency(configuration?.exportPrice!)) ?? formatCurrency(0);

  return (
    <div className="flex flex-col w-full md:w-64 flex-grow border-r border-gray-200 bg-gray-100">
      <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
        <div className="flex items-center flex-shrink-0 px-4 pt-3 pb-4">
          <h1 className="text-3xl font-bold">{t('navbar.link.component')}</h1>
        </div>
        <TabList
          {...tab}
          className="mt-5 flex-auto px-2 bg-gray-100 space-y-1"
          aria-label={t('component.tablist.label')}
        >
          <TabItem
            id="WIDTH_PANEL"
            label={t('component.type.width')}
            tab={tab}
          />
          <TabItem
            id={ComponentType.Switch}
            label={t('component.type.switch')}
            tab={tab}
          />
          <TabItem
            id={ComponentType.PowerSocket}
            label={t('component.type.power')}
            tab={tab}
          />
          <TabItem
            id={ComponentType.OtherSocket}
            label={t('component.type.other')}
            tab={tab}
          />
        </TabList>
      </div>

      <div className="flex-shrink-0 flex-col flex border-t border-gray-200 p-4 ">
        {isDistributorWithoutPrice ? null :
          <div className="flex flex-col items-stretch">
            <div className="ml-3 flex flex-row justify-between">
              <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                {showFactPrice == true ? label : label}</p>
              <p className="text-base font-medium text-gray-500 group-hover:text-gray-700">
                {showFactPrice == true ? price : price}</p>
            </div>
          </div>
        }
        <div className="flex flex-row pt-4 justify-center">
          <TabList
            {...tab}
            aria-label={t('component.tablist.label')}
          >
            <Tab
              {...tab}
              id='PRICE_LIST'
              className="inline-flex items-center px-3 py-2 border border-transparent text-base leading-4 font-medium rounded-md shadow-sm text-white bg-primary-grey-default hover:bg-primary-grey-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-grey-200"
            >
              {t('panel.button.componentList')}
            </Tab>
          </TabList>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;