import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import DeleteAlertModal from '../components/Common/DeleteAlertModal.component';
import DeleteButton from '../components/Common/DeleteButton.component';
import AdjustButton from '../components/Component/AdjustButton.component';
import NewComponentModal from '../components/Component/NewComponentModal.component';
import SetComponentPricesButton from '../components/Component/SetComponentPricesButton.component';
import SetComponentPricesModal from '../components/Component/SetComponentPricesModal.component';
import Spinner from '../components/Spinner.component';
import { useMe } from '../hooks/Me.hook';
import { formatWidth, formatCurrency, translateComponentType, translatePlugRegion } from '../lib/format';
import { useDeleteComponentMutation, useGetComponentByIdQuery, useUndeleteComponentMutation, useGetThicknessQuery, Role } from '../lib/graphql.generated';
import { baseScale, mm2px } from '../lib/measurement';
import NotFound from './NotFound.component';

type PageParams = { id: string };

function ComponentPage() {
  const me = useMe();
  const { t } = useTranslation();
  const { id } = useParams<PageParams>();
  const history = useHistory();
  const [ShowSetCustomPrices, setShowSetCustomPrices] = useState(false);

  const { data, loading, error, refetch } = useGetComponentByIdQuery({
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });
  void useGetThicknessQuery({
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });
  const [scale] = useState<number>(baseScale / 2);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const [deleteComponentMutation, {
    data: deleteResult,
  }] = useDeleteComponentMutation();

  const [undeleteComponentMutation, {
    data: undeleteResult,
  }] = useUndeleteComponentMutation();

  if (me?.role != Role.Admin) {
    return <NotFound />;
  }
  if (loading) {
    return <Spinner />;
  } else if (error) {
    console.error('ERROR LOADING CONFIG', error);
  }
  const dataUri = `url("${data?.component?.svg}")`;

  return (
    <div className="min-h-content 2xl:px-20 xl:px-20 lg:px-20 md:px-14 px-8">
      <div className="w-full pt-10 flex flex-row justify-between">
        <h1 className="text-primary-grey-default text-xl font-bold">{data?.component?.name}</h1>
        <div className="flex flex-row space-x-2 flex-shrink-0 xl:pr-20 lg:pr-20 2xl:pr-20 pr-4 ">
          {data?.component &&
            <>
              <SetComponentPricesButton
                action={setShowSetCustomPrices}
              />
              <AdjustButton
                id={id}
                refetch={refetch}
                thickness={data.component.thickness}
              />
              <DeleteButton
                action={setShowDeleteConfirm}
                isActive={data?.component?.isActive}
              />
            </>
          }
        </div>
      </div>
      <div className="pt-20 justify-items-center self-center h-full grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 2xl:space-y-0 xl:space-y-0 lg:space-y-0 space-y-5 grid-cols-1">
        <span className="flex flex-col justify-center">
          <div
            className="relative text-gray-500"
            style={{
              backgroundImage: dataUri,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              height: `${mm2px(50, scale)}px`,
              width: `${mm2px(data?.component?.width!, scale)}px`,
            }}
          />
        </span>
        {/* </div> */}
        <div className="bg-gray-50 xl:p-10 md:p-6 sm:p-4 sm:w-full xl:w-9/12 lg:w-9/12 md:w-full flex-1 flex-col my-auto content-center">
          <div className="grid grid-cols-2">
            <span className="flex flex-col space-y-4">
              <p className="capitalize text-sm xl:text-md lg:text-md 2xl:text-md font-semibold">{t('label.type')}:</p>
              <p className="capitalize text-sm xl:text-md lg:text-md 2xl:text-md font-semibold">{t('label.region')}:</p>
              <p className="capitalize text-sm xl:text-md lg:text-md 2xl:text-md font-semibold">{t('label.width')}:</p>
              <p className="capitalize text-sm xl:text-md lg:text-md 2xl:text-md font-semibold">{t('label.exportPrice')}:</p>
              <p className="capitalize text-sm xl:text-md lg:text-md 2xl:text-md font-semibold">{t('label.factPrice')}:</p>
              <p className="capitalize text-sm xl:text-md lg:text-md 2xl:text-md font-semibold">{t('label.description')}:</p>
            </span>
            <span className="flex flex-col space-y-4 ml-4">
              <p className="capitalize text-sm xl:text-md lg:text-md 2xl:text-md">{translateComponentType(data?.component?.type!)}</p>
              <p className="capitalize relative text-sm xl:text-md lg:text-md 2xl:text-md">{data?.component?.plugRegions.length! > 1 ? 'All' : translatePlugRegion(data?.component?.plugRegions[0]!)}</p>
              <p className="lowercase text-sm xl:text-md lg:text-md 2xl:text-md">{formatWidth(data?.component?.width!)}</p>
              <p className="capitalize text-sm xl:text-md lg:text-md 2xl:text-md">{formatCurrency(data?.component?.exportPrice!)}</p>
              <p className="capitalize text-sm xl:text-md lg:text-md 2xl:text-md">{formatCurrency(data?.component?.factoryPrice!)}</p>
              <p className="capitalize text-sm xl:text-md lg:text-md 2xl:text-md">{data?.component?.description}</p>
            </span>
          </div>
        </div>
      </div>
      {data?.component && data.component.isActive &&
        <DeleteAlertModal
          id={id}
          show={showDeleteConfirm}
          setClose={setShowDeleteConfirm}
          action={deleteComponentMutation}
          result={deleteResult}
          status={true}
          next={() => history.push('/components')}
        />
      }
      {data?.component && !data.component.isActive &&
        <DeleteAlertModal
          id={id}
          show={showDeleteConfirm}
          setClose={setShowDeleteConfirm}
          action={undeleteComponentMutation}
          result={undeleteResult}
          status={false}
          next={refetch}
        />
      }
      {data?.component && (
        <SetComponentPricesModal
          id={id}
          exportPrice={data.component.exportPrice}
          factoryPrice={data.component.factoryPrice}
          show={ShowSetCustomPrices}
          setClose={setShowSetCustomPrices}
          next={refetch}
        />
      )}
      <NewComponentModal />
    </div>
  );
}
export default ComponentPage;