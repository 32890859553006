import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid';
import { parse } from 'query-string';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Redirect, useHistory, useLocation, useParams } from 'react-router';
import FilterBar, {
  FilterConfig,
  FilterConfigs,
  ActiveState,
  PageParams,
  InputQueryOptions,
  makePageObj,
} from '../components/Common/FilterBar.component';
import PreviewPanel from '../components/Panel/PreviewPanel.component';
import SvgPreview from '../components/Panel/SvgPreview.component';
import Spinner from '../components/Spinner.component';
import { useActionModal } from '../hooks/ActionModal.hook';
import { panelWidth } from '../hooks/CurrentConfiguration.hook';
import { useMe } from '../hooks/Me.hook';
import { formatWidth } from '../lib/format';
import { Configuration, ListConfigurationsQueryVariables, PanelWidth, PlugRegion, Role, useListConfigurationsQuery } from '../lib/graphql.generated';
import { mm2px } from '../lib/measurement';

const LIMIT = 20;
const BASE_URL = '/panels/page';

type PlugRegionState = PlugRegion | null;

interface FilterState {
  isActive: ActiveState;
  createdBySelf: boolean;
  plugRegion: PlugRegionState;
  search?: string;
}

function PanelListPage() {
  const { t } = useTranslation();
  const me = useMe();
  const history = useHistory();

  const { search } = useLocation();
  const query: InputQueryOptions<FilterState> = parse(search);

  const { page: pageString } = useParams<PageParams>();
  const page = parseInt(pageString ?? '1');
  const offset = (page - 1) * LIMIT;

  const handleSetViewList = () => {
    if (viewList.isOpen) {
      viewList.close();
    } else {
      viewList.open();
    }
  };
  const { previewPanel, viewList } = useActionModal();
  const [selectedPanelIndex, setSelectedPanelIndex] = useState(0);

  const appropriateScale = 18.75;

  const optionsRegion: FilterConfig<PlugRegionState> = {
    label: t('panel.filters.regionLabel'),
    options: [
      { value: PlugRegion.Schuko, label: t('plugRegion.schuko') },
      { value: PlugRegion.Italian, label: t('plugRegion.italian') },
      { value: PlugRegion.French, label: t('plugRegion.french') },
      { value: PlugRegion.Danish, label: t('plugRegion.danish') },
      { value: PlugRegion.British, label: t('plugRegion.british') },
      { value: PlugRegion.Swiss, label: t('plugRegion.swiss') },
      { value: null, label: t('panel.filters.region.all') },
    ],
    default: null,
    enabled: true,
  };
  const optionsIsOwner: FilterConfig<boolean> = {
    label: t('panel.filters.isOwnerLabel'),
    options: [
      { value: true, label: t('panel.filters.isOwner.myself') },
      { value: false, label: t('panel.filters.isOwner.all') },
    ],
    default: false,
    enabled: true,
  };
  const optionsIsActive: FilterConfig<ActiveState> = {
    label: t('filters.label.status'),
    options: [
      { value: ActiveState.Active, label: t('panel.filters.deleted.active') },
      { value: ActiveState.Deleted, label: t('panel.filters.deleted.deleted') },
      { value: ActiveState.All, label: t('panel.filters.deleted.all') },
    ],
    default: ActiveState.Active,
    enabled: (me?.role == Role.Admin) ?? false,
  };

  const filterOptions: FilterConfigs<FilterState> = {
    isActive: optionsIsActive,
    createdBySelf: optionsIsOwner,
    plugRegion: optionsRegion,
  };

  let selectedPlugRegion: PlugRegionState = null;
  if (query.plugRegion) {
    const selection = Object.keys(PlugRegion)
      .map(i => PlugRegion[i as keyof typeof PlugRegion])
      .find(region => region == query.plugRegion);
    if (selection) {
      selectedPlugRegion = selection;
    }
  }

  let selectedIsActive: ActiveState = ActiveState.Active;
  if (me?.role == Role.Admin ?? false) {
    if (query.isActive) {
      const selection = Object.keys(ActiveState)
        .map(i => ActiveState[i as keyof typeof ActiveState])
        .find(state => state == query.isActive);
      if (selection) {
        selectedIsActive = selection;
      }
    }
  }

  let filterState: FilterState = {
    isActive: selectedIsActive,
    createdBySelf: (query.createdBySelf && query.createdBySelf == 'true') ? true : false,
    plugRegion: selectedPlugRegion,
  };

  let listVars: ListConfigurationsQueryVariables = {
    offset,
    limit: LIMIT,
  };

  if (query.search && query.search.length > 0) {
    listVars.search = query.search;
    filterState.search = query.search;
  }
  if (filterState.createdBySelf && filterState.createdBySelf != optionsIsOwner.default) {
    listVars.createdBySelf = true;
  }
  if (filterState.plugRegion && filterState.plugRegion != optionsRegion.default) {
    listVars.plugRegion = filterState.plugRegion;
  }
  if (me?.role == Role.Admin) {
    if (filterState.isActive != ActiveState.All) {
      listVars.isActive = filterState.isActive == ActiveState.Active ?
        true :
        false;
    }
  } else {
    listVars.isActive = true;
  }

  const { data, loading, error } = useListConfigurationsQuery({
    variables: listVars,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  function handleOnClick(id: string) {
    history.push(generatePath('/panels/:id', { id }));
  }

  if (!loading && page > 1 && data?.configurations.length == 0) {
    return <Redirect to={makePageObj(BASE_URL, 1, filterState, filterOptions)} />;
  }

  return (
    <>
      <div className="pb-5 flex w-full flex-col items-center">
        <div className="flex flex-col flex-1 w-full xl:max-w-screen-lg justify-center">
          <FilterBar
            values={filterState}
            next={(newValues: Partial<FilterState>) =>
              history.push(makePageObj(BASE_URL, 1, newValues, filterOptions))}
            options={filterOptions}
            isViewList={viewList.isOpen}
            handleSetViewList={handleSetViewList}
          />
          <div className="py-2 align-middle inline-block min-w-full items-center sm:px-6 lg:px-8">
            {(() => {
              if (loading) {
                return <Spinner local={true} />;
              } else if (error) {
                return <p>Error: {error}</p>;
              } else {
                if (viewList.isOpen) {
                  return (
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg ">
                      <table className="min-w-full divide-y divide-gray-200 stripped ">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                              {t('panel.table.id')}
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                              {t('label.name')}
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                              {t('label.width')}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {(data?.configurations?.length ?? 0) > 0 && (data?.configurations.map((item, index) => {
                            return (
                              <tr key={index} onClick={() => handleOnClick(item.id)} className="hover:bg-gray-200 cursor-pointer">
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {item.id}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {item.name}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {item.width == PanelWidth.Custom ? t('width.custom') : formatWidth(panelWidth.get(item.width)!)}
                                </td>
                              </tr>
                            );
                          }))}
                        </tbody>
                      </table>
                      {(data?.configurations?.length ?? 0) == 0 &&
                    <h3 className="text-primary-grey-default text-md text-center">
                      {t('table.NoData')}
                    </h3>
                      }
                    </div>
                  );
                } else {
                  return (<div className="grid grid-cols-2 gap-y-10 gap-x-9 sm:grid-cols-3 lg:grid-cols-4">
                    {data?.configurations.map((configuration, index) => (
                      <div key={index} className="w-full aspect-w-4 aspect-h-3 border-2 bg-white border-gray-200 rounded-lg overflow-hidden transform hover:scale-125">
                        <button
                          onClick={() => {
                            setSelectedPanelIndex(index);
                            previewPanel.open();
                          }}
                        >
                          <div
                            className="flex flex-row border border-black border-r-0 border-l-0"
                            style={{
                              width: `${mm2px(panelWidth.get(configuration.width!) ?? configuration.customWidth!, appropriateScale)}px`,
                            }}
                          >
                            <SvgPreview configuration={configuration as Configuration} appropriateScale={appropriateScale} />
                          </div>
                        </button>
                      </div>
                    ))}
                    {(data?.configurations?.length ?? 0) == 0 &&
                      <h3 className="col-span-2 sm:col-span-3 lg:col-span-4 text-primary-grey-default text-md text-center">
                        {t('table.NoData')}
                      </h3>}
                  </div>);
                }
              }
            })()}
          </div>

          <nav className="flex-shrink-0 border-t border-gray-200 px-4 flex items-center justify-between sm:px-0 min-w-full">
            <div className="-mt-px w-0 flex-1 flex">

              <button disabled={page == 1} className="disabled:text-gray-300 disabled:cursor-not-allowed border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:ring-0 focus:outline-none"
                onClick={() => {
                  history.push(makePageObj(BASE_URL, page - 1, filterState, filterOptions));
                }}
              >
                <ArrowNarrowLeftIcon className="mr-3 h-5 w-5" />
                {t('table.previous')}
              </button>
            </div>
            <div className="-mt-px w-0 flex-1 flex justify-end">
              <button disabled={data?.configurations.length! < LIMIT} className="disabled:text-gray-300 disabled:cursor-not-allowed border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:ring-0 focus:outline-none"
                onClick={() => {
                  history.push(makePageObj(BASE_URL, page + 1, filterState, filterOptions));
                }}
              >
                {t('table.next')}
                <ArrowNarrowRightIcon className="mr-3 h-5 w-5" />
              </button>
            </div>
          </nav>
        </div>
      </div>
      <PreviewPanel data={data} loading={loading} selectedPanelIndex={selectedPanelIndex} />
    </>
  );
}

export default PanelListPage;
