
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';


const DEBUG: boolean = process.env.REACT_APP_NODE_ENV == 'development';
//Cache for 10s or 1 day depending on DEBUG
const expirationTime = DEBUG ? 10 : 1 * 24 * 60 * 60;

void i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: DEBUG,
    supportedLngs: ['en', 'fi', 'de', 'fr', 'se', 'ru'],

    interpolation: {
      escapeValue: false,
    },

    detection: {
      order: ['querystring', 'localStorage', 'navigator', 'htmlTag', 'path'],
      lookupQuerystring: 'l',
      lookupLocalStorage: 'PowerConfiguratorLng',
      lookupFromPathIndex: 0,
    },

    backend: {
      backends: [
        LocalStorageBackend,
        HttpApi,
      ],
      backendOptions: [
        {
          prefix: 'power_configurator_',
          expirationTime,
        },
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      ],
    },
  });

export default i18n;