import { PencilAltIcon } from '@heroicons/react/solid';
import { Dispatch, SetStateAction } from 'react';
import { PanelWidth } from '../../lib/graphql.generated';

interface SetBasePriceButtonProps {
  action: Dispatch<SetStateAction<boolean>>;
  set: Dispatch<SetStateAction<PanelWidth | undefined>>;
  id: PanelWidth;
}
export default function SetBasePriceButton({ action, set, id }: SetBasePriceButtonProps) {
  const handleOnClick = () => {
    action(true);
    set(id);
  };
  return (
    <button type="button" onClick={handleOnClick} className="group xl:text-md sm:text-base md:text-sm inline-flex flex-1 items-center px-4 py-2 text-sm font-medium rounded-md text-primary-gray-default bg-transparent hover:bg-gray-300 focus:outline-none focus:ring-0">
      <>
        <PencilAltIcon className="h-5 w-5 group-hover:text-primary-red-default" />
      </>
    </button>
  );
}