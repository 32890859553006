
import { PlusIcon } from '@heroicons/react/solid';

export interface CreateButtonProps {
  message: string;
  onClick?: () => void;
}

function CreateButton({
  message,
  onClick,
}: CreateButtonProps) {
  return (
    <div className="flex-shrink-0">
      <button
        onClick={onClick}
        type="button" className="relative inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-secondary-orange-default shadow-sm hover:bg-secondary-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-orange-300">
        <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        <span>{message}</span>
      </button>
    </div>
  );
}
export default CreateButton;