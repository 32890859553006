import { RadioGroup, Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

export interface FilterOptions<TValue> {
  value: TValue;
  label: string;
}

interface FilterProps<TValue> {
  value: TValue;
  options: FilterOptions<TValue>[];
  onChange: (newValue: TValue) => void;
  label: string;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

function Filter<TValue>({
  value,
  options,
  onChange,
  label,
}: FilterProps<TValue>) {
  return (
    <div >
      <Disclosure >
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between rounded-md w-48 px-4 py-2 text-sm font-medium text-left text-primary-grey-default bg-white hover:bg-gray-300 focus:bg-gray-300 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
              <span>{label}</span>
              <ChevronDownIcon
                className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-gray-500`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className=" text-sm text-gray-500">
              <RadioGroup value={value} onChange={onChange} >
                <div className="bg-white rounded-md -space-y-px">
                  {options.map((option, index) => (
                    <RadioGroup.Option
                      key={option.label}
                      value={option.value}
                      className={({ checked }) =>
                        classNames(
                          index === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                          index === options.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                          checked ? ' border-gray-300 z-10' : 'border-gray-200',
                          'relative border p-2 flex cursor-pointer focus:outline-none',
                        )
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <span
                            className={classNames(
                              checked ? 'bg-gray-600 border-transparent' : 'bg-white border-gray-300',
                              active ? 'ring-2 ring-offset-2 ring-gray-500' : '',
                              'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center',
                            )}
                            aria-hidden="true"
                          >
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <div className="ml-3 flex flex-col">
                            <RadioGroup.Label
                              as="span"
                              className={classNames(checked ? 'text-gray-800' : 'text-gray-900', ' text-sm font-medium')}
                            >
                              {option.label}
                            </RadioGroup.Label>

                          </div>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

    </div>
  );
}
export default Filter;