import { Auth0Provider } from '@auth0/auth0-react';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AuthorizedApolloProvider from './components/AuthorizedApolloProvider.component';
import Spinner from './components/Spinner.component';
import './lib/i18n';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Spinner />}>
      <Auth0Provider
        domain={process.env.REACT_APP_DOMAIN!}
        clientId={process.env.REACT_APP_CLIENT_ID!}
        redirectUri={window.location.origin}
      >
        <AuthorizedApolloProvider>
          <App />
        </AuthorizedApolloProvider>
      </Auth0Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);