import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentConfiguration } from '../../hooks/CurrentConfiguration.hook';
import { PlugRegion, PanelWidth } from '../../lib/graphql.generated';
import { TypeListOption } from '../ListBox/TypeListBox.component';

function Region() {
  const { t } = useTranslation();
  const { reset, configuration } = useCurrentConfiguration();

  const options: TypeListOption<PlugRegion>[] = [
    { value: PlugRegion.Schuko, label: t('plugRegion.schuko') },
    { value: PlugRegion.Italian, label: t('plugRegion.italian') },
    { value: PlugRegion.French, label: t('plugRegion.french') },
    { value: PlugRegion.Danish, label: t('plugRegion.danish') },
    { value: PlugRegion.British, label: t('plugRegion.british') },
    { value: PlugRegion.Swiss, label: t('plugRegion.swiss') },
  ];

  const current = options.find(o => o.value == (configuration?.plugRegion ?? PlugRegion.Schuko));
  const handleOnChange = (values: TypeListOption<PlugRegion>) => reset(values?.value!,
    (configuration?.width ?? PanelWidth.Medium), (configuration?.customWidth ?? 0));

  return (

    <div className="flex justify-center">

      <Listbox value={current} onChange={handleOnChange}>
        {({ open }) => (
          <>
            <div className="mt-1 relative">
              <Listbox.Button className="relative w-56 bg-white border border-gray-300 rounded-md shadow-sm  pl-4 py-2 text-left cursor-default focus:outline-none sm:text-sm">
                <span className="block truncate capitalize">
                  {current?.label}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronDownIcon
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className="absolute w-56 py-1 mt-1 overflow-auto text-base z-10 bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" tabIndex={-1} role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3"
                >
                  {options?.map((i, index) => (
                    <Listbox.Option
                      key={index}
                      className={({ active }) => {
                        return (`${active ? 'text-gray-900 bg-gray-100' : 'text-gray-900'} cursor-default select-none relative py-2 pl-4 text-left`);
                      }}
                      value={i}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`${selected ? 'font-semibold' : 'font-normal'} block truncate`}
                          >
                            {i.label}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>


  );
}

export default Region;
