import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { panelWidth, useCurrentConfiguration } from '../../hooks/CurrentConfiguration.hook';
import { formatWidth, getNumberFormatting } from '../../lib/format';
import { PanelWidth, PlugRegion } from '../../lib/graphql.generated';

type FormData = {
  customWidth: number;
};

function PanelWidthItem({ name, width }: { name: string; width: PanelWidth }) {
  const { t } = useTranslation();
  const { reset, configuration } = useCurrentConfiguration();
  const {
    handleSubmit,
    setValue,
    setError,
    control,
    reset: resetForm,
    formState: { isValid, isDirty, errors: formErrors },
  } = useForm<FormData>({
    mode: 'all',
  });

  const onClick = () => {
    reset(configuration?.plugRegion ?? PlugRegion.Schuko, width, undefined);
  };

  const onSubmit = (data: FormData) => {
    reset(configuration?.plugRegion ?? PlugRegion.Schuko, width, data.customWidth);
    resetForm();
  };


  return (
    <>{name === t('panel.customWidth') ? (
      <form onSubmit={handleSubmit(onSubmit)}>
        <li className="py-4 px-4 flex flex-row justify-between">
          <div className="ml-3">
            <p className="text-sm font-medium text-gray-900">{t('panel.customWidth')}</p>
            <p className="text-sm text-gray-500 flex">w:{
              <Controller
                control={control}
                // @ts-ignore
                name='customWidth'
                defaultValue={undefined}
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <NumberFormat
                    id="custom width"
                    placeholder={'0'}
                    allowNegative={false}
                    defaultValue={''}
                    onValueChange={(values) => {
                      const { value } = values;
                      if (parseFloat(value) > 331 && parseFloat(value) < 1700) {
                        onChange(value);
                        setValue('customWidth', parseFloat(value), {
                          shouldValidate: true,
                        });
                      } else {
                        setError('customWidth', {
                          type: 'required',
                          message: t('panel.widthItemError'),
                        });
                        setValue('customWidth', 0, {
                          shouldValidate: false,
                        });
                      }
                    }}
                    decimalScale={2}
                    thousandSeparator={getNumberFormatting().thousandsSeparator}
                    decimalSeparator={getNumberFormatting().decimalSeparator}
                    className={`${formErrors.customWidth ? 'border-red-300 focus:border-red-400 focus:ring-red-400' : 'border-gray-300 focus:border-gray-400 focus:ring-gray-400'} rounded-md w-12 text-xs px-1 py-1 ml-2 mr-1 text-center`}
                  />
                )}
              />

            }mm</p>
            {formErrors.customWidth && <p className="mt-2 text-sm text-red-600" id="customWidth-error">{formErrors.customWidth.message}</p>}
          </div>
          <button
            type="submit"
            disabled={!isValid || !isDirty}
            className="flex-shrink-0 items-center uppercase h-10 py-0 px-3 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-green-400 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-300 disabled:bg-primary-grey-200 disabled:cursor-not-allowed"
          >
            {t('panel.button.set')}
          </button>
        </li >
      </form>) : (
      <li className="py-4 px-4 flex flex-row justify-between">
        <div className="ml-3">
          <p className="text-sm font-medium text-gray-900">{name}</p>
          <p className="text-sm text-gray-500">w:{formatWidth(panelWidth.get(width)!)}</p>
        </div>
        <button
          onClick={onClick}
          disabled={configuration?.width == width}
          className="inline-flex items-center uppercase px-3 py-0 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-green-400 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-300 disabled:bg-primary-grey-200 disabled:cursor-not-allowed"
        >
          {t('panel.button.set')}
        </button>
      </li >
    )}
    </>

  );
}
export default PanelWidthItem;