import { Popover, Transition } from '@headlessui/react';
import { XCircleIcon, SearchIcon, FilterIcon, ViewGridIcon, ViewListIcon } from '@heroicons/react/solid';
import { stringify } from 'query-string';
import { Fragment, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation } from 'react-router';
import Filter, { FilterOptions } from './Filter.component';

const SEARCH_KEY = 'search';

export type Search = { search?: string };

export type PageParams = { page?: string };

export enum ActiveState {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  All = 'ALL',
}
export type InputQueryOptions<TData extends Search> = Partial<{
  [Property in keyof TData]: string;
}>;

export type OutputQueryOptions<TData extends Search> = Partial<{
  [Property in keyof TData]: TData[Property];
}>;

export interface FilterConfig<TValue> {
  label: string;
  options: FilterOptions<TValue>[];
  default: TValue;
  enabled: boolean;
}

export type FilterConfigs<TData> = {
  [Property in keyof TData]: FilterConfig<TData[Property]>;
}

export interface FilterBarProps<TData extends Search> {
  values: TData;
  next: (newValues: Partial<TData>) => void;
  options: FilterConfigs<TData>;
  isViewList?: boolean;
  handleSetViewList?: () => void;
}

export function makePageObj<TData extends Search>(
  base: string,
  newPage: number,
  filters: Partial<TData>,
  filterOptions: FilterConfigs<TData>,
) {
  let queryVars: OutputQueryOptions<TData> = {};
  for (let name of Object.keys(filterOptions)) {
    // @ts-ignore  -- TODO There must be a way to express this
    if (filterOptions[name].enabled && filters[name] != filterOptions[name].default) {
      // @ts-ignore
      queryVars[name] = filters[name];
    }
  }
  if (filters.search && filters.search.length > 0) {
    queryVars.search = filters.search;
  }

  return {
    pathname: generatePath(`${base}/:page`, { page: newPage }),
    search: stringify(queryVars),
  };
};

function FilterBar<TData extends Search>({
  values,
  next,
  options,
  isViewList,
  handleSetViewList,
}: FilterBarProps<TData>) {
  const { t } = useTranslation();
  const [areFiltersActive, setAreFiltersActive] = useState(false);
  const location = useLocation();
  const isPanelPage = (location.pathname.split('/'))[1].toString() == 'panels' ? true : false;
  const {
    register,
    handleSubmit,
    formState: {
      isValid,

    },
  } = useForm<Search>({
    mode: 'all',
  });

  useEffect(() => {
    let foundFilter = false;
    // @ts-ignore -- All keys of options must be keys of TData
    Object.keys(options).map((name: keyof TData) => {
      if (values[name] != options[name].default) {
        foundFilter = true;
      }
    });

    const isSearchActive: boolean = (values.search && values.search?.length > 0) ? true : false;
    setAreFiltersActive(foundFilter || isSearchActive);
  }, [options, values]);

  const clearFilters = () => {
    setAreFiltersActive(false);
    let defaults: Partial<TData> = {};
    for (let name of Object.keys(options)) {
      if (name == SEARCH_KEY) {
        continue;
      } else {
        // @ts-ignore -- All keys of options must be keys of TData
        defaults[name] = options[name].default;
      }
    }
    next(defaults);
  };

  const onSearch: SubmitHandler<Search> = ({ search }) => {
    if (search && search.length > 0) {
      next({ ...values, search });
    }
  };

  return (
    <div className="sm:px-6 lg:px-8 pb-4 mt-6 w-full">
      <div className="mt-6 flex flex-row space-x-4 items-center">
        <form
          onSubmit={handleSubmit(onSearch)}
          className="flex-grow"
        >
          <div className="min-w-0 flex">
            <div className="relative flex rounded-md shadow-sm flex-grow">
              <div className="absolute inline-flex inset-y-0 left-0 pl-3 items-center pointer-events-none">
                <SearchIcon className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="search"
                {...register('search', { required: true })}
                id="search"
                className="focus:ring-gray-300 focus:border-gray-300 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                placeholder={t('button.search')}
              />
            </div>
            <button
              type="submit"
              disabled={!isValid}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-secondary-orange-default text-base font-medium text-white hover:bg-secondary-orange-700 focus:outline-none focus:ring-0 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-400 disabled:cursor-not-allowed"
            >
              {t('button.search')}
            </button>
          </div>
        </form>
        {isPanelPage &&
          <div>
            <button
              className="mb-1"
              onClick={() => {
                if (!handleSetViewList) return;
                handleSetViewList();
              }}>
              {
                isViewList
                  ? <ViewGridIcon className="h-5 w-5 text-gray-400 hover:text-secondary-orange-700" />
                  : <ViewListIcon className="h-5 w-5 text-gray-400 hover:text-secondary-orange-700" />
              }
            </button>
          </div>}
        <div className="">
          <Popover className="relative inline-flex ">
            {({ open }) => (
              <>
                <Popover.Button
                  className="text-white group bg-orange-700 pr-3 rounded-md inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                >
                  <FilterIcon className="h-5 w-5 text-gray-400 hover:text-secondary-orange-700" />
                </Popover.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel
                    static
                    className="absolute z-20 mt-8 right-0 origin-right p-2 shadow-md rounded-lg overflow-y-auto text-base sm:text-sm space-y-0 bg-white"
                  >
                    {
                      // @ts-ignore -- All keys of options must be keys of TData
                      Object.keys(options).map((name: keyof TData) => {
                        if (options[name].enabled) {
                          return (

                            <Filter
                              key={name.toString()}
                              value={values[name]}
                              options={options[name].options}
                              onChange={(newValue) => {
                                const newValues = { ...values };
                                newValues[name] = newValue;

                                next(newValues);
                              }}
                              label={options[name].label}
                            />
                          );
                        }
                      })}
                  </Popover.Panel>

                </Transition>
              </>
            )
            }
          </Popover >
        </div >
      </div>
      {areFiltersActive &&
        <div>
          <div className="relative rounded-lg px-6 mt-2 flex flex-row items-center space-x-3 " >
            <button
              className="flex-shrink-0"
              aria-hidden="true"
              onClick={clearFilters}
            >
              <XCircleIcon className="h-6 w-6 inline-block hover:text-primary-red-default" />
              <div className="flex-shrink-0 inline-block pl-1">
                <p className="text-sm font-medium">
                  {t('filters.clear')}
                </p>
              </div>
            </button>
            <div className="flex-grow" />
            <div className="lex-shrink-0">
              <div className="flex-shrink-0 inline-block pl-1">
                <p className="text-sm font-medium">
                  {t('filters.active')}
                </p>
              </div>
            </div>

            {
              // @ts-ignore -- All keys of options must be keys of TData
              Object.keys(options).map((name: keyof TData) => {
                if (values[name] != options[name].default) {
                  const option = options[name].options.find(o => o.value == values[name]);
                  return (
                    <button
                      key={name.toString()}
                      className="flex-shrink-0"
                      aria-hidden="true"
                      onClick={() => {
                        const newValues = { ...values };
                        newValues[name] = options[name].default;

                        next(newValues);
                      }}
                    >
                      <XCircleIcon className="h-6 w-6 inline-block hover:text-primary-red-default" />
                      <div className="flex-shrink-0 inline-block pl-1">
                        <p className="text-sm font-medium">
                          {option?.label}
                        </p>
                      </div>
                    </button>
                  );
                }
              })
            }
            {values.search && values.search.length > 0 &&
              <button
                key="search"
                className="flex-shrink-0"
                aria-hidden="true"
                onClick={() => {
                  next({ ...values, search: '' });
                }}
              >
                <XCircleIcon className="h-6 w-6 inline-block hover:text-primary-red-default" />
                <div className="flex-shrink-0 inline-block pl-1">
                  <p className="text-sm font-medium">
                    {t('button.search')}{`: ${values.search}`}
                  </p>
                </div>
              </button>
            }
          </div>
        </div>
      }
    </div>
  );
}

export default FilterBar;