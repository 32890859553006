import { useMe } from '../../hooks/Me.hook';
import Avatar from './avatar.svg';
export interface MobileMenuProfileProps { }

function MobileMenuProfile({

}: MobileMenuProfileProps) {
  const me = useMe();

  return (
    <div className="flex items-center px-4">
      <div className="flex-shrink-0">
        <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
          <img src={me?.picture ?? Avatar} alt="avatar" />
        </span>
      </div>
      <div className="ml-3">
        <div className="text-base font-medium text-gray-800">{me?.name ?? ''}</div>
        <div className="text-sm font-medium text-gray-500">{me?.email ?? ''}</div>
      </div>
    </div>
  );
}

export default MobileMenuProfile;