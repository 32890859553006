import { TabPanel, useTabState } from 'reakit';
import ComponentItemList from '../components/Panel/ComponentItemList.component';
import PriceList from '../components/Panel/PriceList.component';
import Region from '../components/Panel/Region.component';
import SavePanel from '../components/Panel/SavePanel.component';
import Sidebar from '../components/Panel/Sidebar.component';
import SvgPanel from '../components/Panel/SvgPanel.component';
import WidthPanel from '../components/Panel/WidthPanel.component';
import { CurrentConfiguration, useCurrentConfigurationContext } from '../hooks/CurrentConfiguration.hook';
import { ComponentType } from '../lib/graphql.generated';

function PanelCreatePage() {
  const tab = useTabState({ orientation: 'vertical', selectedId: 'WIDTH_PANEL' });
  return (
    <CurrentConfiguration.Provider value={useCurrentConfigurationContext()}>
      <div className="min-h-content relative flex flex-col lg:flex-row overflow-hidden bg-white">
        <div className="flex flex-col md:flex-row">
          <Sidebar tab={tab} />

          <div className="relative flex flex-row w-96 border-r border-gray-200">
            <div className="absolute inset-0 py-6 px-0 bg-gray-50">
              <div className="h-full focus:ring-0">
                <TabPanel {...tab} tabId="WIDTH_PANEL" className="focus:ring-0 focus:outline-none">
                  <Region />
                  <WidthPanel />
                </TabPanel>
                <TabPanel {...tab} tabId={ComponentType.Switch} className="focus:ring-0 focus:outline-none">
                  <ComponentItemList selection={ComponentType.Switch} />
                </TabPanel>
                <TabPanel {...tab} tabId={ComponentType.PowerSocket} className="focus:ring-0 focus:outline-none">
                  <ComponentItemList selection={ComponentType.PowerSocket} />
                </TabPanel>
                <TabPanel {...tab} tabId={ComponentType.OtherSocket} className="focus:ring-0 focus:outline-none">
                  <ComponentItemList selection={ComponentType.OtherSocket} />
                </TabPanel>
                <TabPanel {...tab} tabId="PRICE_LIST">
                  <PriceList />
                </TabPanel>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-grow relative z-0 overflow-y-auto focus:outline-none">
          <div className="absolute inset-0 py-6 px-2 md:px-4 lg:px-8">
            <SvgPanel/>
          </div>
        </div>
      </div>
      <SavePanel />
    </CurrentConfiguration.Provider>
  );
}
export default PanelCreatePage;