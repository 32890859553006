import { ZoomInIcon, ZoomOutIcon, ReplyIcon } from '@heroicons/react/outline';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrag } from 'react-use-gesture';
import { panelWidth, useCurrentConfiguration } from '../../hooks/CurrentConfiguration.hook';
import { formatWidth } from '../../lib/format';
import { baseScale, mm2px } from '../../lib/measurement';
import Spinner from '../Spinner.component';
import SvgComponent from './SvgComponent.component';
export default function SvgPanel() {
  const { configuration, undo, redo, canUndo, canRedo } = useCurrentConfiguration();

  const panel = useRef<HTMLDivElement>(null);

  const scaleIncrement = 5;
  const [scale, setScale] = useState<number>(baseScale / 2);

  const handleZoomIn = () => {
    setScale(scale + scaleIncrement);
  };

  const handleZoomOut = () => {
    setScale(scale - scaleIncrement);
  };

  const bind = useDrag(({ dragging, delta, tap }) => {
    if (!tap && dragging && panel.current) {
      panel.current.scrollLeft += delta[0];
    }
  }, { delay: 400, filterTaps: true });
  const { t } = useTranslation();

  return (
    <>
      <div className="relative w-full h-auto flex flex-row justify-between whitespace-normal top-4">
        <div className="flex flex-col absolute ml-4 space-y-2">
          <p className="text-sm text-gray-500">
            Panel width: {configuration?.customWidth ? formatWidth(configuration?.customWidth!) : formatWidth(panelWidth.get(configuration?.width!)!)}
          </p>
          <p className="text-base text-gray-500 xs:text-sm md:text-sm">{t('panel.messageSvg')}</p>
        </div>
        <div className="right-0 top-0 absolute">
          <div className="flex-row justify-around flex mr-4">
            <button onClick={() => undo()} disabled={!canUndo} className={`${canUndo ? 'hover:text-primary-grey-300' : 'cursor-not-allowed'} flex-1 pr-2 text-primary-grey-200 focus:outline-none `}>
              <ReplyIcon className="h-6 w-6" />
            </button>
            <button onClick={() => redo()} disabled={!canRedo} className={`${canRedo ? 'hover:text-primary-grey-300 ' : 'cursor-not-allowed'} flex-1 pr-2 text-primary-grey-200 focus:outline-none `}>
              <ReplyIcon className="h-6 w-6" style={{ transform: 'scaleX(-1)' }} />
            </button>
            <button onClick={() => handleZoomOut()} className="flex-1 text-primary-grey-200 hover:text-primary-grey-300 focus:outline-none">
              <ZoomOutIcon className="h-6 w-6" />
            </button>
            <div className="flex-1 text-sm text-gray-400 self-center px-1">{scale}%</div>
            <button onClick={() => handleZoomIn()} className="flex-1 text-primary-grey-200 hover:text-primary-grey-300 focus:outline-none">
              <ZoomInIcon className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>
      <div ref={panel} {...bind()} className="h-96 lg:h-full z-0 border-2 border-gray-200 rounded-lg flex flex-col p-2 overflow-x-auto cursor-grab active:cursor-grabbing">

        {(configuration && configuration.components.length > 0) ?
          <div className="my-auto self-start cursor-default " >
            <div
              className="flex flex-row mr-2 border-2 border-black border-r-0 border-l-0"
              style={{
                width: `${mm2px(panelWidth.get(configuration?.width!) ?? configuration.customWidth!, scale)}px`,
              }}
            >
              {configuration?.components.map((component, index: number) =>
                <SvgComponent
                  key={`${component.id}__${index}`}
                  index={index}
                  scale={scale}
                  component={component}
                />,
              )}
            </div>
          </div>
          : <Spinner local={true} />}
      </div>
    </>
  );
}