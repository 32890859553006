import { useTranslation } from 'react-i18next';
import { useCurrentConfiguration } from '../../hooks/CurrentConfiguration.hook';
import { formatWidth } from '../../lib/format';

function PriceList() {
  const { t } = useTranslation();
  const { allComponentDetails } = useCurrentConfiguration();

  return (
    <>
      <h2 className="text-xl font-bold text-gray-600 text-center pt-6">{t('panel.button.componentList')}</h2>
      <span className="flex-1 inline-flex justify-end space-x-16 py-4 px-4 w-full">
        <p className="text-xs font-medium text-gray-600">{t('label.quantity')}</p>
      </span>
      <ul className="divide-y divide-gray-200">
        {allComponentDetails().map((i) => (
          <li key={i.name} className="py-4 px-4 flex flex-row justify-between">
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-600">{i.name}</p>
              <p className="text-sm text-gray-500">w: {formatWidth(i.width!)}</p>
            </div>
            <span className="flex-1 inline-flex justify-end space-x-24">
              <p className="text-sm font-medium text-gray-600">{i.quantity}</p>
            </span>
          </li>
        ))}
      </ul>
    </>
  );
}
export default PriceList;