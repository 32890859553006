import { Dispatch, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Role, UpdateUserMutationVariables, UserDetailsFragment, useUpdateUserMutation } from '../../lib/graphql.generated';
import { TypeListOption } from '../ListBox/TypeListBox.component';
import SelectEditRole, { EditListOption } from './SelectEditRole.component';
export interface UserDataProps {
  idProps: string;
  roleProps: Role;
  changer: Dispatch<React.SetStateAction<UserDetailsFragment | undefined>>;
  roleSelected: TypeListOption<Role>[];

}

function EditRole({
  idProps,
  roleProps,
  changer,
  roleSelected,
}: UserDataProps) {
  const { t } = useTranslation();
  const {
    formState: {
      errors: formErrors,
    },
    reset,
  } = useForm<UpdateUserMutationVariables>({
    mode: 'all',
  });
  const [
    UpdateUserMutation,
    { data, loading, error: saveError },
  ] = useUpdateUserMutation();
  const [card, setCard] = useState<boolean>();

  const handleChange = (newRole: Role) => {
    const roles = roleSelected.find(o => o.label == newRole);
    if (roles?.value != roleProps) {
      let vars: UpdateUserMutationVariables = {
        id: idProps,
        role: roles?.value,
      };
      void UpdateUserMutation({ variables: vars });
      setCard(false);
      reset();
    }
  };
  const roleList: EditListOption[] = roleSelected.map((x: TypeListOption<Role>) => {
    return { label: x.label };
  });
  const roleUse = roleSelected.find(o => o.value == roleProps);
  const [selectedPerson, setSelectedPerson] = useState(roleUse?.label);
  if (card == false) {
    changer(undefined);
  }
  let message: string | undefined;
  let error: string | undefined;

  if (formErrors.email
    || formErrors.name
  ) {
    console.error(formErrors);
    error = t('user.modal.error.fromError');
  }
  if (saveError) {
    console.error(saveError);
    error = t('user.modal.error.cannotSaver');
  }
  if (loading) {
    message = t('user.awaitMsg.save');
  }
  if (data && !data?.updateUser?.email) {
    error = t('user.modal.error.alreadyExisted');
  } else if (data) {
    message = t('user.modal.saveSuccessful');
  }
  return (
    <div className="flex-1 min-w-0 ">
      <div className="lg:col-span-2">
        <ul className="space-y-12 sm:grid sm:grid-cols-1 sm:gap-12 sm:space-y-0 lg:gap-x-8 items-center">
          <li>
            <div className="items-center space-x-4 lg:space-x-6 w-48">

              <SelectEditRole
                value={selectedPerson}
                onChange={(newValue: Role) => {
                  setSelectedPerson(newValue);
                  handleChange(newValue);
                }}
                optionEdit={roleList}
              />
            </div>
          </li>
        </ul>
      </div>

      {message && <p className="text-sm">{message}</p>}
      {error && <p className="text-red-600 text-sm">{error}</p>}
    </div>

  );
};
export default EditRole;