import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { useMenuState } from 'reakit';
import NavbarLink from '../../src/components/Navbar/NavbarLink.component';
import Logo from '../../src/Treston_logo.svg';
import HamburgerIcon from '../components/Navbar/HamburgerIcon.component';
import MobileMenu from '../components/Navbar/MobileMenu.component';
import Translate from '../components/Navbar/Translate.component';
import PowerRail from '../Power_rail.png';

function HomePage() {
  const { t, i18n } = useTranslation();
  const menu = useMenuState();
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const onLoginOutClick = async () => {
    if (isAuthenticated) {
      void logout({ returnTo: window.location.origin });
    } else {
      void loginWithRedirect({ ui_locales: i18n.language });
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/panels" />;
  }

  return (
    <div className="relative bg-white h-screen">
      {/* Background svg*/}
      <div className="hidden xl:block xl:absolute xl:inset-0 xl:h-screen" aria-hidden="true">
        <svg className="absolute top-0 left-1/2 transform translate-x-64 -translate-y-8" width="640" height="784" fill="none" viewBox="0 0 640 784">
          <defs>
            <pattern id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047" x="118" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect y="72" width="640" height="640" className="text-gray-50" fill="currentColor" />
          <rect x="118" width="404" height="784" fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)" />
        </svg>
      </div>

      <div className="relative pt-6 pb-16 sm:pb-24 lg:pb-32">
        {/* Navbar before login */}
        <nav className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
          <div className="flex items-center flex-1">
            <div className="flex items-center justify-between w-full md:w-auto">
              <div className="md:hidden  ">
                <HamburgerIcon {...menu} />
              </div>
            </div>

            <div className="hidden md:flex md:ml-10 md:space-x-10 lg:flex lg:space-x-8">
              <Link className="m-auto" to={'/'}><img className="h-12 w-auto" src={Logo} alt={t('navbar.link.logo')} /></Link>
              <NavbarLink to="/">
                {t('navbar.link.home')}
              </NavbarLink>
              <NavbarLink to="/panels">
                {t('navbar.link.panel')}
              </NavbarLink>
            </div>
          </div>

          <div className="inline-flex justify-between space-x-4 items-center">
            <Translate />
            <div className="hidden md:block text-right">
              <button
                onClick={onLoginOutClick}
                className="inline-flex rounded-md shadow-md ring-1 ring-black ring-opacity-5 active:shadow-none active:bg-primary-grey-300 focus:ring-0 focus:outline-none">
                <p className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-primary-red-default bg-white hover:bg-gray-50">
                  {isAuthenticated ? t('navbar.logout') : t('navbar.login')}
                </p>
              </button>
            </div>

          </div>

        </nav>


        {/* Mobile Menu  */}

        <MobileMenu {...menu} />

        <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
          <div className="text-center">
            <h1 className="text-4xl tracking-tight font-semibold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block text-primary-red-default xl:inline">{t('Home.titleLeft')} </span>
              <span className="block xl:inline">{t('Home.titleRight')}</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 font-normal sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">{t('Home.detail')}</p>
            <div className="mt-5 w-full mx-auto sm:flex sm:justify-center md:mt-8">

              <Link
                to="/panels/new"
                className="items-center justify-center px-14 py-3 border border-transparent text-base font-semibold uppercase rounded-md text-white bg-secondary-orange-default hover:bg-secondary-orange-600  md:py-4 md:text-lg md:px-10"
              >
                {t('Home.start')}
              </Link>

            </div>
          </div>
          <div className="flex">
            <div className="bg-no-repeat bg-contain bg-top" style={{
              backgroundImage: `url(${PowerRail})`,
              width: '85vw',
              height: '85vh',
            }}>
            </div>
          </div>

        </main>

      </div>
    </div>
  );
}

export default HomePage;

