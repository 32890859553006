import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import Breadcrumbs from './components/Common/BreadCrumbs.component';
import Navbar from './components/Navbar/Navbar.component';
import Routes from './components/Routes.component';
import Spinner from './components/Spinner.component';
import ActionModal from './hooks/ActionModal.hook';
import Me from './hooks/Me.hook';


function App() {
  const {
    isLoading,
    isAuthenticated,
    error,
  } = useAuth0();

  if (isLoading) {
    return <Spinner />;
  }
  if (error) {
    return <div>Loading error... {error.message}</div>;
  }

  if (isAuthenticated) {
    return (
      <Me>
        <ActionModal>
          <BrowserRouter>
            <Navbar />
            <Breadcrumbs/>
            <Routes />
          </BrowserRouter>
        </ActionModal>
      </Me>
    );
  } else {
    return (
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    );
  }
}
export default App;