import { ApolloQueryResult } from '@apollo/client';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  UserDetailsFragment,
  ListUsersQueryVariables,
  ListUsersQuery,
  Role,
} from '../../lib/graphql.generated';
import { TypeListOption } from '../ListBox/TypeListBox.component';
import Avatar from '../Navbar/avatar.svg';
import Delete from './DeleteUser.component';
import EditRole from './EditRole.component';
import EditUser from './EditUser.component';
import Undelete from './UndeleteUser.component';

export interface ListUserItemProps {
  user: UserDetailsFragment;
  active: boolean | undefined;

  changer: Dispatch<SetStateAction<UserDetailsFragment | undefined>>;
  refetch: (variables?: Partial<ListUsersQueryVariables>) => Promise<ApolloQueryResult<ListUsersQuery>>;
  roleSelected: TypeListOption<Role>[];

}

function ListUserItem({
  user: {
    id,
    name,
    email,
    role,
    picture,
    isActive,
  },
  changer,
  refetch,
  active,
  roleSelected,
}: ListUserItemProps) {
  const handleOnClick = () => {
    changer({
      id,
      name,
      email,
      role,
      picture,
      isActive,
    });
  };
  const { t } = useTranslation();


  const [isOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function callbackModal() {
    setIsOpen(false);
    void refetch();
  };

  return (
    <tr className="border-gray-200 border-t " onClick={() => handleOnClick()} >
      <td className="px-6 py-4 whitespace-nowrap " >
        <div className="relative rounded-lg px-6 py-5 flex items-center space-x-2 mt-1 " >
          <div className="flex-shrink-0 " >
            <img className="h-10 w-10 rounded-full" src={picture ?? Avatar} alt="avatar" />
          </div>
          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray-900">{name}</p>
            <p className="text-sm text-gray-500 truncate">{email}</p>
          </div>
          <button
            onClick={() => openModal()}
            type="button" className="relative inline-flex items-center px-2 py-1 border border-transparent text-sm font-normal rounded-md text-white bg-secondary-orange-default shadow-sm hover:bg-secondary-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-orange-300">{t('user.buttonEdit')}
          </button>
          <EditUser
            isOpen={isOpen}
            closeModalMessage={callbackModal}
            idMessage={id}
            nameMessage={name}
            emailMessage={email}
            changer={changer} />
        </div>
      </td>
      <td className=" py-9 whitespace-nowrap text-sm text-gray-500">
        <div>
          <EditRole
            idProps={id}
            roleProps={role}
            changer={changer}
            roleSelected={roleSelected}
          />

        </div>
      </td>
      <td >
        <div className="relative rounded-lg flex items-center space-x-3 " >
          {active == true &&
            <Delete id={id} refetch={refetch} changer={changer} />
          }
          {active == false &&
            <Undelete id={id} refetch={refetch} changer={changer} />
          }

        </div>
      </td>
    </tr>
  );
}

export default ListUserItem;
