import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';

export interface ProtectedRouteProps {
  component: ComponentType;
  path: string;
}

const ProtectedRoute = ({ component, path }: ProtectedRouteProps) => {
  const { i18n } = useTranslation();
  return (
    <Route
      component={withAuthenticationRequired(component, { loginOptions: { ui_locales: i18n.language } })}
      path={path} />
  );
};

export default ProtectedRoute;