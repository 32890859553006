import { FetchResult, MutationFunctionOptions } from '@apollo/client';
import { Dialog, Transition } from '@headlessui/react';
import { QuestionMarkCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import { Dispatch, Fragment, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Exact } from '../../lib/graphql.generated';


type HasId = Exact<{
  id: string;
}>;

type HasResultId = {
  result: { id: string };
};

interface DeleteAlertModalProps<TMutation extends HasResultId> {
  id: string;
  status: boolean;
  show: boolean;
  setClose: Dispatch<SetStateAction<boolean>>;
  action: (options?: MutationFunctionOptions<TMutation, HasId>) => Promise<FetchResult>;
  result?: TMutation | null;
  next: () => void;
}

export default function DeleteAlertModal<TMutation extends HasResultId>({
  id,
  status,
  show,
  setClose,
  action,
  result,
  next,
}: DeleteAlertModalProps<TMutation>) {
  const { t } = useTranslation();

  const close = () => setClose(false);
  const handleOnClick = () => {
    void action({ variables: { id } });
    close();
  };
  useEffect(() => {
    if (result?.result.id == id) {
      void next();
    }
  });

  return (
    <Transition show={show} as={Fragment}>
      <Dialog
        as="div"
        id="deleteConfirm"
        open={show}
        onClose={close}
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button type="button" onClick={close} className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0">
                  <span className="sr-only">{t('modal.closeButton')}</span>
                  <XCircleIcon className="w-6 h-6" />
                </button>
              </div>
              <div className="sm:flex items-center">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
                  <QuestionMarkCircleIcon className="h-6 w-6 text-gray-600" />
                </div>
                <div className="mt-3 text-center items-center sm:mt-0 sm:ml-4 sm:text-left flex-col">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {status ? t('modal.deleteConfirm') : t('modal.undeleteConfirm')}
                  </Dialog.Title>
                </div>
              </div>
              <div className="mt-4 pl-4">
                <p className="text-sm text-gray-500">
                  {status ? t('modal.deleteConfirmDetail') : t('modal.UndeleteConfirmDetail')}
                </p>
              </div>
              <div className="mt-4 py-3 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 mx-2 text-sm font-medium text-white bg-red-400 border border-transparent rounded-full hover:bg-red-600 focus:outline-none"
                  onClick={handleOnClick}
                >
                  {status ? t('button.delete') : t('button.undelete')}
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-600 bg-gray-100 border border-transparent rounded-full hover:bg-gray-200 focus:outline-none"
                  onClick={close}
                >
                  {t('button.cancel')}
                </button>
                <input type="hidden" className='sr-only' />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}