import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { MenuStateReturn, MenuButton } from 'reakit';

function HamburgerIcon(props: MenuStateReturn) {
  const { t } = useTranslation();
  return (
    <div className="flex items-center lg:hidden">
      <MenuButton
        {...props}
        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none "
        aria-expanded="false"
        aria-label="Main menu"
      >
        <span className="sr-only">{t('navbar.openMenu')}</span>
        <MenuIcon className={`${props.visible ? 'hidden' : 'block'} h-6 w-6`} aria-hidden="true" />
        <XIcon className={`${props.visible ? 'block' : 'hidden'} h-6 w-6`} aria-hidden="true" />
      </MenuButton>
    </div>
  );
}

export default HamburgerIcon;