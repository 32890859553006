import { ZoomInIcon, ZoomOutIcon } from '@heroicons/react/solid';
import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrag } from 'react-use-gesture';
import { panelWidth } from '../../hooks/CurrentConfiguration.hook';
import { GetConfigurationQuery } from '../../lib/graphql.generated';
import { baseScale, mm2px } from '../../lib/measurement';


export interface SvgPanelViewProps {
  data?: GetConfigurationQuery;
}

function SvgPanelView({ data }: SvgPanelViewProps) {
  const { t } = useTranslation();
  const panel = useRef<HTMLDivElement>(null);

  const scaleIncrement = 5;
  const [scale, setScale] = useState<number>(baseScale / 2);

  const handleZoomIn = () => {
    setScale(scale + scaleIncrement);
  };

  const handleZoomOut = () => {
    setScale(scale - scaleIncrement);
  };

  const bind = useDrag(({ dragging, delta, tap }) => {
    if (!tap && dragging && panel.current) {
      panel.current.scrollLeft += delta[0];
    }
  }, { delay: 400, filterTaps: true });

  return (
    <div className="relative px-4 py-5 flex-1 flex-col">
      <div className="absolute z-10 w-full h-auto flex flex-row justify-between whitespace-normal top-4">
        {/* <p className="text-xs text-gray-500 absolute ml-4">panel width: {configuration?.width ?? 0}mm</p> */}
        <div className="right-0 top-0 absolute">
          <div className="flex-row justify-around flex mr-4">
            <button onClick={() => handleZoomOut()} className="flex-1 text-primary-grey-200 hover:text-primary-grey-300 focus:outline-none">
              <ZoomOutIcon className="h-6 w-6" />
            </button>
            <div className="flex-1 text-xs text-gray-400 self-center px-1">{scale}%</div>
            <button onClick={() => handleZoomIn()} className="flex-1 text-primary-grey-200 hover:text-primary-grey-300 focus:outline-none">
              <ZoomInIcon className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>
      <div ref={panel} {...bind()} className="overflow-y-auto h-full pb-8 cursor-grab active:cursor-grabbing">
        <div
          className="flex flex-row border-2 border-black border-r-0 border-l-0 mt-10"
          style={{
            width: `${mm2px(panelWidth.get(data?.configuration?.width!) ?? data?.configuration?.customWidth!, scale)}px`,
          }}
        >
          {data?.configuration?.components.map((c, index) => {
            const dataUri = `url("${c.svg}")`;
            return (
              <Fragment key={`frag_${c.id}__${index}`} >
                {index == ((data?.configuration?.components.length ?? 0) - 1) && <div className="flex-grow" />}
                <div className="flex-none z-10" >
                  <div
                    className="relative"
                    style={{
                      backgroundImage: dataUri,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      height: `${mm2px(50, scale)}px`,
                      width: `${mm2px(c.width, scale)}px`,
                    }}
                  />
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
      <span className="absolute w-full">
        <p className="text-sm xl:text-base lg:text-base 2xl:text-md text-gray-700">{t('panel.messageSvg')}</p>
      </span>
    </div>
  );
}

export default SvgPanelView;