import { Role, UserDetailsFragment } from '../../lib/graphql.generated';
import { TypeListOption } from '../ListBox/TypeListBox.component';
import Avatar from '../Navbar/avatar.svg';

interface CardPropsItem {
  selection: UserDetailsFragment | undefined;
  roleSelected: TypeListOption<Role>[];
}
function Card({
  selection,
  roleSelected,
}: CardPropsItem) {
  return (
    <div>
      <div className="flex-1 flex flex-col p-8">
        <img className="w-32 h-32 flex-shrink-0 mx-auto bg-black rounded-full" src={selection?.picture ?? Avatar} alt="avatar" />
        <h3 className="mt-6 text-gray-900 text-sm font-medium">{selection?.name}</h3>
        <dl className="mt-1 flex-grow flex flex-col justify-between">
          <dd className="text-gray-500 text-sm">{selection?.email}</dd>
          <dd className="mt-3">
            <span className="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full">
              {roleSelected.find(o => o.value == selection?.role)?.label}
            </span>
          </dd>
        </dl>
      </div>
      <div>
      </div>
    </div>

  );
}

export default Card;
